import React, { useEffect, useState } from "react";

import NavBar from "../NavBar";
import Footer from "../Footer";

import { Helmet } from "react-helmet";
import axios from "axios";
import { useLocation } from "react-router";
import NavBarHome from "../NavBarHome";

const getData = async (data) => {
    const response = await axios.post(
        `${process.env.REACT_APP_PROXY}/pagoCuotealo/receiver`,
        {
            data: data,
        }
    );

    console.log(response);
};

const SuccessCuotealo = () => {
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams(location.search);
                const data = await params.get("data");

                getData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [location.search]);

    return (
        <div>
            <NavBarHome explorer={false} />
            <Helmet>
                <link
                    rel="canonical"
                    href="https://example.com/dresses/green-dresses"
                />
                <meta
                    name="description"
                    content={`Blue Corner, Alquileres, Embarcaciones, Alojamientos`}
                />
            </Helmet>
            <div className="p-10 bg-blue-300 rounded-lg">
                {" "}
                <h4>Tu compra se realizó correctamente</h4>
            </div>

            <Footer />
        </div>
    );
};

export default SuccessCuotealo;
