const formatedDate_YYYY_MM_DD = (newDate) => {
    let formatedDate;
    const getMonth = (month) => {
        const newMonth = month + 1;
        if (newMonth < 10) return "0" + newMonth;
        else return newMonth;
    };

    const getDay = (day) => {
        const newDay = day;
        if (newDay < 10) return "0" + newDay;
        else return newDay;
    };

    formatedDate = `${newDate.getFullYear()}-${getMonth(
        newDate.getMonth()
    )}-${getDay(newDate.getDate())}`;

    return formatedDate;
};

export default formatedDate_YYYY_MM_DD;
