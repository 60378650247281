import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavBar from "../NavBar";
import Footer from "../Footer";
import axios from "axios";

import CardHouses from "../ownersStore/CardHouses";
import { Paginado } from "../helpers/Paginado";
import useScrollTo from "../../hooks/useScrollToTop";
import Spinner from "../Spinner";
import colores from "../helpers/colores";
import NavbarEmpresas from "../dashboardEmpresas/NavbarEmpresas";
import { NavbarEmpresasNew } from "../dashboardEmpresas/NavbarEmpresasNew";
import { NavbarMobileEmpresasNew } from "../dashboardEmpresas/NavbarMobileEmpresasNew";
import NavBarHome from "../NavBarHome";

const Favorite = ({ desdeDashboard = false, empresa = false }) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const [allHouses, setAllHouses] = useState([]);
    const [allBoats, setAllBoats] = useState([]);
    const [totalCasa, setTotalCasa] = useState(0);
    const [totalEmbarcaciones, setTotalEmbarcaciones] = useState(0);
    const [sinFavoritoscasa, setSinFavoritosCasa] = useState(false);
    const [sinFavoritosembarcaciones, setSinFavoritosEmbarcaciones] =
        useState(false);
    const CASAS_PER_PAGE = 10;
    const EMBARCACIONES_PER_PAGE = 10;

    const authUser = useSelector((state) => state.authReducer);

    useScrollTo();

    const getPropietarioHouses = async (id, numero = 1) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/favorito/allCasas/${numero}/${id}`,
            { headers }
        );

        setTotalCasa(data.total);
        if (data.data.length) {
            setAllHouses(data.data);
        } else {
            setSinFavoritosCasa(true);
        }
    };

    const getPropietarioBoats = async (id) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/favorito/allEmbarcaciones/1/${id}`,
            { headers }
        );

        setTotalEmbarcaciones(data.total);
        if (data.data.length) {
            setAllBoats(data.data);
        } else {
            setSinFavoritosEmbarcaciones(true);
        }
    };

    useEffect(() => {
        getPropietarioHouses(authUser.id_usuario);
        getPropietarioBoats(authUser.id_usuario);
    }, []);

    const eliminarFavorito = async (id, tipo) => {
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/favorito/eliminar`,
            {
                id_producto: id,
                id_cliente: authUser.id_usuario,
                tipo: tipo,
            },
            { headers }
        );

        if (json.data.message === "Registro eliminado con exito") {
            if (tipo === "casa") {
                setAllHouses(allHouses.filter((house) => house.id !== id));
            } else {
                setAllBoats(allBoats.filter((boat) => boat.id !== id));
            }
        }
    };

    const paginationCasa = (pageNumber) => {
        getPropietarioHouses(authUser.id_usuario, pageNumber);
    };

    const paginationEmbarcaciones = (pageNumber) => {
        getPropietarioBoats(authUser.id_usuario, pageNumber);
    };

    return (
        <>
            {empresa ? (
                <NavbarEmpresasNew>
                    <div
                        className={
                            desdeDashboard
                                ? "w-full p-4  md:mx-auto"
                                : "flex flex-col mt-32 "
                        }
                    >
                        {!desdeDashboard && <NavBarHome explorer={false} />}
                        <div className=" xs:pl-0 ">
                            <h2
                                className={`mx-auto w-9/12 xs:w-7/12 text-2xl xs:text-4xl  text-white font-bold uppercase text-center bg-[${colores.secundario}] rounded-lg p-2 mt-5`}
                            >
                                Mis Favoritos
                            </h2>
                            <div className="text-base w-[50rem] text-justify mx-auto mt-3">
                                Aca puedes guardar tus alojamientos o
                                embarcaciones que sean de tu interes, si deseas
                                guardar alguna pudes visitar el link de la casa
                                y darle al corazón.
                            </div>
                        </div>
                        <div>
                            <h1 className="mx-auto text-3xl uppercase font-mono font-semibold w-max p-1 mt-5">
                                Alojamientos{" "}
                            </h1>
                            <hr className="w-10/12 mx-auto"></hr>
                            <div>
                                <div className="md:w-4/5 md:mx-auto md:p-0">
                                    {totalCasa < 10 ? null : (
                                        <Paginado
                                            elementsPerPage={CASAS_PER_PAGE}
                                            cantTotalElements={totalCasa}
                                            pagination={paginationCasa}
                                        />
                                    )}
                                </div>
                                {allHouses?.length > 0 ? (
                                    <div className="grid-cols-2 gap-5 mb-20 p-10 sm:grid sm:p-4 lg:grid-cols-3 2xl:grid-cols-4 md:w-4/5 md:mx-auto md:p-0 2xl:gap-10">
                                        {allHouses.map((house) => (
                                            <CardHouses
                                                key={house.id}
                                                house={house}
                                                tipo={"casa"}
                                                eliminarFavorito={
                                                    eliminarFavorito
                                                }
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="mb-20">
                                        {sinFavoritoscasa ? (
                                            <h2 className="mx-auto text-3xl font-bold uppercase text-center pt-4">
                                                Aun no tienes Alojamientos
                                                favoritos
                                            </h2>
                                        ) : (
                                            <Spinner width={20} />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <h1 className="mx-auto text-3xl uppercase font-mono font-semibold w-max p-1 ">
                                Embarcaciones{" "}
                            </h1>
                            <hr className="w-10/12 mx-auto"></hr>

                            <div>
                                <div className="md:w-4/5 md:mx-auto md:p-0 ">
                                    {totalEmbarcaciones < 10 ? null : (
                                        <Paginado
                                            elementsPerPage={
                                                EMBARCACIONES_PER_PAGE
                                            }
                                            cantTotalElements={
                                                totalEmbarcaciones
                                            }
                                            pagination={paginationEmbarcaciones}
                                        />
                                    )}
                                </div>
                                {allBoats?.length > 0 ? (
                                    <div className="grid-cols-2 gap-5 mb-20 p-10 sm:grid sm:p-4 lg:grid-cols-3 2xl:grid-cols-4 md:w-4/5 md:mx-auto md:p-0 2xl:gap-10">
                                        {allBoats.map((boat) => (
                                            <CardHouses
                                                key={boat.id}
                                                house={boat}
                                                tipo={"embarcacion"}
                                                eliminarFavorito={
                                                    eliminarFavorito
                                                }
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="mb-20">
                                        {sinFavoritosembarcaciones ? (
                                            <h2 className="mx-auto text-3xl font-bold uppercase text-center pt-4">
                                                Aun no tienes Embarcaciones
                                                favoritas
                                            </h2>
                                        ) : (
                                            <Spinner width={20} />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {!desdeDashboard && <Footer />}
                    </div>
                    <NavbarMobileEmpresasNew />
                </NavbarEmpresasNew>
            ) : (
                <div
                    className={
                        desdeDashboard
                            ? "w-full md:pl-[13rem] md818:pl-[14rem] md920:pl-[14rem] lg:pl-[17rem] p-4"
                            : "flex flex-col "
                    }
                >
                    {!desdeDashboard && <NavBarHome explorer={false} />}
                    <div className=" xs:pl-0 ">
                        <h2
                            className={`mx-auto w-9/12 xs:w-7/12 text-2xl xs:text-4xl  text-white font-bold uppercase text-center bg-[${colores.secundario}] rounded-lg p-2 mt-5`}
                        >
                            Mis Favoritos
                        </h2>
                    </div>
                    <div>
                        <h1 className="mx-auto text-3xl uppercase font-mono font-semibold w-max p-1 mt-5">
                            Alojamientos{" "}
                        </h1>
                        <hr className="w-10/12 mx-auto"></hr>
                        <div>
                            <div className="md:w-4/5 md:mx-auto md:p-0">
                                {totalCasa < 10 ? null : (
                                    <Paginado
                                        elementsPerPage={CASAS_PER_PAGE}
                                        cantTotalElements={totalCasa}
                                        pagination={paginationCasa}
                                    />
                                )}
                            </div>
                            {allHouses?.length > 0 ? (
                                <div className="grid-cols-2 gap-5 mb-20 p-10 sm:grid sm:p-4 lg:grid-cols-3 2xl:grid-cols-4 md:w-4/5 md:mx-auto md:p-0 2xl:gap-10">
                                    {allHouses.map((house) => (
                                        <CardHouses
                                            key={house.id}
                                            house={house}
                                            tipo={"casa"}
                                            eliminarFavorito={eliminarFavorito}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="mb-20">
                                    {sinFavoritoscasa ? (
                                        <h2 className="mx-auto text-3xl font-bold uppercase text-center pt-4">
                                            Aun no tienes Alojamientos favoritos
                                        </h2>
                                    ) : (
                                        <Spinner width={20} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <h1 className="mx-auto text-3xl uppercase font-mono font-semibold w-max p-1 ">
                            Embarcaciones{" "}
                        </h1>
                        <hr className="w-10/12 mx-auto"></hr>

                        <div>
                            <div className="md:w-4/5 md:mx-auto md:p-0 ">
                                {totalEmbarcaciones < 10 ? null : (
                                    <Paginado
                                        elementsPerPage={EMBARCACIONES_PER_PAGE}
                                        cantTotalElements={totalEmbarcaciones}
                                        pagination={paginationEmbarcaciones}
                                    />
                                )}
                            </div>
                            {allBoats?.length > 0 ? (
                                <div className="grid-cols-2 gap-5 mb-20 p-10 sm:grid sm:p-4 lg:grid-cols-3 2xl:grid-cols-4 md:w-4/5 md:mx-auto md:p-0 2xl:gap-10">
                                    {allBoats.map((boat) => (
                                        <CardHouses
                                            key={boat.id}
                                            house={boat}
                                            tipo={"embarcacion"}
                                            eliminarFavorito={eliminarFavorito}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="mb-20">
                                    {sinFavoritosembarcaciones ? (
                                        <h2 className="mx-auto text-3xl font-bold uppercase text-center pt-4">
                                            Aun no tienes Embarcaciones
                                            favoritas
                                        </h2>
                                    ) : (
                                        <Spinner width={20} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {!desdeDashboard && <Footer />}
                </div>
            )}
        </>
    );
};

export default Favorite;
