import React, { useEffect, useReducer, useState } from "react";
import headerHelpCenter from "../../img/Home/head-faq.jpg";
import axios from "axios";
import NavBar from "../../components/NavBar";
import Footer from "../Footer";
import FlexibleColum from "./FlexibleColum";
import NavBarHome from "../NavBarHome";

const dropDownsReducer = (state, action) => {
    switch (action.type) {
        case "menuVisible":
            const property = action.payload.toString();
            return {
                ...state,
                [property]: !state[property],
            };

        default:
            return state;
    }
};

const HelpCenter = () => {
    const [input, setInput] = useState({});
    const [allManuals, setAllManuals] = useState([]);
    const [manualSelected, setManualSelected] = useState(null);
    const createInitialState = () => {
        const INITIAL_STATE = {};
        const menusDropdown = allManuals.map(
            (manualName) => manualName.tipo_manual
        );
        menusDropdown.forEach((menuName) => (INITIAL_STATE[menuName] = false));
        return INITIAL_STATE;
    };
    const [state, dispatch] = useReducer(dropDownsReducer, createInitialState);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const getAllManuals = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/manual/allByTipoManual`
        );
        setAllManuals(data);
    };

    useEffect(() => {
        getAllManuals();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <NavBarHome explorer={false} />
            <div className="flex flex-col select-none h-[35rem] bg-red-100">
                <div
                    className="w-full h-full bg-no-repeat bg-cover lg:h-max min-h-max"
                    style={{
                        backgroundImage: `url(${headerHelpCenter})`,
                        filter: "brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
                    }}
                >
                    <div
                        style={{
                            backgroundImage:
                                "linear-gradient(180deg, #053465 0%, rgba(5, 52, 101, 0) 100%)",
                        }}
                        className="h-full pt-40 md:p-32"
                    >
                        <div className="pt-4 mx-auto text-4xl font-normal text-center text-white lg:pt-56 md:pt-40">
                            <span className="font-sans font-bold tracking-widest text-center">
                                CENTRO DE AYUDA
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" bg-[#f3f3f3]  lg:flex  text-center flex-col items-center justify-center md:mt-0 pt-20 hidden"></div>
            <div className=" md:grid grid-cols-[40%_60%] justify-center w-11/12 lg:w-4/6 mx-auto  gap-4 select-none  mb-20 mt-24 p-4 ">
                <div className="text-[#053465] text-xl font-bold p-4 mx-5 bg-white w-full -mt-4 border-2">
                    {allManuals.map((manual) => (
                        <div
                            className="mt-5"
                            key={manual.id_tipo_manual}
                            onClick={(e) => {
                                dispatch({
                                    type: "menuVisible",
                                    payload: manual.tipo_manual,
                                });
                            }}
                        >
                            <ul className="items-center w-full ">
                                <div className="flex items-center justify-between cursor-pointer">
                                    <div className="flex">
                                        <svg
                                            className="text-indigo-500 h-9 w-9 "
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />
                                            <path d="M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                                            <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2" />
                                        </svg>
                                        <span className="mt-1 ml-1.5">
                                            {manual.tipo_manual}
                                        </span>
                                    </div>
                                    <span className="flex  bg-[#c58b68] text-white w-8 h-8 justify-center items-center  rounded-full">
                                        {manual.manual.length}
                                    </span>
                                </div>
                                <div
                                    className={`${
                                        state[manual.tipo_manual]
                                            ? "flex flex-col"
                                            : " hidden"
                                    }  `}
                                >
                                    {manual.manual.map((manualHijo) => (
                                        <li
                                            className="flex items-center mt-4 text-base font-normal transition ease-in-out transform cursor-pointer hover:scale-105"
                                            key={manualHijo.id}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setManualSelected(manualHijo);
                                            }}
                                        >
                                            <svg
                                                className="mr-1 text-gray-500 h-7 w-7"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <rect
                                                    x="5"
                                                    y="3"
                                                    width="14"
                                                    height="18"
                                                    rx="2"
                                                />{" "}
                                                <line
                                                    x1="9"
                                                    y1="7"
                                                    x2="15"
                                                    y2="7"
                                                />{" "}
                                                <line
                                                    x1="9"
                                                    y1="11"
                                                    x2="15"
                                                    y2="11"
                                                />{" "}
                                                <line
                                                    x1="9"
                                                    y1="15"
                                                    x2="13"
                                                    y2="15"
                                                />
                                            </svg>{" "}
                                            {manualHijo.nombre}
                                        </li>
                                    ))}
                                </div>
                            </ul>
                        </div>
                    ))}
                </div>
                {manualSelected && <FlexibleColum manual={manualSelected} />}
            </div>
            <Footer />
        </div>
    );
};

export default HelpCenter;
