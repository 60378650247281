import React from "react";

import imagenBanner from "../../../img/Casa-Mario.png";
import encabezado from "../../../img/Encabezado.png";
import videoCasaMario from "../../../img/video-casa-mario.mp4";
import { FormularioReservaCasa } from "./FormularioReservaCasa";

export const Banner = () => {
    return (
        <div className="">
            <div
                className="flex items-center justify-center md:rounded-br-[12%] md:rounded-bl-[12%]   bg-cover bg-center bg-no-repeat py-8 bg-[#053465] bg-opacity-30"
                style={{
                    backgroundImage: `linear-gradient(45deg, #0E0F10, #5485B7), url(${imagenBanner})`,
                    backgroundSize: "cover",
                    // height: "50vh",
                    backgroundPosition: "center",
                    backgroundBlendMode: "overlay",
                }}>
                <div className="w-11/12 h-full mx-auto text-white md:w-4/5">
                    <div className="w-full">
                        <img
                            src={encabezado}
                            alt="Encabezado"
                            className="md:w-[12%] w-[35%] "
                        />
                    </div>
                    <div className="flex items-center justify-center w-full md:justify-start md:items-start">
                        <p className="mt-6 text-sm md:text-base text-center border-[#0F80F2] border rounded-full inline-flex justify-center items-center gap-2 px-5 py-1 md:mx-0 mx-auto">
                            Ubicado en{" "}
                            <span className="font-bold">Cieneguilla</span>
                        </p>
                    </div>

                    <div className="grid w-full grid-cols-1 gap-4 mt-5  md:grid-cols-[60%,40%]">
                        <div className="">
                            <div className="w-full md:w-11/12">
                                <p className="text-2xl font-bold text-center  2xl:text-5xl md:text-4xl md:text-left">
                                    ¡Tu escape perfecto al campo!
                                </p>
                                <p className="mt-5 text-sm text-center md:mt-5 2xl:text-xl md:text-base md:text-left">
                                    Disfruta{" "}
                                    <span className="font-bold">
                                        3 días y 2 noches
                                    </span>{" "}
                                    por solo
                                    <span className="font-bold">
                                        {" "}
                                        S/4100 para máximo 12 personas
                                    </span>
                                </p>
                                <p className="w-4/5 mx-auto mt-4 text-sm text-center 2xl:text-base md:text-left md:mx-0">
                                    Relájate en una hermosa casa de campo
                                    equipada con todo lo que necesitas para
                                    desconectarte de la rutina y disfrutar con
                                    amigos o familiares.
                                </p>
                                <p className="text-sm font-bold text-center 2xl:text-base md:text-left">
                                    ¡Aprovecha esta oferta limitada!
                                </p>
                            </div>
                            <div className="w-full mx-auto mt-6 md:w-4/5 md:mx-0">
                                <video
                                    className="mt-4 rounded-md"
                                    src={videoCasaMario}
                                    controls
                                    width="100%"></video>
                            </div>
                        </div>
                        <div
                            id="formularioReserva"
                            className="h-full max-w-sm ">
                            <FormularioReservaCasa />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
