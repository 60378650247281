import axios from "axios";
import React, { useEffect, useState, lazy } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import useColores from "../../../hooks/useColores";
import useHeaders from "../../../hooks/useHeaders";
import NavBarEmpresas from "../../dashboardEmpresas/NavbarEmpresas";
import NavBar from "../../NavBar";
import Spinner from "../../Spinner";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import getPriceCard from "../../helpers/getPriceCard";
/* import FormStripePaquetes from "./FormStripePaquetes"; */
import ButtonRegresar from "../../ButtonRegresar";
import NavBarHome from "../../NavBarHome";

const FormStripePaquetes = lazy(() => import("./FormStripePaquetes"));

const CheckoutPaquetesEmpresariales = () => {
    const [pedido, setPedido] = useState(null);
    const { idPedido } = useParams();
    const { headers } = useHeaders();
    const { primario, secundario } = useColores();
    const { tipo_usuario } = useSelector((state) => state.authReducer);
    const [paying, setPaying] = useState(false);
    const [formaPago, setFormaPago] = useState("stripe");
    const navigate = useNavigate();
    const [comprobantePreview, setComprobantePreview] = useState(null);
    const [comprobante, setComprobante] = useState(null);
    const [habilitarModal, setHabilitarModal] = useState(false);
    const [modalLogeo, setModalLogeo] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );
    const form = new FormData();

    const getPedido = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/pedidoPaquetesEmpresas/detalle/${idPedido}`,
            { headers }
        );

        setPedido(data);

        console.log(data);
    };

    useEffect(() => {
        getPedido();
    }, []);

    const handleComprobante = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        setComprobante(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setComprobantePreview(objectUrl);
    };

    const handlePagoComprobante = async () => {
        setPaying(true);
        const file = comprobante;

        console.log("entrando a comprobante");
        form.append("id_pedidos_paquete_empresa", idPedido);
        form.append("imagen_pedido_transaccion", file);
        form.append("idEmpresa", pedido.pedido_paquete_empresa.id_empresa);

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/pedidoPaquetesEmpresasTransaccion/`,
                form,
                { headers }
            );
            console.log(data);
            if (data.message === "registro exitoso") {
                navigate(`/pago_aprobado/comprobante/${pedido.id}`);
            }
        } catch (error) {
            Swal.fire({
                title: error.response.data,
                icon: "error",
            });
            setPaying(false);
        }
    };

    if (pedido && pedido?.pedido_paquete_empresa.estado_pago !== "No pagado") {
        return (
            <div>
                {tipo_usuario === 6 ? (
                    <NavBarEmpresas />
                ) : (
                    <NavBarHome explorer={false} />
                )}
                <ButtonRegresar />
                <div className={`flex flex-col select-none`}>
                    <div className={`bg-[${primario}] text-center`}>
                        <h1 className=" text-4xl font-bold text-white">
                            Checkout
                        </h1>
                    </div>
                    <div className="p-36">
                        <div className="p-10 text-xl border-8 border-dotted md:text-center md:text-3xl">
                            Este pago ya ha sido abonado por la empresa, muchas
                            gracias por su reserva.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (pedido && pedido?.pedido_paquete_empresa.estado_pago === "No pagado") {
        return (
            <div>
                {tipo_usuario === 6 ? (
                    <NavBarEmpresas />
                ) : (
                    <NavBarHome explorer={false} />
                )}

                <ButtonRegresar />
                <div className="p-4 md:p-10">
                    <h1 className="text-[#053465] font-semibold text-xl">
                        Checkout paquetes empresariales
                    </h1>
                    <div
                        className={`text-[${primario}] font-semibold text-base lg:grid lg:grid-cols-[50%_50%] flex flex-col-reverse`}
                    >
                        <div className="md:p-10">
                            <div
                                className={`rounded-lg border-2 border-gray-600 text-[${primario}] p-5 text-xl`}
                            >
                                <h1>Elige tu metodo de pago</h1>
                                <hr className="my-5 border-t-2 border-gray-300" />
                                <p className="text-lg font-bold">
                                    Pago con tarjeta de crédito/débito cuenta
                                    con un costo adicional del 5% del monto a
                                    pagar.
                                </p>
                                <select
                                    onChange={(e) => {
                                        setFormaPago(e.target.value);
                                    }}
                                    value={formaPago}
                                    className="bg-slate-100 mt-7 p-3 max-w-[500px] w-full border-gray-400 border rounded-md"
                                >
                                    <option className="p-10" value="stripe">
                                        Tarjeta de crédito o débito
                                    </option>
                                    <option
                                        className="p-10"
                                        value="transferencia"
                                    >
                                        Transferencia bancaria
                                    </option>
                                </select>
                                {formaPago === "stripe" && (
                                    <Elements stripe={stripePromise}>
                                        <div className="flex w-full mt-5">
                                            <FormStripePaquetes
                                                tipo_pedido={"Paquetes"}
                                                costos={{
                                                    costo: pedido
                                                        .pedido_paquete_empresa
                                                        .costo_total,
                                                    costo_tarjeta: getPriceCard(
                                                        pedido
                                                            .pedido_paquete_empresa
                                                            .costo_total
                                                    ),
                                                    metodo: "Stripe",
                                                }}
                                                pedido={pedido}
                                                amount={
                                                    pedido
                                                        .pedido_paquete_empresa
                                                        .costo_total +
                                                    getPriceCard(
                                                        pedido
                                                            .pedido_paquete_empresa
                                                            .costo_total
                                                    )
                                                }
                                            />
                                        </div>
                                    </Elements>
                                )}
                                {formaPago === "transferencia" && (
                                    <div>
                                        <p className="mt-10 text-base font-bold text-justify">
                                            Realiza tu pago directamente en
                                            nuestra cuenta bancaria. Por favor,
                                            usa el número del pedido como
                                            referencia de pago. Tu pedido no se
                                            procesará hasta que se haya recibido
                                            el importe en nuestra cuenta.
                                        </p>
                                        <div className="w-full mt-4 text-base font-normal bg-white rounded-md">
                                            {
                                                <div>
                                                    <div className="flex flex-row">
                                                        <p className="font-bold">
                                                            Banco:
                                                        </p>{" "}
                                                        <p className="ml-3">
                                                            BANCO DE CRÉDITO DEL
                                                            PERÚ
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <p className="font-bold">
                                                            Tipo de cuenta:
                                                        </p>{" "}
                                                        <p className="ml-3">
                                                            CUENTA CORRIENTE
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <p className="font-bold">
                                                            Cuenta en Dólares:
                                                        </p>{" "}
                                                        <p className="ml-3">
                                                            193 - 2457006 - 1 -
                                                            51
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <p className="font-bold">
                                                            CCI:
                                                        </p>{" "}
                                                        <p className="ml-3">
                                                            00219300245700615115
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <p className="font-bold">
                                                            Titular de cuenta:
                                                        </p>{" "}
                                                        <p className="ml-3">
                                                            LA ESQUINA AZUL
                                                            S.A.C.
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            <br />
                                            <p className="font-bold">
                                                Numero de pedido: #{idPedido}
                                            </p>
                                        </div>
                                        <hr className="border-t-2 border-gray-300 my-15" />
                                        <h1 className="mt-4 font-normal">
                                            Por favor, adjuntar su comprobante
                                            en formato Imagen
                                        </h1>
                                        <div className="relative flex mt-4 bg-white border-4 border-dashed rounded-lg w-72 h-72 ">
                                            {comprobantePreview ? (
                                                <div className="w-full h-full rounded-lg ">
                                                    <img
                                                        className="object-cover w-full h-full rounded-lg"
                                                        src={comprobantePreview}
                                                        alt="Comprobante de pago"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="flex items-center justify-center p-3 ">
                                                    <div className="flex flex-col items-center justify-center w-full mx-auto ">
                                                        <div className="relative flex flex-col items-center justify-center">
                                                            <span className="mt-2 font-bold text-center ">
                                                                Arrastre el
                                                                comprobante
                                                            </span>
                                                            o
                                                            <span
                                                                className={`font-normal text-center text-[${secundario}]`}
                                                            >
                                                                Sube la imagen
                                                                desde tu
                                                                dispositivo
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <input
                                                type="file"
                                                onChange={handleComprobante}
                                                accept="image/*"
                                                className="absolute top-0 left-0 bg-red-200 opacity-0 cursor-pointer w-72 h-72 file:cursor-pointer"
                                            />
                                        </div>

                                        <button
                                            className={`payButton active:translate-y-1 w-full max-w-[288px] disabled:bg-slate-400 hover:disabled:bg-slate-400`}
                                            style={{
                                                backgroundColor: secundario,
                                            }}
                                            disabled={!formaPago || paying}
                                            onClick={() =>
                                                handlePagoComprobante()
                                            }
                                        >
                                            {paying ? (
                                                <Spinner width={7} />
                                            ) : (
                                                "Pagar"
                                            )}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="my-4 md:p-10 md:my-0">
                            <table className="w-full border-2 border-gray-600 bordered">
                                <thead>
                                    <tr>
                                        <th
                                            className="p-4 uppercase border-2 border-gray-600"
                                            colSpan={2}
                                        >
                                            Producto
                                        </th>
                                        <th
                                            className="p-4 uppercase border-2 border-gray-600"
                                            colSpan={2}
                                        >
                                            Subtotal
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            className="p-4 border-2 border-gray-600"
                                            colSpan={3}
                                        >
                                            {/* <div className="flex flex-auto">detalle</div> */}

                                            <p className="flex items-center font-medium">
                                                <img
                                                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${pedido.pedido_paquete_empresa?.imagen_minificada}`}
                                                    alt="Imagen principal"
                                                    className="object-cover w-20 h-20 mr-4 rounded-lg"
                                                />
                                                {
                                                    pedido
                                                        .pedido_paquete_empresa
                                                        ?.paquete_empresa
                                                }
                                            </p>
                                        </td>
                                        <td
                                            className="p-2 border-2 border-gray-600"
                                            colSpan={1}
                                        >
                                            <span className="flex font-semibold ">
                                                {pedido.pedido_paquete_empresa
                                                    ?.costo_paquete > 0
                                                    ? pedido
                                                          .pedido_paquete_empresa
                                                          ?.costo_paquete +
                                                      " USD"
                                                    : "a definir"}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        className={
                                            pedido
                                                .detalle_pedido_paquete_empresa
                                                .length > 0
                                                ? ""
                                                : "hidden"
                                        }
                                    >
                                        <td
                                            className="p-4 border-2 border-gray-600"
                                            colSpan={3}
                                        >
                                            <h3>Servicios extras</h3>
                                        </td>
                                        <td
                                            className="p-2 font-semibold border-2 border-gray-600"
                                            colSpan={1}
                                        >
                                            <h3></h3>
                                        </td>
                                    </tr>
                                    {pedido.detalle_pedido_paquete_empresa?.map(
                                        (detalle) => (
                                            <tr
                                                key={
                                                    detalle.id_paquetes_empresas
                                                }
                                            >
                                                <td
                                                    className="p-4 border-2 border-gray-600"
                                                    colSpan={3}
                                                >
                                                    <div className="flex flex-col items-center gap-3 md:flex-row">
                                                        <img
                                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${detalle.imagen_minificada}`}
                                                            alt="Imagen attribute"
                                                            className="object-cover w-20 h-20 rounded-lg"
                                                        />
                                                        <div className="flex flex-col text-left">
                                                            <div className="">
                                                                <h1 className="font-bold">
                                                                    {
                                                                        detalle?.nombre_paquete
                                                                    }
                                                                </h1>
                                                                <span>
                                                                    {
                                                                        detalle?.descripcion_paquete
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div>
                                                                Para{" "}
                                                                {
                                                                    detalle.numero_personas
                                                                }{" "}
                                                                personas
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td
                                                    className="p-4 border-2 border-gray-600"
                                                    colSpan={1}
                                                >
                                                    {detalle.costo} USD{" "}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                    {pedido.pedido_paquete_empresa?.descuento >
                                        0 && (
                                        <tr>
                                            <td
                                                className="p-4 border-2 border-gray-600"
                                                colSpan={3}
                                            >
                                                <h3>Descuento</h3>
                                            </td>
                                            <td
                                                className="p-2 font-semibold border-2 border-gray-600"
                                                colSpan={1}
                                            >
                                                <h3>
                                                    -
                                                    {
                                                        pedido
                                                            .pedido_paquete_empresa
                                                            ?.descuento
                                                    }{" "}
                                                    USD
                                                </h3>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td
                                            className="p-4 border-2 border-gray-600"
                                            colSpan={3}
                                        >
                                            Costo total:
                                        </td>
                                        <td
                                            className="p-4 border-2 border-gray-600"
                                            colSpan={1}
                                        >
                                            {
                                                pedido?.pedido_paquete_empresa
                                                    .costo_total
                                            }{" "}
                                            USD
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex items-center justify-center h-screen">
                <Spinner />
            </div>
        );
    }
};
export default CheckoutPaquetesEmpresariales;
