import React from "react";
import CasaMario1 from "../../../img/landing-casa-mario/Casa-mario-1.png";
import CasaMario2 from "../../../img/landing-casa-mario/Casa-mario-2.png";
import CasaMario3 from "../../../img/landing-casa-mario/Casa-mario-3.png";
import CasaMario4 from "../../../img/landing-casa-mario/Casa-mario-4.png";
import CasaMario5 from "../../../img/landing-casa-mario/Casa-mario-5.png";
import CasaMario6 from "../../../img/landing-casa-mario/Casa-mario-6.jpg";
import CasaMario7 from "../../../img/landing-casa-mario/Casa-mario-7.png";
import CasaMario8 from "../../../img/landing-casa-mario/Casa-mario-8.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";

const IMAGES = [
    CasaMario1,
    CasaMario2,
    CasaMario3,
    CasaMario4,
    CasaMario5,
    CasaMario6,
    CasaMario7,
    CasaMario8,
];

const Images = () => {
    return (
        <div className="md:py-3">
            <hr className="border-t-2 border-[#0F80F2] w-3/4 mx-auto" />
            <div className="flex justify-center items-center gap-5 mt-5">
                <div className="md:text-4xl text-2xl text-center bg-gradient-to-r font-bold from-[#053465] to-[#0A69CB] inline-block text-transparent bg-clip-text">
                    Casa Mario
                </div>
                <div className=" border-l-2 border-[#0F80F2] text-[#0F80F2] md:text-3xl text-xl pl-5 ">
                    Cieneguilla, Lima
                </div>
            </div>
            <div className="flex justify-center">
                <div className="inline-flex justify-center items-center gap-5 border-2 border-[#0F80F2] mt-5 rounded-full px-5 py-1 mx-auto md:text-base text-xs">
                    <div className=" text-[#0F80F2] ">12 Personas</div>
                    <div className="w-1 h-1 bg-[#053465] rounded-full "></div>
                    <div className="text-[#0F80F2]">3 Habitaciones</div>
                    <div className="w-1 h-1 bg-[#053465] rounded-full "></div>
                    <div className="text-[#0F80F2]">3 Baños</div>
                </div>
            </div>

            <div className="mt-5 w-4/5 mx-auto">
                <Swiper
                    className="w-full rounded-2xl"
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={10}
                    slidesPerView={1}
                    rewind={true}
                    autoplay={{ delay: 3000 }}
                    loop={true}
                    /*  navigation
                    pagination={{ clickable: true }} */
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                        },
                    }}
                >
                    {IMAGES.map((imagen, index) => (
                        <SwiperSlide
                            key={index}
                            // className="flex flex-col w-full md:px-1 lg:px-1 rounded-xl "
                            className="h-[25rem] 2xl:w-full w-5/6 rounded-xl bg-white select-none flex justify-center items-center"
                        >
                            <img
                                alt="Imagen no encontrada"
                                src={imagen}
                                className="w-full mx-auto transition-all duration-200 ease-out rounded-xl h-[18rem] md:h-[20rem] 2xl:h-[30rem] "
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default Images;
