import React from "react";

import bannerCuotealoFooter from "../../img/Home/banner-cuotealo.png";
import bannerCuotealoFooterMobile from "../../img/Home/banner-cuotealo-footer.png";

const BannerCuotealoFooter = () => {
    return (
        <div className="xl:w-10/12 w-11/12 mx-auto mb-10">
            <picture>
                <source
                    media="(max-width: 600px)"
                    srcSet={bannerCuotealoFooterMobile}
                />
                <img
                    src={bannerCuotealoFooter}
                    alt="banner cuotealo"
                    className="w-full"
                />
            </picture>
        </div>
    );
};

export default BannerCuotealoFooter;
