import React, { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useSelector } from "react-redux";
import axios from "axios";
import dividirFechaDatePicker from "../../helpers/dividirFechaDatePicker";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { Calendar } from "react-calendar";
import formatedDate_YYYY_MM_DD from "../../helpers/formatedDate_YYYY_MM_DD";
import { useParams } from "react-router";
export const FormularioReservaCasa = ({}) => {
    registerLocale("es", es);
    const authUser = useSelector((state) => state.authReducer);
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const { fechas } = useParams();

    const [enabledPersonas, setEnabledPersonas] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [cargando, setCargando] = useState(false);

    const [input, setInput] = useState({
        nombre: "",
        apellido: "",
        correo: "",

        fecha_inicio: "Fecha no seleccionada",
        fecha_final: "Fecha no seleccionada",
        nota: "",
        celular: "",
    });
    const [currentProduct, setCurrentProduct] = useState({});

    const [arrayMeses, setArrayMeses] = useState([]);
    const [arrayDias, setArrayDias] = useState([]);
    const [arrayFechas, setArrayFechas] = useState([]);
    const [arrayPedidos, setArrayPedidos] = useState([]);
    const [cantDays, setCantDays] = useState(0);
    const [selectedDays, setSelectedDays] = useState([]);
    const [errorDates, setErrorDates] = useState(null);

    const getProductDetails = async () => {
        const { data } = await axios(
            `${process.env.REACT_APP_PROXY}/casa/detalle/19040/${
                authUser.logged ? authUser.id_usuario : 0
            }`
        );

        setCurrentProduct({ ...data, huespedes: 0 });
        setArrayPedidos(data.disponibilidad.pedidos);
        setArrayDias(data.disponibilidad.arrayDias);
        setArrayMeses(data.disponibilidad.arrayMeses);
        setArrayFechas(data.disponibilidad.arrayFechas);
    };

    const enviar = async () => {
        if (
            input.nombre === "" ||
            input.correo === "" ||
            input.celular === ""
        ) {
            Swal.fire({
                icon: "error",
                title: "Todos los campos son requeridos",
                showConfirmButton: false,
                timer: 1500,
            });
            return;
        }
        setCargando(true);

        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/casa/enviarCotizacionFormularioCasaMario`,
            {
                nombre: `${input.nombre}`,
                correo: input.correo,
                celular: input.celular,
            },
            { headers }
        );
        setCargando(false);

        const mensaje = `
Hola Estoy interesad@ en reservar *Casa Mario*

*Mis datos son*:
*Nombre*: ${input.nombre}
*Correo*: ${input.correo}`;

        const mensajeCodificado = encodeURIComponent(mensaje);

        // const numeroWhatsApp = "554598373045";
        const numeroWhatsApp = "51941110270";

        const urlWhatsApp = `https://wa.me/${numeroWhatsApp}?text=${mensajeCodificado}`;
        window.open(urlWhatsApp, "_blank");
        return;
    };

    const [selectedRange, setSelectedRange] = useState(null);
    const getDaysBetween2Dates = (arrayDates) => {
        const [dateOne, dateTwo] = arrayDates;
        let nuevosDias = 0;

        const primerAño = dateOne.getFullYear();
        const primerMes = dateOne.getMonth();
        const primerDia = dateOne.getDate();
        let nuevaPrimeraFecha = new Date(primerAño, primerMes, primerDia);

        while (nuevaPrimeraFecha <= dateTwo) {
            nuevaPrimeraFecha.setDate(nuevaPrimeraFecha.getDate() + 1);
            nuevosDias++;
        }

        return nuevosDias;
    };

    const allowReservation = (
        array_dias_reserva,
        array_dias_disponibilidad,
        numero_noches_necesarios
    ) => {
        let cantDiasCoinciden = 0;

        const new_array_disponiblidad = array_dias_disponibilidad.map((day) =>
            day.getTime()
        );

        array_dias_reserva.forEach((day_reserva) => {
            if (new_array_disponiblidad.includes(day_reserva.getTime())) {
                cantDiasCoinciden += 1;
            }
        });

        if (cantDiasCoinciden - 1 >= numero_noches_necesarios) return true;
        else return false;
    };

    const onChangeCalendar = (twoDates) => {
        let dates = twoDates;
        setSelectedRange(dates);
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        let dateOne = dates[0];
        let dateTwo = dates[1];

        let totalDays = getDaysBetween2Dates(dates);

        const allDatesSelected = getEveryDayBetween2Dates(dates).map((date) =>
            date.getTime()
        );
        const allDaysSelected = getEveryDayBetween2Dates(dates).map((date) =>
            date.getDay()
        );
        const allMonthssSelected = getEveryDayBetween2Dates(dates).map((date) =>
            date.getMonth()
        );

        const isAvailable = currentProduct?.minima_disponibilidad.map(
            ({ fecha_inicio, fecha_final, numero_noches }) => {
                const FDI = new Date(fecha_inicio + "T00:00:00");
                const FDF = new Date(fecha_final + "T00:00:00");
                const [FRI, FRF] = dates;

                const array_dias_disponibilidad = getEveryDayBetween2Dates([
                    FDI,
                    FDF,
                ]);
                const array_dias_reserva = getEveryDayBetween2Dates([FRI, FRF]);

                if (FRI <= FDI) {
                    if (FRF <= FDI) {
                        return {
                            available: true,
                            message: "Se puede reservar",
                        }; // case 1
                    } else {
                        if (FRF >= FDF) {
                            return {
                                available: true,
                                message: "Se puede reservar",
                            }; // case 4
                        } else {
                            if (
                                allowReservation(
                                    array_dias_reserva,
                                    array_dias_disponibilidad,
                                    numero_noches
                                )
                            ) {
                                return {
                                    available: true,
                                    message: "Se puede reservar",
                                };
                            } else {
                                return {
                                    available: false,
                                    message: `Es necesario reservar minimamente ${numero_noches} noches entre el día ${FDI.toLocaleDateString(
                                        "es-ES",
                                        options
                                    )} y el día ${FDF.toLocaleDateString(
                                        "es-ES",
                                        options
                                    )}`,
                                }; // case 2
                            }
                        }
                    }
                } else {
                    if (FRI > FDF) {
                        return {
                            available: true,
                            message: "Se puede reservar",
                        }; // case 5
                    } else {
                        if (
                            allowReservation(
                                array_dias_reserva,
                                array_dias_disponibilidad,
                                numero_noches
                            )
                        ) {
                            return {
                                available: true,
                                message: "Se puede reservar",
                            };
                        } else {
                            return {
                                available: false,
                                message: `Es necesario reservar minimamente ${numero_noches} noches entre el día ${FDI.toLocaleDateString(
                                    "es-ES",
                                    options
                                )} y el día ${FDF.toLocaleDateString(
                                    "es-ES",
                                    options
                                )}`,
                            }; // case 3
                        }
                    }
                }
            }
        );
        setOpenCalendar(false);
        if (
            allDatesSelected.some(
                (date) =>
                    arrayFechas.includes(
                        formatedDate_YYYY_MM_DD(new Date(date))
                    ) ||
                    arrayPedidos.includes(
                        formatedDate_YYYY_MM_DD(new Date(date))
                    )
            ) ||
            allDaysSelected.some((day) => arrayDias.includes(day)) ||
            allMonthssSelected.some((month) => arrayMeses.includes(month))
        ) {
            setCantDays(0);
            setSelectedDays([]);
            setErrorDates("No puede elegir días no disponibles");
        } else {
            if (isAvailable.some(({ available }) => available === false)) {
                setCantDays(0);
                setSelectedDays([]);
                const { message } = isAvailable.find(
                    ({ available }) => available === false
                );
                setErrorDates(message);
            } else if (
                totalDays <
                (currentProduct.tipo_estadia === "dia"
                    ? currentProduct.alquiler_minimo
                    : currentProduct.alquiler_minimo + 1)
            ) {
                let match = false;
                const [FRI, FRF] = dates;
                currentProduct?.minima_disponibilidad.forEach(
                    ({ fecha_inicio, fecha_final, numero_noches }) => {
                        const FDI = new Date(fecha_inicio + "T00:00:00");
                        const FDF = new Date(fecha_final + "T00:00:00");
                        if (
                            (FDI <= FRI && FDF >= FRI) ||
                            (FDI <= FRF && FDF >= FRF) ||
                            (FRI <= FDI && FRF >= FDI) ||
                            (FRI <= FDF && FRF >= FDF)
                        ) {
                            if (totalDays >= numero_noches) {
                                match = true;
                            }
                        }
                    }
                );
                if (match) {
                    setErrorDates(null);
                    setCantDays(totalDays);
                    setSelectedDays([dateOne, dateTwo]);
                } else {
                    setCantDays(0);
                    setSelectedDays([]);
                    setErrorDates(`Seleccione un mínimo de
                    ${
                        currentProduct.alquiler_minimo +
                        (currentProduct.tipo_estadia === "dia" ? 0 : 1)
                    }
                    dias`);
                }
            } else {
                setErrorDates(null);
                setCantDays(totalDays);
                setSelectedDays([dateOne, dateTwo]);

                setInput({
                    ...input,
                    fecha_inicio: formatedDate_YYYY_MM_DD(new Date(dateOne)),
                    fecha_final: formatedDate_YYYY_MM_DD(new Date(dateTwo)),
                });
            }
        }
    };
    useEffect(() => {
        if (fechas) {
            let filtrosSplit = fechas?.split("&");
            let objeto = {
                fecha_inicio: filtrosSplit[0].split("=")[1],
                fecha_final: filtrosSplit[1].split("=")[1],
            };

            const startDate = new Date(`${objeto.fecha_inicio}T00:00:00`);
            const endDate = new Date(`${objeto.fecha_final}T00:00:00`);
            onChangeCalendar([startDate, endDate]);
        }
    }, [fechas]);
    useEffect(() => {
        getProductDetails();
    }, []);

    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpenCalendar(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const calculateDisabledTiles = (date) => {
        if (arrayFechas?.length > 0) {
            const block = arrayFechas?.some((fecha) => {
                return (
                    date.getTime() ===
                        new Date(fecha + "T00:00:00").getTime() ||
                    arrayDias.some((day) => day === date.getDay()) ||
                    arrayMeses.some((month) => date.getMonth() === month)
                );
            });
            if (block) return block;
        }
        if (arrayPedidos.length > 0) {
            const block = arrayPedidos?.some((fecha) => {
                return (
                    date.getTime() ===
                        new Date(fecha + "T00:00:00").getTime() ||
                    arrayDias.some((day) => day === date.getDay()) ||
                    arrayMeses.some((month) => date.getMonth() === month)
                );
            });
            if (block) return block;
        }
        if (arrayDias.length > 0) {
            return (
                arrayDias.some((day) => date.getDay() === day) ||
                arrayMeses.some((month) => date.getMonth() === month)
            );
        }
        return arrayMeses.some((month) => date.getMonth() === month);
    };

    const getEveryDayBetween2Dates = (arrayFechas) => {
        const [startDate, endDate] = arrayFechas.map(
            (fecha) => new Date(fecha)
        );
        const date = new Date(startDate.getTime());
        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="h-full">
            <div className="h-full">
                <div className="h-full py-4 bg-white rounded-lg select-none">
                    <p className="text-2xl font-bold text-center text-[#0F80F2]">
                        ¡Reserva tu lugar ahora!
                    </p>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-8 ">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-[#053465] font-bold">
                                Nombre completo
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="nombre"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        nombre: e.target.value,
                                    })
                                }
                                value={input.nombre}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg  disabled:bg-slate-50  placeholder:text-[#053465] text-[#053465] bg-[#ECECEC]"
                                placeholder="Nombre"
                            />
                        </div>
                    </div>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-3 ">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-[#053465] font-bold">
                                Celular
                            </label>
                            <input
                                autoComplete="off"
                                type="number"
                                name="personas"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        celular: e.target.value,
                                    })
                                }
                                value={input.celular}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg  disabled:bg-slate-50  placeholder:text-[#053465] text-[#053465] bg-[#ECECEC]"
                                placeholder="Celular"
                            />
                        </div>
                    </div>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-3 ">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-[#053465] font-bold">
                                Correo
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="correo"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        correo: e.target.value,
                                    })
                                }
                                value={input.correo}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg  disabled:bg-slate-50  placeholder:text-[#053465] text-[#053465] bg-[#ECECEC]"
                                placeholder="Correo"
                            />
                        </div>
                    </div>

                    {/*   <div
                        ref={ref}
                        className="relative grid w-10/12 gap-2 mx-auto mt-3 xs:grid-cols-1 text-[#053465]"
                    >
                        <div className=" text-sm text-left text-[#053465] font-bold">
                            Fechas de reserva
                        </div>
                        <div
                            onClick={() => setOpenCalendar(!openCalendar)}
                            className="flex items-center justify-between p-2 border border-gray-300 rounded-md cursor-pointer bg-[#ECECEC]"
                        >
                            <div className="flex flex-col">
                                <div className="text-sm">
                                    {input.fecha_inicio ===
                                        "Fecha no seleccionada" &&
                                    input.fecha_final ===
                                        "Fecha no seleccionada" ? (
                                        "Fechas no seleccionadas"
                                    ) : (
                                        <div className="flex flex-col items-center justify-start gap-2">
                                            <div>
                                                <div className="font-bold ">
                                                    Fecha Inicio:{" "}
                                                </div>{" "}
                                                <div>
                                                    {formatDate(
                                                        input.fecha_inicio
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="font-bold ">
                                                    Fecha Final:{" "}
                                                </div>{" "}
                                                <div>
                                                    {formatDate(
                                                        input.fecha_final
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {!openCalendar ? (
                                <svg
                                    class="h-8 w-8 text-[#053465] "
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <polyline points="6 9 12 15 18 9" />
                                </svg>
                            ) : (
                                <svg
                                    class="h-8 w-8 text-[#053465]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <polyline points="6 15 12 9 18 15" />
                                </svg>
                            )}
                        </div>

                        {openCalendar && (
                            <div className="absolute z-20 w-full bg-white border-2 rounded-md border-[#053465] p-1.5">
                                <Calendar
                                    selectRange={true}
                                    onChange={onChangeCalendar}
                                    minDate={new Date()}
                                    tileDisabled={({
                                        activeStartDate,
                                        date,
                                        view,
                                    }) => calculateDisabledTiles(date)}
                                    value={selectedRange}
                                />
                            </div>
                        )}
                    </div> */}

                    {/*      {cantDays === 0 && errorDates ? (
                        cantDays < currentProduct?.alquiler_minimo + 1 &&
                        errorDates ? (
                            <div className="p-1 mt-2 text-lg text-center text-red-400 bg-white rounded-lg">
                                {errorDates || (
                                    <div>
                                        Seleccione un mínimo de{" "}
                                        {currentProduct?.alquiler_minimo +
                                            (currentProduct.tipo_estadia ===
                                            "dia"
                                                ? 0
                                                : 1)}
                                        {" dias"}
                                    </div>
                                )}
                            </div>
                        ) : (
                            currentProduct.alquiler_minimo && (
                                <div className="p-4 mt-5 text-lg text-center text-red-400 bg-white rounded-lg">
                                    <div>
                                        Seleccione un mínimo de{" "}
                                        {currentProduct?.alquiler_minimo +
                                            (currentProduct.tipo_estadia ===
                                            "dia"
                                                ? 0
                                                : 1)}
                                        {" dias"}
                                    </div>
                                </div>
                            )
                        )
                    ) : currentProduct.alquiler_maximo > 0 &&
                      cantDays >
                          (currentProduct.tipo_estadia === "dia"
                              ? currentProduct.alquiler_maximo
                              : currentProduct.alquiler_maximo + 1) ? (
                        <div className="p-4 mt-5 text-lg text-center text-red-400 bg-white rounded-lg">
                            El alquiler máximo es de{" "}
                            {currentProduct.tipo_estadia === "dia"
                                ? currentProduct.alquiler_maximo
                                : currentProduct.alquiler_maximo + 1}
                            {" dias"}
                        </div>
                    ) : null} */}
                    <div className=" flex justify-center items-center w-full flex-col gap-4 mx-auto mt-5">
                        <button
                            disabled={cargando}
                            onClick={() => enviar()}
                            className={`mx-2 text-center w-11/12`}
                        >
                            <p
                                className={` font-semibold bg-blue-500 rounded-lg min-w-max p-2 text-white  hover:bg-blue-600 mx-auto disabled:bg-blue-300 w-11/12`}
                            >
                                {cargando
                                    ? "Enviando..."
                                    : "¡Quiero Reservar Ahora!"}
                            </p>
                        </button>
                        <div className=" text-red-500 text-sm">
                            ⚠ No válido para otras fechas festivas ni feriados
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
