import React from "react";
import { useSelector } from "react-redux";
import BackgroundImage from "../../img/corporativo/eventosCorporativos.png";
import experienciasInolvidables from "../../img/corporativo/experiencasInolvidables2.png";
import activacionesMarca from "../../img/corporativo/activacionesMarca.png";
import integraciones from "../../img/corporativo/Integraciones.png";
import coachingEmpresarial from "../../img/corporativo/coachingEmpresarial.png";
import Footer from "../Footer";
import NavBar from "../NavBar";
import useScrollTo from "../../hooks/useScrollToTop";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import cliente1 from "../../img/corporativo/cliente1.png";
import cliente2 from "../../img/corporativo/cliente2.png";
import cliente3 from "../../img/corporativo/cliente3.png";
import cliente4 from "../../img/corporativo/cliente4.png";
import cliente5 from "../../img/corporativo/cliente5.png";
import cliente6 from "../../img/corporativo/cliente6.png";
import sitemap from "../../assets/my-sitemap.xml";
import NavBarHome from "../NavBarHome";
const EventosCorporativos = () => {
    const authUser = useSelector((state) => state.authReducer);
    useScrollTo();

    const testimonials = [
        {
            id: 0,
            text: "“Nuestra experiencia con Blue Corner no sólo fue una experiencia distinta y única, sino también muy enriquecedora ya que nos permitió acercar más al equipo regional. Somos una empresa que se encuentra en 3 países distintos, con culturas distintas. Físicamente no podemos estar juntos siempre y este espacio fue sumamente positivo para acortar esas brechas geográficas, acompañadas de dinámicas que fortalecen más aún al equipo y generan cercanía. Creo que fue todo un reto para nosotros salir del día a día, desconectarnos un momento para conectar entre nosotros y obtener grandes resultados a nivel laboral y personal. ¡Gracias por la experiencia, Bue Corner!”",
            clientName: "Brenda Nacarino",
            clientType:
                "Jefe Regional de Cultura y Comunicación de Ingeniería y Construcción en Graña y Montero (GyM)",
        },
        {
            id: 1,
            text: "“Somos clientes de Blue Corner en lo que es organización de eventos y Team Building. Lo único que les puedo decir es que me siento totalmente satisfecho de trabajar con Blue Corner, que bueno que haya una empresa profesional que dé el servicio de arrendamiento de embarcaciones en Perú y altamente recomendable en el servicio, muchas gracias”",
            clientName: "Enzo Montalbetti",
            clientType: "Gerente General en Yellow SAC",
        },
        {
            id: 2,
            text: "“En el verano hicimos un compartir, una actividad con el equipo de la oficina, y la verdad es que es la primera que hacemos un full day en un Velero. Les debo decir que es una experiencia única, todo el personal quedó bastante feliz y contento. Las instalaciones bastante limpias, el personal muy atento, bastante flexibles con el cambio de ruta, pudimos ver delfines de muy cerca y la parte del catering, también, espectacular. Si desean pasar un momento relajante, divertirse, hacer un compartir entre amigos, alguna actividad de la empresa o en familia se los recomiendo, estoy segura que no se van a arrepentir”",
            clientName: "Estela Salas",
            clientType: "Jefe de Logística en Lledó Comunicaciones",
        },
    ];

    const ButtonCita = () => (
        <button className="p-2 text-white rounded-md cursor-pointer bg-blue-bluecorner w-44">
            Agenda una cita
        </button>
    );

    return (
        <div>
            <NavBarHome explorer={false} />
            <div>
                <div
                    style={{
                        backgroundImage: `url(${BackgroundImage})`,
                        filter: "brightness( 100% ) contrast( 100% ) saturate( 80% ) blur( 0px ) hue-rotate( 0deg )",
                    }}
                    className={`w-full relative bg-[#053465] h-96 bg-center bg-no-repeat bg-cover flex flex-col overflow-hidden`}
                >
                    <div className="text-white absolute -bottom-6 right-0 w-40 h-36 pt-2 pb-4 flex items-center justify-center text-center rounded-full bg-[#053465]">
                        <h1 className="text-2xl font-semibold ">
                            Celebra y disfruta con BlueCorner
                        </h1>
                    </div>
                </div>
            </div>
            <div className="flex flex-col p-5 text-center md:px-[10%]">
                <h2 className="text-[#053465] font-bold text-2xl">
                    Eventos corporativos
                </h2>
                <p className="p-4 text-center">
                    Ya sea que estés buscando nuevas opciones para acciones de
                    marketing o propuestas innovadoras para tu equipo de
                    colaboradores, Blue Corner garantiza que tendrán una
                    experiencia única e inolvidable que los conectarán
                    profundamente con tu marca o empresa.
                </p>
            </div>
            <div className="bg-[#053465] p-4">
                <div className="p-4">
                    <h2 className="text-2xl font-bold text-center text-white">
                        ¿Por qué hacer tu evento con Bluecorner?
                    </h2>
                </div>
                <div className="grid sm:grid-cols-5 justify-items-center">
                    <div className="m-5">
                        <div className="border-2 p-1 w-10 h-10 mx-auto border-[#307fe2] rounded-full group hover:bg-[#307fe2] hover:border-[#053465] duration-150">
                            <svg
                                className="h-8 w-8 text-[#307fe2]  group-hover:text-[#053465] pr-1 pb-1"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
                                />
                            </svg>
                        </div>
                        <p className="mt-2 text-base font-bold text-center text-white">
                            SOCIOS ESTRATÉGICOS{" "}
                        </p>
                        <p className="mt-1 text-sm text-center text-white">
                            Desde catering y clases de cocina hasta coaches
                            certificados. Trabajamos con los mejores de cada
                            industria.
                        </p>
                    </div>
                    <div className="m-5">
                        <div className="border-2 p-1 w-10 h-10 mx-auto border-[#307fe2] rounded-full group hover:bg-[#307fe2] hover:border-[#053465] duration-150">
                            <svg
                                className="h-8 w-8 text-[#307fe2]  group-hover:text-[#053465] pr-1 pb-1"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M7 12l5 5l10 -10" />{" "}
                                <path d="M2 12l5 5m5 -5l5 -5" />
                            </svg>
                        </div>
                        <p className="mt-2 text-base font-bold text-center text-white">
                            REALIZAMOS EVENTOS A MEDIDA
                        </p>
                        <p className="mt-1 text-sm text-center text-white">
                            Planeamientos estratégicos, integraciones de
                            equipos, sesiones de team-building/coaching o
                            eventos para tus clientes. Un programa diseñado para
                            tu empresa.
                        </p>
                    </div>
                    <div className="m-5">
                        <div className="border-2 p-1 w-10 h-10 mx-auto border-[#307fe2] rounded-full group hover:bg-[#307fe2] hover:border-[#053465] duration-150">
                            <svg
                                className="h-8 w-8 text-[#307fe2]  group-hover:text-[#053465] pr-1 pb-1"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path d="M13.73 21a2 2 0 0 1-3.46 0" />{" "}
                                <path d="M18.63 13A17.89 17.89 0 0 1 18 8" />{" "}
                                <path d="M6.26 6.26A5.86 5.86 0 0 0 6 8c0 7-3 9-3 9h14" />{" "}
                                <path d="M18 8a6 6 0 0 0-9.33-5" />{" "}
                                <line x1="1" y1="1" x2="23" y2="23" />
                            </svg>
                        </div>
                        <p className="mt-2 text-base font-bold text-center text-white">
                            CONCENTRACIÓN SIN DISRUPCIÓN
                        </p>
                        <p className="mt-1 text-sm text-center text-white">
                            Porque para desconectarse hay que salir de la
                            oficina. Ofrecemos eventos en La Punta, Sur Chico,
                            Paracas y Máncora.
                        </p>
                    </div>
                    <div className="m-5">
                        <div className="border-2 p-1 w-10 h-10 mx-auto border-[#307fe2] rounded-full group hover:bg-[#307fe2] hover:border-[#053465] duration-150">
                            <svg
                                className="h-8 w-8 text-[#307fe2]  group-hover:text-[#053465] pr-1 pb-1"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <circle cx="12" cy="12" r="9" />{" "}
                                <line x1="9" y1="9" x2="9.01" y2="9" />{" "}
                                <line x1="15" y1="9" x2="15.01" y2="9" />{" "}
                                <path d="M8 13a4 4 0 1 0 8 0m0 0H8" />
                            </svg>
                        </div>
                        <p className="mt-2 text-base font-bold text-center text-white">
                            DETALLES QUE HACEN LA DIFERENCIA{" "}
                            {/* <a href={sitemap}>aqui</a> */}
                        </p>
                        <p className="mt-1 text-sm text-center text-white">
                            Desde polos con el logo de tu empresa hasta
                            recuerdos personalizados o merchandising
                            corporativo. Creamos experiencias inolvidables para
                            tu equipo y/o tus clientes.
                        </p>
                    </div>
                    <div className="m-5">
                        <div className="border-2 p-1 w-10 h-10 mx-auto border-[#307fe2] rounded-full group hover:bg-[#307fe2] hover:border-[#053465] duration-150">
                            <svg
                                className="h-8 w-8 text-[#307fe2]  group-hover:text-[#053465] pr-1 pb-1"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <circle cx="6" cy="6" r="2" />{" "}
                                <circle cx="18" cy="6" r="2" />{" "}
                                <circle cx="6" cy="18" r="2" />{" "}
                                <circle cx="18" cy="18" r="2" />{" "}
                                <line x1="6" y1="8" x2="6" y2="16" />{" "}
                                <line x1="8" y1="6" x2="16" y2="6" />{" "}
                                <line x1="8" y1="18" x2="16" y2="18" />{" "}
                                <line x1="18" y1="8" x2="18" y2="16" />
                            </svg>
                        </div>
                        <p className="mt-2 text-base font-bold text-center text-white">
                            INTEGRACIONES DE EQUIPO
                        </p>
                        <p className="mt-1 text-sm text-center text-white">
                            Olvídate de lidiar con diferentes proveedores. Un
                            solo proveedor responsable de toda la gestión de tus
                            eventos.
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col p-5 text-center gap-6 md:px-[10%]">
                <div className="flex flex-col items-center grid-cols-2 gap-3 p-4 border-4 rounded-md shadow-md md:grid xl:gap-10">
                    <img
                        className="object-cover w-full rounded-md"
                        src={coachingEmpresarial}
                        alt="Coaching empresarial"
                    />
                    <div className="flex flex-col gap-3 text-left">
                        <span className="text-2xl font-semibold md:text-3xl">
                            Coaching empresarial
                        </span>
                        <p>
                            Capacitaciones y charlas magistrales personalizadas
                            para ajustarse a las necesidades de cada empresa y
                            promover el trabajo en equipo.
                        </p>
                        <p>
                            Sesiones alejadas del ruido de la ciudad y
                            distracciones para tener mayor concentración y una
                            experiencia única.
                        </p>
                        <a
                            className=""
                            href={`https://web.whatsapp.com/send?text=Hola BlueCorner, vengo de la página de eventos corporativos y quiero saber más sobre "Coaching empresarial" y quiero reservar una cita para el evento, gracias!&phone=51941110270`}
                            target="_blank"
                        >
                            <div className="flex justify-center mt-5 md:justify-start">
                                <ButtonCita />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="flex flex-col items-center grid-cols-2 gap-3 p-4 border-4 rounded-md shadow-md md:grid xl:gap-10">
                    <img
                        className="object-cover w-full rounded-md"
                        src={activacionesMarca}
                        alt="Activaciones de marca"
                    />
                    <div className="flex flex-col gap-3 text-left">
                        <span className="text-2xl font-semibold md:text-3xl">
                            Activaciones de marca
                        </span>
                        <p>
                            Promociona tu marca a través de eventos de marketing
                            para clientes internos o externos.
                        </p>
                        <p>
                            Un solo proveedor responsable de toda la gestión del
                            evento.
                        </p>
                        <p>
                            Activaciones en embarcaciones, casas de campo o
                            playa.
                        </p>
                        <a
                            className=""
                            href={`https://web.whatsapp.com/send?text=Hola BlueCorner, vengo de la página de eventos corporativos y quiero saber más sobre "Activaciones de marca" y quiero reservar una cita para el evento, gracias!&phone=51941110270`}
                            target="_blank"
                        >
                            <div className="flex justify-center mt-5 md:justify-start">
                                <ButtonCita />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="flex flex-col items-center grid-cols-2 gap-3 p-4 border-4 rounded-md shadow-md md:grid xl:gap-10">
                    <img
                        className="object-cover w-full rounded-md"
                        src={integraciones}
                        alt="Coaching empresarial"
                    />
                    <div className="flex flex-col gap-3 text-left">
                        <span className="text-2xl font-semibold md:text-3xl">
                            Integraciones
                        </span>
                        <p>
                            Ofrecemos experiencias inolvidables con un equipo de
                            coaches profesionales y responsables que se
                            encargaran de organizar actividades para promover la
                            integración y el trabajo en equipo, garantizando una
                            experiencia única y divertida.
                        </p>
                        <a
                            className=""
                            href={`https://web.whatsapp.com/send?text=Hola BlueCorner, vengo de la página de eventos corporativos y quiero saber más sobre "Integraciones" y quiero reservar una cita para el evento, gracias!&phone=51941110270`}
                            target="_blank"
                        >
                            <div className="flex justify-center mt-5 md:justify-start">
                                <ButtonCita />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="mt-10 bg-blue-bluecorner w-full text-white p-10 md:px-[10%]">
                <h2 className="text-xl font-semibold text-center xl:text-3xl">
                    Experiencias inolvidables con los mejores socios
                    estratégicos
                </h2>
                <div className="flex flex-col gap-3 mt-8 md:flex-row lg:px-20">
                    <div className="flex flex-col items-center justify-center grid-cols-2 gap-3 md:grid">
                        <div className="flex flex-col w-full text-center lg:px-10">
                            <div className="flex flex-wrap items-center gap-2 my-3 md:gap-10 justify-evenly">
                                <div className="flex flex-col w-32 text-center">
                                    <span className="text-2xl font-bold lg:text-4xl xl:text-6xl">
                                        +110
                                    </span>
                                    <span>Casas de campo</span>
                                </div>
                                <div className="flex flex-col w-32 text-center">
                                    <span className="text-2xl font-bold lg:text-4xl xl:text-6xl">
                                        +120
                                    </span>
                                    <span>Casas de playa</span>
                                </div>
                                <div className="flex flex-col w-32 text-center">
                                    <span className="text-2xl font-bold lg:text-4xl xl:text-6xl">
                                        +85
                                    </span>
                                    <span>Embarcaciones</span>
                                </div>
                                <div className="flex flex-col w-32 text-center">
                                    <span className="text-2xl font-bold lg:text-4xl xl:text-6xl">
                                        +65
                                    </span>
                                    <span>Destinos</span>
                                </div>
                                <div className="flex flex-col w-32 text-center">
                                    <span className="text-2xl font-bold lg:text-4xl xl:text-6xl">
                                        3
                                    </span>
                                    <span>Países</span>
                                </div>
                            </div>
                            <p className="text-sm md:mt-10 xl:text-lg">
                                y muchos socios para personalizar y mejorar tus
                                eventos.
                            </p>
                        </div>
                        <img
                            className="object-cover w-full h-full"
                            src={experienciasInolvidables}
                            alt="Experiencias inolvidables"
                        />
                    </div>
                </div>
            </div>
            <div className="font-bold text-[#053465] text-3xl text-center bg-white py-10">
                Algunos de nuestros clientes
            </div>
            <div className="grid grid-cols-2 pb-10 bg-white sm:grid-cols-6 ">
                <img
                    className="p-6 mx-auto mb-2 align-middle grayscale"
                    src={cliente1}
                    alt="cliente1"
                />
                <img
                    className="p-6 mx-auto mb-2 align-middle grayscale"
                    src={cliente2}
                    alt="cliente2"
                />
                <img
                    className="p-6 mx-auto mb-2 align-middle grayscale"
                    src={cliente3}
                    alt="cliente3"
                />
                <img
                    className="p-4 mx-auto mb-2 align-middle grayscale"
                    src={cliente4}
                    alt="cliente4"
                />
                <img
                    className="p-6 mx-auto mb-2 align-middle grayscale"
                    src={cliente5}
                    alt="cliente5"
                />
                <img
                    className="p-6 mx-auto mb-2 align-middle grayscale"
                    src={cliente6}
                    alt="cliente6"
                />
            </div>
            <div className="bg-white">
                <div className="w-4/5 mx-auto bg-white">
                    <div>
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay]}
                            spaceBetween={20}
                            slidesPerView={1}
                            rewind={true}
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                        >
                            {testimonials.map((testimonial) => (
                                <SwiperSlide
                                    key={testimonial.id}
                                    className="flex flex-col p-8 bg-white rounded-xl h-3/5 min-h-[180px] mb-5 text-center"
                                >
                                    <p className="flex text-[#053465] text-base">{`${testimonial.text}`}</p>
                                    <span className="mt-5 font-bold text-[#053465] text-xl">
                                        {testimonial.clientName}
                                    </span>
                                    <span className="text-sm text-[#307FE2]">
                                        {testimonial.clientType}
                                    </span>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default EventosCorporativos;
