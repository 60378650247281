import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import paramsURLhouses from "../../alquilerScreen/alquilerAlojamiento/paramsURLhouses";
import { updateHouseFilters } from "../../../redux/actions/filtersActions";
import getTotalReservaInicial from "../../detailedScreens/getTotalReservaInicial";
import numberToCommas from "../../helpers/numberToCommas";
import { normalizeString } from "../../helpers/normalizeString";
import getTotalReservaFinal from "../../detailedScreens/getTotalReservaFinal";
import { getPreciosAdicionales } from "../../alquilerScreen/alquilerAlojamiento/getPreciosAdicionales";
import { getGarantiaTotal } from "../../alquilerScreen/alquilerAlojamiento/getGarantiaTotal";
import { calcularDescuentoPrecioFinal } from "../../alquilerScreen/alquilerAlojamiento/calcularDescuentoPrecioFinal";

const MapAlojamientos = ({
    allProducts,
    selectedMarker,
    setSelectedMarker,
    mapShouldFly,
    setMapShouldFly,
    mapRef,
    cantDays,
    selectedDays,
    personas,
}) => {
    const ubication = {
        lat: -12.050653,
        lng: -76.8467069,
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { house_filters } = useSelector((state) => state.filtersReducer);
    const [coordinates, setCoordinates] = useState({
        lat: Number(selectedMarker?.latitud) || ubication.lat,
        lng: Number(selectedMarker?.longitud) || ubication.lng,
        zoom: 10,
    });
    const filters = useSelector((state) => state.filtersReducer);

    const authUser = useSelector((state) => state.authReducer);
    const moneda_elegida = localStorage.getItem("moneda");

    useEffect(() => {
        const listener = (e) => {
            if (e.key === "Escape") setSelectedMarker(null);
        };
        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, []);
    console.log("allProducts", allProducts);
    useEffect(() => {
        if (
            selectedMarker &&
            mapShouldFly &&
            mapRef.current &&
            house_filters.query_value !== "Área del mapa"
        ) {
            mapRef.current.panTo({
                lat: Number(selectedMarker.latitud),
                lng: Number(selectedMarker.longitud),
            });
        }
    }, [selectedMarker, allProducts]);

    const onMouseOver = (e, product) => {
        e.preventDefault();
        setMapShouldFly(false);
        setSelectedMarker(product);

        window.open(
            `/alojamientos/${selectedMarker.id}/${normalizeString(
                selectedMarker.nombre
            )}/${
                filters.house_filters.fecha_inicio !== "" &&
                filters.house_filters.fecha_inicio !== null &&
                filters.house_filters.fecha_final !== "" &&
                filters.house_filters.fecha_final !== null
                    ? `fecha_inicio=${filters.house_filters.fecha_inicio}&fecha_final=${filters.house_filters.fecha_final}`
                    : ""
            }`,
            "_blank"
        );
    };

    const params = (filtros) => {
        let url = "";

        url = paramsURLhouses(filtros);

        return url;
    };

    const handleDragEnd = (event) => {
        setMapShouldFly(false);
        setSelectedMarker(null);
        const newCenter = mapRef.current.getCenter();
    };

    const menos10Porciento = (valor, product) => {
        let nuevoValor = valor - 0.1 * valor;
        let valorFinal;
        (authUser.tipo_usuario === 6 || authUser.tipo_usuario === 7) &&
        product?.alianza_empresa === "No"
            ? (valorFinal = Math.ceil(nuevoValor))
            : (valorFinal = Math.ceil(valor));

        return valorFinal;
    };
    const calcularDescuento = (monto, porcentaje) => {
        if (!porcentaje) {
            return 0;
        } else {
            let montoSinIGV = monto;
            let descuento = (Number(montoSinIGV) * Number(porcentaje)) / 100;

            return Math.ceil(monto - descuento);
        }
    };

    return (
        <div className="h-full">
            <div className="flex justify-center w-full h-full rounded-lg md:rounded-none">
                <GoogleMap
                    options={{ disableDefaultUI: true }}
                    mapContainerStyle={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                    }}
                    center={coordinates}
                    zoom={coordinates.zoom}
                    onDragEnd={handleDragEnd}
                    onLoad={(map) => (mapRef.current = map)}
                >
                    {allProducts?.data?.length > 0 &&
                        allProducts?.data?.map((product) => {
                            const latitud = parseFloat(product.latitud);
                            const longitud = parseFloat(product.longitud);
                            return (
                                <OverlayView
                                    key={product.id}
                                    position={{
                                        lat: latitud,
                                        lng: longitud,
                                    }}
                                    mapPaneName={
                                        OverlayView.OVERLAY_MOUSE_TARGET
                                    }
                                >
                                    <div
                                        onClick={(e) => onMouseOver(e, product)}
                                        className={`${
                                            selectedMarker?.id === product?.id
                                                ? "bg-black text-white scale-110"
                                                : "bg-white"
                                        } flex justify-center items-center p-1 px-2 rounded-xl border border-black cursor-pointer w-16 h-8`}
                                    >
                                        {selectedDays.length > 0 ? (
                                            <span className="font-semibold">
                                                {moneda_elegida === "PEN"
                                                    ? "S/"
                                                    : "US$"}{" "}
                                                {numberToCommas(
                                                    getTotalReservaFinal(
                                                        cantDays + 1,
                                                        selectedDays,
                                                        {
                                                            ...product,
                                                            huespedes: personas,
                                                        },
                                                        getPreciosAdicionales({
                                                            ...product,
                                                            huespedes: personas,
                                                        }),
                                                        authUser.tipo_usuario ===
                                                            6 ||
                                                            authUser.tipo_usuario ===
                                                                7,
                                                        getGarantiaTotal,
                                                        authUser.membresia,
                                                        true
                                                    ) -
                                                        calcularDescuentoPrecioFinal(
                                                            getTotalReservaInicial(
                                                                cantDays + 1,
                                                                selectedDays,
                                                                {
                                                                    ...product,
                                                                    huespedes:
                                                                        personas,
                                                                }
                                                            ),
                                                            product.oferta_activada,
                                                            product.oferta
                                                        ) -
                                                        getTotalReservaInicial(
                                                            cantDays + 1,
                                                            selectedDays,
                                                            {
                                                                ...product,
                                                                huespedes:
                                                                    personas,
                                                            },
                                                            true
                                                        )
                                                )}
                                            </span>
                                        ) : (
                                            <span className="font-semibold text-[9px] ">
                                                {product.nombre}
                                            </span>
                                        )}
                                    </div>
                                </OverlayView>
                            );
                        })}
                </GoogleMap>
            </div>
        </div>
    );
};

export default MapAlojamientos;
