import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import dividirFechaDatePicker from "../../../helpers/dividirFechaDatePicker";
import { useSelector } from "react-redux";
import getFeeByDay from "../../../helpers/getFeeByDay";
import ToggleButton from "../../../ToggleButton";

const ModalCalendarBoats = ({
    fechas,
    valorDia,
    duracion,

    setModal,
    onboarding,
    fromDisponibilidad = false,
    setActualizar,
    actualizar,
}) => {
    registerLocale("es", es);
    const allDates = {
        months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ],
        days: [
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
            "Domingo",
        ],
    };

    const [input, setInput] = useState({
        tipoVariante: "Rango de Fechas",
        fechaHasta: fechas,
        fechaDesde: fechas,
        mes: "",
        dia: "",
        costo: valorDia,
    });
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);
    const [caragando, setCargando] = useState(false);

    const [toggleButton, setToggleButton] = useState(true);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleClose = () => {
        setInput({
            name: "",
            description: "",
            price: 0,
            coin: "",
        });
        setModal(false);
    };

    const handleSave = () => {
        const payload = {
            id_embarcacion: onboarding.id,
            id_productos: onboarding.id,
            id_duracion: duracion.id_duracion,
            tipo_variante: input.tipoVariante,
            fecha_inicio: input.fechaDesde,
            fecha_final: input.fechaHasta,
            mes: input.mes,
            dia: input.dia,
            costo: input.costo,
            id_usuario: authUser.id_usuario,
        };
        updateProduct(payload);
    };
    const updateProduct = async (payload) => {
        if (input.costo === "") {
            Swal.fire({
                title: "Error",
                text: "Debe ingresar un precio",
                icon: "error",
                confirmButtonText: "Ok",
            });
        } else {
            setCargando(true);
            let json = await axios.post(
                `${process.env.REACT_APP_PROXY}${
                    onboarding.tipo_producto === "embarcacion"
                        ? "/embarcacionPreciosVariables"
                        : "/productoPreciosVariables"
                }`,
                payload,
                { headers }
            );
            fromDisponibilidad && (await ocultarHabilitarDias());
            if (
                json.data.message === "Registro Exitoso" ||
                json.data.message === "registro exitoso"
            ) {
                Swal.fire({
                    title: "Creada",
                    text: "Variacion creada correctamente",
                    icon: "success",
                    confirmButtonText: "Ok",
                });

                setModal(false);
            }
            setCargando(false);
        }
    };

    const ocultarHabilitarDias = async () => {
        if (toggleButton) {
            try {
                let json = await axios.put(
                    `${process.env.REACT_APP_PROXY}/productoDisponiblidad/enableFechas`,
                    {
                        id_producto: onboarding.id,
                        fecha_inicio: input.fechaDesde,
                        fecha_final: input.fechaHasta,
                    },
                    { headers }
                );

                setActualizar(!actualizar);
            } catch (error) {
                console.log("error", error);
            }
        } else {
            let json = await axios.post(
                `${process.env.REACT_APP_PROXY}${"/productoDisponiblidad"}`,
                {
                    id_usuario: authUser.id_usuario,

                    id_producto: onboarding.id,
                    tipo_variante: "Rango de Fechas",
                    fecha_inicio: input.fechaDesde,
                    fecha_final: input.fechaHasta,
                    dia_inicio: "",
                    dia_final: "",
                    mes_inicio: "",
                    mes_final: "",
                },
                { headers }
            );
            setActualizar(!actualizar);
        }
    };

    useEffect(() => {
        const noDisponible = onboarding.disponibilidad.arrayFechas.find(
            (fecha) => fecha.substring(0, 10) === fechas
        );

        if (
            fromDisponibilidad &&
            onboarding.disponibilidad.arrayFechas.find(
                (fecha) => fecha.substring(0, 10) === fechas
            )
        ) {
            setToggleButton(false);
        }
    }, []);

    return (
        <div
            className={`relative flex items-center justify-center
            `}>
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 pt-24 2xl:container 2xl:mx-auto ">
                    <div className="relative flex flex-col items-center px-4 py-16 overflow-y-auto bg-white rounded-lg w-96 xs:w-[480px] dark:bg-white h-[85vh] 3xl:h-[60vh]">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>

                        <div className="w-full my-3 text-left">
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-base  font-bold ">
                                {onboarding.tipo_producto === "embarcacion"
                                    ? "Nuevo precio a " + duracion?.duracion
                                    : `Nuevo precio ${
                                          fromDisponibilidad
                                              ? `(${onboarding.nombre})`
                                              : ""
                                      }`}
                            </header>
                            <header className="mb-3 pl-2 text-[#3C3C3C]  text-sm  font-semibold ">
                                {onboarding.tipo_producto === "embarcacion"
                                    ? `Precio base: ${duracion?.precio_base} (${onboarding?.tipo_moneda})`
                                    : `Precio base: ${onboarding?.precio_base} (${onboarding.moneda_precio_base})`}
                            </header>
                        </div>
                        {fromDisponibilidad && (
                            <div className="flex items-center justify-end w-full gap-2 pr-5 font-medium">
                                <p className="text-sm">
                                    {toggleButton
                                        ? "Dia disponible"
                                        : "Dia no disponible"}
                                </p>
                                <ToggleButton
                                    isToggled={
                                        onboarding.disponibilidad.arrayFechas.find(
                                            (fecha) =>
                                                fecha.substring(0, 10) ===
                                                fechas
                                        ) === undefined
                                    }
                                    onChangeToggle={(e) => setToggleButton(e)}
                                />
                            </div>
                        )}
                        {fromDisponibilidad !== true && (
                            <>
                                <div className="w-full text-left ">
                                    <header className="mb-3 pl-2 text-[#3C3C3C]  text-base ">
                                        Tipo variante
                                    </header>
                                </div>

                                <div className="w-full px-4 rounded-lg border border-[#E9E9E9]">
                                    <select
                                        name="tipoVariante"
                                        className="w-full my-3 bg-transparent border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C]"
                                        value={input.tipoVariante}
                                        onChange={(e) => (
                                            handleInputChange,
                                            setInput({
                                                ...input,
                                                tipoVariante: e.target.value,

                                                mes: "",
                                                dia: "",
                                            })
                                        )}>
                                        <option>Rango de Fechas</option>
                                        <>
                                            <option>Por Mes</option>
                                            <option>Por Dia</option>
                                        </>
                                    </select>
                                </div>
                            </>
                        )}
                        {input.tipoVariante === "Rango de Fechas" && (
                            <div className="flex flex-col mt-1  text-[#3C3C3C] w-10/12">
                                <div className="flex flex-col ">
                                    <div className="">
                                        <label className="ml-1 text-base font-semibold ">
                                            Desde:
                                        </label>
                                        {/* <input
                                            value={input.fechaDesde}
                                            type="date"
                                            name="fechaDesde"
                                            className="p-1 rounded-lg"
                                            onChange={handleInputChange}
                                        /> */}
                                        <DatePicker
                                            className="w-9/12 p-2 bg-white border rounded-lg cursor-pointer"
                                            placeholderText="Seleccionar fecha"
                                            locale="es"
                                            autoComplete="off"
                                            name="fechaDesde"
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                input.fechaDesde !== ""
                                                    ? new Date(
                                                          dividirFechaDatePicker(
                                                              input.fechaDesde
                                                          )[0],
                                                          dividirFechaDatePicker(
                                                              input.fechaDesde
                                                          )[1] - 1,
                                                          dividirFechaDatePicker(
                                                              input.fechaDesde
                                                          )[2]
                                                      )
                                                    : input.fechaDesde
                                            }
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    fechaDesde: `${e.getFullYear()}-${`${
                                                        e.getMonth() + 1
                                                    }`.padStart(
                                                        2,
                                                        "0"
                                                    )}-${`${e.getDate()}`.padStart(
                                                        2,
                                                        "0"
                                                    )}`,
                                                })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="ml-1 text-base font-semibold ">
                                            Hasta:
                                        </label>
                                        {/* <input
                                            value={input.fechaHasta}
                                            type="date"
                                            name="fechaHasta"
                                            className="p-1 rounded-lg"
                                            onChange={handleInputChange}
                                        /> */}
                                        <DatePicker
                                            className="w-9/12 p-2 bg-white border rounded-lg cursor-pointer"
                                            placeholderText="Seleccionar fecha"
                                            locale="es"
                                            autoComplete="off"
                                            name="fechaHasta"
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                input.fechaHasta !== ""
                                                    ? new Date(
                                                          dividirFechaDatePicker(
                                                              input.fechaHasta
                                                          )[0],
                                                          dividirFechaDatePicker(
                                                              input.fechaHasta
                                                          )[1] - 1,
                                                          dividirFechaDatePicker(
                                                              input.fechaHasta
                                                          )[2]
                                                      )
                                                    : input.fechaHasta
                                            }
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    fechaHasta: `${e.getFullYear()}-${`${
                                                        e.getMonth() + 1
                                                    }`.padStart(
                                                        2,
                                                        "0"
                                                    )}-${`${e.getDate()}`.padStart(
                                                        2,
                                                        "0"
                                                    )}`,
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="mt-2 ">
                                    <div className="flex items-center ">
                                        <div className="mb-2 ">
                                            <label className="ml-1 mr-1 text-base font-semibold ">
                                                {onboarding.tipo_producto ===
                                                "embarcacion"
                                                    ? `Precio por dia (${onboarding?.tipo_moneda})`
                                                    : `Precio por dia (${onboarding.moneda_precio_base})`}
                                            </label>
                                            <input
                                                className="md:ml-3 p-2 pt-[9.5px] rounded-lg border border-[#E9E9E9] outline-none w-14 placeholder:text-center text-center"
                                                value={input.costo}
                                                placeholder="10"
                                                type="number"
                                                step=".01"
                                                name="costo"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {input.tipoVariante === "Por Mes" && (
                            <div className="flex flex-col mt-1 w-full text-[#3C3C3C]">
                                <div className="flex ">
                                    <div className="">
                                        <label className="mx-1 text-base font-semibold text-center ">
                                            Mes:
                                        </label>
                                        <select
                                            name="mes"
                                            value={input.mes}
                                            className="outline-none rounded-lg border border-[#E9E9E9] mr-2  text-base px-2 py-2 "
                                            onChange={handleInputChange}>
                                            <option value="">Seleccione</option>
                                            {allDates.months.map((month) => (
                                                <option key={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="inline-flex items-center mt-2">
                                    <div className="flex items-center ">
                                        <div className="mb-2 ">
                                            <label className="ml-1 mr-1 text-base font-semibold ">
                                                {onboarding.tipo_producto ===
                                                "embarcacion"
                                                    ? `Precio por dia (${onboarding?.tipo_moneda})`
                                                    : `Precio por dia (${onboarding.moneda_precio_base})`}
                                            </label>
                                            <input
                                                value={input.costo}
                                                className="md:ml-3 p-2 pt-[9.5px] rounded-lg border border-[#E9E9E9] outline-none w-14 placeholder:text-center text-center"
                                                placeholder="10"
                                                type="number"
                                                step=".01"
                                                name="costo"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {input.tipoVariante === "Por Dia" && (
                            <div className="flex flex-col mt-3 w-full text-[#3C3C3C]">
                                <div className="flex ">
                                    <div className=" -mt-1.5">
                                        <label className="mx-1 text-base font-semibold text-center">
                                            Dia:
                                        </label>

                                        <select
                                            name="dia"
                                            className="outline-none rounded-lg border border-[#E9E9E9] mr-2  text-base px-2 py-2 "
                                            onChange={handleInputChange}>
                                            <option value="">Seleccione</option>
                                            {allDates.days.map((month) => (
                                                <option key={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="inline-flex items-center mt-2">
                                    <div className="flex items-center ">
                                        <div className="mb-2 ">
                                            <label className="ml-1 mr-1 text-base font-semibold ">
                                                {onboarding.tipo_producto ===
                                                "embarcacion"
                                                    ? `Precio por dia (${onboarding?.tipo_moneda})`
                                                    : `Precio por dia (${onboarding.moneda_precio_base})`}
                                            </label>
                                            <input
                                                value={input.costo}
                                                className="md:ml-3 p-2 pt-[9.5px] rounded-lg border border-[#E9E9E9] outline-none w-14 placeholder:text-center text-center"
                                                placeholder="10"
                                                type="number"
                                                step=".01"
                                                name="costo"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <hr className="w-full h-1 my-2"></hr>

                        <button
                            disabled={caragando}
                            className={`w-full py-3 mt-4 text-center disabled:cursor-not-allowed text-white border border-blue-400 rounded-md bg-blue-bluecorner hover:bg-white hover:text-blue-bluecorner disabled:bg-blue-400`}
                            onClick={(e) => handleSave(e)}>
                            {caragando ? "Guardando.." : "Guardar"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCalendarBoats;
