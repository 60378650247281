export const calcularDescuentoPrecioFinal = (
    monto,
    oferta_activada,
    porcentaje,
    desde = false
) => {
    if (!porcentaje || oferta_activada === "No") {
        return 0;
    } else {
        let montoSinIGV = monto;
        let descuento = (Number(montoSinIGV) * Number(porcentaje)) / 100;

        return desde ? Math.ceil(monto - descuento) : Math.floor(descuento);
    }
};
