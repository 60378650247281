import React from "react";

import ImagenReserva from "../../../img/landing-casa-mario/imagen-reserva.png";

const Reservs = () => {
    return (
        <div className="w-4/5 mx-auto mb-10">
            <div className="flex flex-col items-center justify-center w-full h-full rounded-md overflow-hidden mt-10 relative">
                <img
                    src={ImagenReserva}
                    alt="reserva"
                    className="md:w-full md:h-full w-full h-[20rem] object-cover"
                />
                <div className=" absolute text-center w-full h-full bg-[#053465] opacity-30 "></div>
                <div className=" absolute text-center w-full h-full flex flex-col justify-between 2xl:py-14 py-10 items-center ">
                    <div className="2xl:text-5xl md:text-3xl text-xl text-white font-bold">
                        ¡No te quedes sin vivir esta experiencia única!
                    </div>

                    <div>
                        <div className="2xl:text-3xl md:text-xl text-base text-white mt-5">
                            Reserva hoy y aprovecha el
                        </div>

                        <div className="2xl:text-3xl md:text-xl text-base 2xl:mt-5 mt-3  text-white font-bold">
                            precio especial de S/ 4,100
                        </div>

                        <div className="flex justify-center items-center 2xl:text-xl md:text-base text-xs mt-3 text-white gap-2 ">
                            Disfruta{" "}
                            <div className="font-bold">3 días y 2 noches </div>
                        </div>
                    </div>

                    <div
                        onClick={() => {
                            document
                                .getElementById("formularioReserva")
                                ?.scrollIntoView({
                                    behavior: "smooth",
                                });
                        }}
                        className="flex justify-center items-center 2xl:text-xl md:text-lg text-sm font-bold mt-5 text-white gap-2 2xl:px-28 md:px-20 px-10 py-2 bg-[#4693F1] cursor-pointer rounded-md "
                    >
                        ¡Quiero Reservar Ahora!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reservs;
