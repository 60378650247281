import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import { useNavigate, useParams } from "react-router-dom";
import TipoPagoEmpresa from "./TipoPagoEmpresa";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";
import { loadStripe } from "@stripe/stripe-js";
import ModalLogeo from "../../alquilerScreen/alquilerAlojamiento/ModalLogeo";
import colores from "../../helpers/colores";
import ModalPolitica from "../casas/ModalPolitica";
import Swal from "sweetalert2";
import TipoPagoEmpresaAllPromo from "./TipoPagoEmpresaAllPromo";
import NavBarHome from "../../NavBarHome";

const CheckoutEmpresaAllPromo = () => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
        "Content-Type": "multipart/form-data",
    };
    const { idEmpresa, tipoPedido } = useParams();
    const [paying, setPaying] = useState(false);
    const [formaPago, setFormaPago] = useState("stripe");
    const [pedido, setPedido] = useState(null);
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.authReducer);
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );
    const [comprobantePreview, setComprobantePreview] = useState(null);
    const [comprobante, setComprobante] = useState(null);
    const [habilitarModal, setHabilitarModal] = useState(false);
    const [modalLogeo, setModalLogeo] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const form = new FormData();

    const getReserva = async (idEmpresa) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/promoEmpresas/getResumenCupones/${idEmpresa}`,
            { headers }
        );
        console.log(data);

        setPedido({
            ...data,
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (idEmpresa) getReserva(idEmpresa);
    }, [idEmpresa]);
    console.log(pedido);
    const handleComprobante = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        setComprobante(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setComprobantePreview(objectUrl);
    };

    console.log(pedido);

    const handlePagoComprobante = async () => {
        setPaying(true);

        const file = comprobante;

        form.append("id_empresa", idEmpresa);
        form.append("costo_total", pedido.costo_total);
        console.log("cupones", pedido.cupones);
        form.append("cupones", JSON.stringify(pedido.cupones));

        form.append("image_pago_masivo", file);

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/promoEmpresas/postPromoMasivoTransaccion`,
                form,
                { headers }
            );
            console.log(data);
            if (data.message === "registro existoso!") {
                navigate(`/pago_aprobado/comprobante/${idEmpresa}`);
            }
        } catch (error) {
            Swal.fire({
                title: error.response.data,
                icon: "error",
            });
            setPaying(false);
        }
    };

    if (pedido && pedido.id && pedido.pagado_empresa === "Si") {
        return (
            <div>
                <NavBarHome explorer={false} />
                <div className={` flex flex-col  select-none`}>
                    <div className={`bg-[${colores.primario}] text-center`}>
                        <h1 className="py-20 text-4xl font-bold text-white">
                            Checkout
                        </h1>
                    </div>
                    <div className="p-36">
                        <div className="p-10 text-xl border-8 border-dotted md:text-center md:text-3xl">
                            Este pago ya ha sido abonado por la empresa, muchas
                            gracias por su reserva.
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    } else if (pedido !== null) {
        return (
            <div className="w-full flex flex-col">
                <NavBarHome explorer={false} />
                <div className="absolute w-full ">
                    {habilitarModal && (
                        <ModalLogeo
                            modalLogeo={modalLogeo}
                            setModalLogeo={setModalLogeo}
                        />
                    )}
                    {modalVisible && (
                        <ModalPolitica
                            setModalVisible={setModalVisible}
                            modalVisible={modalVisible}
                            politica={pedido?.cancelacion}
                            porcentaje={pedido?.porcentaje_moderado}
                        />
                    )}
                </div>
                {idEmpresa ? (
                    <div className={` flex flex-col select-none w-full`}>
                        <div
                            className={`bg-[${colores.primario}] text-center w-full`}
                        >
                            <h1 className="py-20 w-full text-4xl font-bold text-white">
                                Checkout
                            </h1>
                        </div>
                        <div className="p-3 sm:mx-auto sm:p-10 lg:w-4/5">
                            {console.log(pedido)}
                            <div
                                className={`text-[${colores.primario}] font-semibold text-base flex flex-col-reverse`}
                            >
                                <TipoPagoEmpresaAllPromo
                                    pedido={pedido}
                                    formaPago={formaPago}
                                    setFormaPago={setFormaPago}
                                    stripePromise={stripePromise}
                                    comprobantePreview={comprobantePreview}
                                    handleComprobante={handleComprobante}
                                    handlePagoComprobante={
                                        handlePagoComprobante
                                    }
                                    paying={paying}
                                    idEmpresa={idEmpresa}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className={`${
                            authUser.tipo_usuario !== null &&
                            authUser.tipo_usuario <= 2
                                ? "pt-40"
                                : "pt-32"
                        } flex flex-col  select-none`}
                    >
                        <div className={`bg-[${colores.primario}] text-center`}>
                            <h1 className="py-20 text-4xl font-bold text-white">
                                Checkout
                            </h1>
                        </div>
                        <div className="p-36">
                            <div className="p-10 text-xl border-8 border-dotted md:text-center md:text-3xl">
                                No posee ninguna reserva pendiente
                            </div>
                        </div>
                    </div>
                )}
                <Footer />
            </div>
        );
    } else
        return (
            <div className="h-screen flex justify-center items-center">
                <Spinner width={12} />
            </div>
        );
};

export default CheckoutEmpresaAllPromo;
