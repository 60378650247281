import getPricesFromDates from "../helpers/getTotalPrice/getPriceFromDates";
import getPriceFromDays from "../helpers/getTotalPrice/getPriceFromDays";
import getPriceFromMonths from "../helpers/getTotalPrice/getPriceFromMonths";
import addDaysToDate from "../helpers/addDaysToDate";
import getPriceAditionalService from "../helpers/getTotalPrice/getPriceAditionalService";
import priceToMonedaElegida from "../helpers/priceToMonedaElegida";
import getFeeByDay from "../helpers/getFeeByDay";
import getPricesFromCalendar from "../helpers/getTotalPrice/getPriceFromCalendar";

const getTotalPrice = (
    selectedDays,
    currentProduct,
    cantDays,
    fee_bluecorner = true,
    priceFromDayNumbers = true,
    priceFromDiasExactos = true,
    aditionalServices = true,
    aditional_personas = true,
    es_empleado_empresa = false,
    redondeo = true,
    is_padre = false,
    membresia = "No"
) => {
    let totalPrice = 0;
    let total_fee_cliente = 0;
    let suma_fee_cliente = 0;
    let is_numero_dias_total = false;
    let tiene_fee_por_fechas = false;
    let newSelectedDays = [];
    console.log(currentProduct.nombre, selectedDays);
    if (currentProduct.tipo_estadia === "noche") {
        newSelectedDays = [selectedDays[0], addDaysToDate(selectedDays[1], -1)];
        cantDays--;
    } else {
        newSelectedDays = [selectedDays[0], selectedDays[1]];
    }

    const allDatesSelected = getEveryDayBetween2Dates(newSelectedDays);

    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    const dia_inicio = new Date(selectedDays[0])
        .toLocaleDateString("es-ES", options)
        .split(",")[0];

    const dia_final = new Date(selectedDays[1])
        .toLocaleDateString("es-ES", options)
        .split(",")[0];

    /********************* Array de dias exactos SIEMPRE y POR FECHAS    */
    if (!is_numero_dias_total) {
        let rangoEncontrado = {};
        const cumpleCondicion =
            currentProduct.precios_variables.arrayRangoExactoDiasTotal.find(
                (range) => {
                    if (range.tipo_tiempo === "Por fechas") {
                        if (
                            range.dia_inicio.toLowerCase() ===
                                dia_inicio.toLowerCase() &&
                            range.dia_final.toLowerCase() ===
                                dia_final.toLowerCase()
                        ) {
                            const FDI = new Date(
                                range.fecha_inicio + "T00:00:00"
                            );
                            const FDF = new Date(
                                range.fecha_final + "T00:00:00"
                            );
                            const FRI = selectedDays[0];
                            const FRF = selectedDays[1];

                            if (
                                (FDI <= FRI && FDF >= FRI) ||
                                (FDI <= FRF && FDF >= FRF) ||
                                (FRI <= FDI && FRF >= FDI) ||
                                (FRI <= FDF && FRF >= FDF)
                            ) {
                                return range;
                            }
                        }
                    }
                }
            );

        if (cumpleCondicion) {
            rangoEncontrado = cumpleCondicion;
        } else {
            rangoEncontrado =
                currentProduct.precios_variables.arrayRangoExactoDiasTotal.find(
                    (range) =>
                        range.dia_inicio.toLowerCase() ===
                            dia_inicio.toLowerCase() &&
                        range.dia_final.toLowerCase() ===
                            dia_final.toLowerCase() &&
                        range.tipo_tiempo === "Siempre"
                );
        }

        if (rangoEncontrado && cantDays <= 7) {
            is_numero_dias_total = true;
            totalPrice = rangoEncontrado.precio;

            if (fee_bluecorner) {
                const precio_por_dia = totalPrice / cantDays;

                allDatesSelected.forEach((date, index) => {
                    const { fee_cliente, tiene_fee_por_fecha } = getFeeByDay(
                        date,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario,
                        precio_por_dia
                    );

                    if (tiene_fee_por_fecha) {
                        tiene_fee_por_fechas = true;
                    }
                    suma_fee_cliente += fee_cliente;
                });
            }
        }
    }

    /********************* Array de dias exactos SIEMPRE y POR FECHAS    */

    /********************* Array de numero de dias SIEMPRE y POR FECHAS    */
    if (!is_numero_dias_total) {
        const cumpleCondicionNumberDays =
            currentProduct.precios_variables.arrayNumeroDiasTotal.find(
                (rango) => {
                    let newCantDays = cantDays;
                    if (currentProduct.tipo_estadia === "noche") {
                        newCantDays = cantDays + 1;
                    }
                    if (Number(rango.numero_dias) === newCantDays) {
                        if (rango.tipo_tiempo === "Por fechas") {
                            const FDI = new Date(
                                rango.fecha_inicio + "T00:00:00"
                            );
                            const FDF = new Date(
                                rango.fecha_final + "T00:00:00"
                            );
                            const FRI = selectedDays[0];
                            const FRF = selectedDays[1];

                            if (
                                (FDI <= FRI && FDF >= FRI) ||
                                (FDI <= FRF && FDF >= FRF) ||
                                (FRI <= FDI && FRF >= FDI) ||
                                (FRI <= FDF && FRF >= FDF)
                            ) {
                                return rango;
                            }
                        }
                    }
                }
            );
        if (cumpleCondicionNumberDays) {
            is_numero_dias_total = true;
            totalPrice = cumpleCondicionNumberDays.precio;

            if (fee_bluecorner) {
                const precio_por_dia = totalPrice / cantDays;

                allDatesSelected.forEach((date, index) => {
                    const { fee_cliente, tiene_fee_por_fecha } = getFeeByDay(
                        date,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario,
                        precio_por_dia
                    );

                    suma_fee_cliente += fee_cliente;

                    if (tiene_fee_por_fecha) {
                        tiene_fee_por_fechas = true;
                    }
                });
            }
        } else {
            currentProduct.precios_variables.arrayNumeroDiasTotal.forEach(
                (value) => {
                    let newCantDays = cantDays;
                    if (currentProduct.tipo_estadia === "noche") {
                        newCantDays = cantDays + 1;
                    }
                    if (Number(value.numero_dias) === newCantDays) {
                        if (value.tipo_tiempo === "Siempre") {
                            is_numero_dias_total = true;

                            totalPrice = value.precio;

                            if (fee_bluecorner) {
                                const precio_por_dia = totalPrice / cantDays;

                                allDatesSelected.forEach((date, index) => {
                                    const { fee_cliente, tiene_fee_por_fecha } =
                                        getFeeByDay(
                                            date,
                                            currentProduct.feeCasas,
                                            currentProduct.fee_cliente,
                                            currentProduct.fee_propietario,
                                            precio_por_dia
                                        );

                                    if (tiene_fee_por_fecha) {
                                        tiene_fee_por_fechas = true;
                                    }

                                    suma_fee_cliente += fee_cliente;
                                });
                            }
                        }
                    }
                }
            );
        }
    }

    /********************* Array de numero de dias SIEMPRE y POR FECHAS    */

    if (fee_bluecorner) {
        if (tiene_fee_por_fechas) {
            totalPrice += suma_fee_cliente;
        } else if (!tiene_fee_por_fechas) {
            const cumpleCondicion = currentProduct.feeCasas.find((range) => {
                if (range.tipo_tiempo === "Dias") {
                    if (
                        range.dia_inicio.toLowerCase() ===
                            dia_inicio.toLowerCase() &&
                        range.dia_final.toLowerCase() ===
                            dia_final.toLowerCase()
                    ) {
                        return range;
                    }
                }
            });

            if (cumpleCondicion) {
                totalPrice +=
                    totalPrice / (1 - cumpleCondicion.fee_cliente / 100);
            } else {
                totalPrice += suma_fee_cliente;
            }
        }
    }
    let descuento_dia_padre = 0;
    if (!is_numero_dias_total) {
        allDatesSelected.forEach((date) => {
            if (
                getPricesFromDates(
                    date,
                    currentProduct.precios_variables.arrayFechas,
                    currentProduct.moneda_precio_base,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario
                ).hasProperty
            ) {
                const { total, fee_cliente, tiene_fee_por_fecha } =
                    getPricesFromDates(
                        date,
                        currentProduct.precios_variables.arrayFechas,
                        currentProduct.moneda_precio_base,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario
                    );

                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                if (
                    date.getTime() ===
                        new Date("2023-06-16T00:00:00").getTime() ||
                    date.getTime() === new Date("2023-06-17T00:00:00").getTime()
                ) {
                    descuento_dia_padre += total * 0.1 + fee_cliente * 0.1;
                }
                return (
                    (totalPrice += total), (total_fee_cliente += fee_cliente)
                );
            } else if (
                getPriceFromDays(
                    date,
                    currentProduct.precios_variables.arrayDias,
                    currentProduct.moneda_precio_base,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario
                ).hasProperty
            ) {
                const { total, fee_cliente, tiene_fee_por_fecha } =
                    getPriceFromDays(
                        date,
                        currentProduct.precios_variables.arrayDias,
                        currentProduct.moneda_precio_base,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario
                    );
                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                if (
                    date.getTime() ===
                        new Date("2023-06-16T00:00:00").getTime() ||
                    date.getTime() === new Date("2023-06-17T00:00:00").getTime()
                ) {
                    descuento_dia_padre += total * 0.1 + fee_cliente * 0.1;
                }
                return (
                    (totalPrice += total), (total_fee_cliente += fee_cliente)
                );
            } else if (
                getPriceFromMonths(
                    date,
                    currentProduct.precios_variables.arrayMeses,
                    currentProduct.moneda_precio_base,
                    currentProduct.feeCasas,
                    currentProduct.fee_cliente,
                    currentProduct.fee_propietario
                ).hasProperty
            ) {
                const { total, fee_cliente, tiene_fee_por_fecha } =
                    getPriceFromMonths(
                        date,
                        currentProduct.precios_variables.arrayMeses,
                        currentProduct.moneda_precio_base,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario
                    );
                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                if (
                    date.getTime() ===
                        new Date("2023-06-16T00:00:00").getTime() ||
                    date.getTime() === new Date("2023-06-17T00:00:00").getTime()
                ) {
                    descuento_dia_padre += total * 0.1 + fee_cliente * 0.1;
                }
                return (
                    (totalPrice += total), (total_fee_cliente += fee_cliente)
                );
            } else {
                const { fee_cliente, tiene_fee_por_fecha } =
                    getPricesFromCalendar(
                        date,
                        currentProduct.precio_base,
                        currentProduct.feeCasas,
                        currentProduct.fee_cliente,
                        currentProduct.fee_propietario
                    );

                if (tiene_fee_por_fecha) {
                    tiene_fee_por_fechas = true;
                }
                if (
                    date.getTime() ===
                        new Date("2023-06-16T00:00:00").getTime() ||
                    date.getTime() === new Date("2023-06-17T00:00:00").getTime()
                ) {
                    descuento_dia_padre +=
                        currentProduct.precio_base * 0.1 + fee_cliente * 0.1;
                }
                return (
                    (totalPrice += currentProduct.precio_base),
                    (total_fee_cliente += fee_cliente)
                );
            }
        });

        //Los redondeo a numero con 2 decimales
        totalPrice = Number(totalPrice.toFixed(2));
        total_fee_cliente = Number(total_fee_cliente.toFixed(2));
        //Los redondeo a numero con 2 decimales

        if (fee_bluecorner) {
            //totalPrice += total_fee_cliente;

            if (tiene_fee_por_fechas) {
                totalPrice += total_fee_cliente;
            } else if (!tiene_fee_por_fechas) {
                const cumpleCondicion = currentProduct.feeCasas.find(
                    (range) => {
                        if (range.tipo_tiempo === "Dias") {
                            if (
                                range.dia_inicio.toLowerCase() ===
                                    dia_inicio.toLowerCase() &&
                                range.dia_final.toLowerCase() ===
                                    dia_final.toLowerCase()
                            ) {
                                return range;
                            }
                        }
                    }
                );

                if (cumpleCondicion) {
                    totalPrice +=
                        totalPrice / (1 - cumpleCondicion.fee_cliente / 100);
                } else {
                    totalPrice += total_fee_cliente;
                }
            }
        }
    }

    if (aditionalServices) {
        //Modifico cantDays nuevamente para que lo cambie el getPriceAditionalService
        let newCantDays;
        let price_aditional_service = 0;
        if (currentProduct.tipo_estadia === "noche") {
            newCantDays = cantDays + 1;
        }

        currentProduct.servicios_adicionales.forEach((service) => {
            price_aditional_service =
                price_aditional_service +
                Number(
                    getPriceAditionalService(
                        service,
                        newCantDays,
                        selectedDays,
                        currentProduct
                    )
                );
        });

        totalPrice = totalPrice + price_aditional_service;
    }

    if (aditional_personas) {
        const { huespedes } = currentProduct;

        const dia_inicio = new Date(selectedDays[0]);
        const dia_final = new Date(selectedDays[1]);

        let rango = currentProduct.personas_adicionales.find((range) => {
            if (
                huespedes <= range.rango_persona_final &&
                huespedes >= range.rango_persona_inicio
            ) {
                if (range.tipo_tiempo === "Fecha") {
                    range.fecha_inicio = new Date(range.fecha_inicio);
                    range.fecha_final = new Date(range.fecha_final);

                    if (
                        (range.fecha_inicio <= dia_inicio &&
                            range.fecha_final >= dia_inicio) ||
                        (range.fecha_inicio <= dia_final &&
                            range.fecha_final >= dia_final) ||
                        (dia_inicio <= range.fecha_inicio &&
                            dia_final >= range.fecha_inicio) ||
                        (dia_inicio <= range.fecha_final &&
                            dia_final >= range.fecha_final)
                    ) {
                        return range;
                    }
                }
            }
        });

        if (!rango) {
            const dia_inicio = new Date(selectedDays[0])
                .toLocaleDateString("es-ES", options)
                .split(",")[0];
            const dia_final = new Date(selectedDays[1])
                .toLocaleDateString("es-ES", options)
                .split(",")[0];

            rango = currentProduct.personas_adicionales.find((range) => {
                if (
                    huespedes <= range.rango_persona_final &&
                    huespedes >= range.rango_persona_inicio
                ) {
                    if (range.tipo_tiempo === "Días de la semana") {
                        if (
                            range.dia_inicio.toLowerCase() ===
                                dia_inicio.toLowerCase() &&
                            range.dia_final.toLowerCase() ===
                                dia_final.toLowerCase()
                        ) {
                            return range;
                        }
                    }
                }
            });
        }

        if (!rango) {
            rango = currentProduct.personas_adicionales.find((range) => {
                if (
                    huespedes <= range.rango_persona_final &&
                    huespedes >= range.rango_persona_inicio
                ) {
                    if (range.tipo_tiempo === "Rango de días") {
                        const newCantDays =
                            currentProduct.tipo_estadia === "dia"
                                ? cantDays
                                : cantDays + 1;
                        if (
                            newCantDays <= Number(range.dia_final) &&
                            newCantDays >= Number(range.dia_inicio)
                        ) {
                            return range;
                        }
                    }
                }
            });
        }

        if (!rango) {
            rango = currentProduct.personas_adicionales.find((range) => {
                if (
                    huespedes <= range.rango_persona_final &&
                    huespedes >= range.rango_persona_inicio
                ) {
                    if (range.tipo_tiempo === "Siempre") {
                        return range;
                    }
                }
            });
        }

        if (rango) {
            const dia_inicio = new Date(selectedDays[0])
                .toLocaleDateString("es-ES", options)
                .split(",")[0];
            const dia_final = new Date(selectedDays[1])
                .toLocaleDateString("es-ES", options)
                .split(",")[0];
            let total_personas = 0;
            let total_fee_personas = 0;

            if (rango.tipo === "por estadia") {
                total_personas = rango.precio;
                const precio_por_dia = rango.precio / cantDays;

                if (fee_bluecorner) {
                    allDatesSelected.forEach((date) => {
                        const { fee_cliente, tiene_fee_por_fecha } =
                            getFeeByDay(
                                date,
                                currentProduct.feeCasas,
                                currentProduct.fee_cliente,
                                currentProduct.fee_propietario,
                                precio_por_dia
                            );
                        if (tiene_fee_por_fecha) {
                            tiene_fee_por_fechas = true;
                        }

                        total_fee_personas += fee_cliente;
                    });

                    if (tiene_fee_por_fechas) {
                        total_personas += total_fee_personas;
                    } else if (!tiene_fee_por_fechas) {
                        const cumpleCondicion = currentProduct.feeCasas.find(
                            (range) => {
                                if (range.tipo_tiempo === "Dias") {
                                    if (
                                        range.dia_inicio.toLowerCase() ===
                                            dia_inicio.toLowerCase() &&
                                        range.dia_final.toLowerCase() ===
                                            dia_final.toLowerCase()
                                    ) {
                                        return range;
                                    }
                                }
                            }
                        );

                        if (cumpleCondicion) {
                            total_personas +=
                                total_personas /
                                    (1 - cumpleCondicion.fee_cliente / 100) -
                                total_personas;
                        } else {
                            total_personas += total_fee_personas;
                        }
                    }
                }
            } else if (rango.tipo === "por noche") {
                total_personas = rango.precio * cantDays;
                const precio_por_dia = rango.precio;

                if (fee_bluecorner) {
                    allDatesSelected.forEach((date) => {
                        const { fee_cliente, tiene_fee_por_fecha } =
                            getFeeByDay(
                                date,
                                currentProduct.feeCasas,
                                currentProduct.fee_cliente,
                                currentProduct.fee_propietario,
                                precio_por_dia
                            );
                        if (tiene_fee_por_fecha) {
                            tiene_fee_por_fechas = true;
                        }
                        total_fee_personas += fee_cliente;
                    });

                    if (tiene_fee_por_fechas) {
                        total_personas += total_fee_personas;
                    } else if (!tiene_fee_por_fechas) {
                        const cumpleCondicion = currentProduct.feeCasas.find(
                            (range) => {
                                if (range.tipo_tiempo === "Dias") {
                                    if (
                                        range.dia_inicio.toLowerCase() ===
                                            dia_inicio.toLowerCase() &&
                                        range.dia_final.toLowerCase() ===
                                            dia_final.toLowerCase()
                                    ) {
                                        return range;
                                    }
                                }
                            }
                        );

                        if (cumpleCondicion) {
                            total_personas +=
                                total_personas /
                                    (1 - cumpleCondicion.fee_cliente / 100) -
                                total_personas;
                        } else {
                            total_personas += total_fee_personas;
                        }
                    }
                }
            }

            totalPrice = totalPrice + total_personas;
        }
    }

    if (es_empleado_empresa) {
        let descuento_empresa =
            membresia === "No"
                ? Number(currentProduct.descuento_empresa_free) / 100
                : Number(currentProduct.descuento_empresa_premium) / 100;
        totalPrice -= totalPrice * descuento_empresa;
    }

    let combo_parrilla_inicio_1 = new Date("2023-06-29T00:00:00");
    let combo_parrilla_final_1 = new Date("2023-07-02T00:00:00");

    let combo_parrilla_inicio_2 = new Date("2023-07-27T00:00:00");
    let combo_parrilla_final_2 = new Date("2023-07-30T00:00:00");

    if (
        ((selectedDays[0] > combo_parrilla_inicio_1 &&
            selectedDays[0] <= combo_parrilla_final_1) ||
            (selectedDays[1] > combo_parrilla_inicio_1 &&
                selectedDays[1] <= combo_parrilla_final_1) ||
            (selectedDays[0] <= combo_parrilla_inicio_1 &&
                selectedDays[1] >= combo_parrilla_final_1)) &&
        fee_bluecorner
    ) {
        if (currentProduct.moneda_precio_base === "PEN") {
            totalPrice += 222;
        } else {
            totalPrice += 60;
        }
    } else if (
        ((selectedDays[0] > combo_parrilla_inicio_2 &&
            selectedDays[0] <= combo_parrilla_final_2) ||
            (selectedDays[1] > combo_parrilla_inicio_2 &&
                selectedDays[1] <= combo_parrilla_final_2) ||
            (selectedDays[0] <= combo_parrilla_inicio_2 &&
                selectedDays[1] >= combo_parrilla_final_2)) &&
        fee_bluecorner
    ) {
        if (currentProduct.moneda_precio_base === "PEN") {
            totalPrice += 222;
        } else {
            totalPrice += 60;
        }
    }

    if (is_padre) {
        // localStorage.setItem("descuento_dp", descuento_dia_padre);
        return Math.floor(descuento_dia_padre);
    } else {
        return priceToMonedaElegida(
            totalPrice,
            currentProduct.moneda_precio_base,
            redondeo
        );
    }
};

const getEveryDayBetween2Dates = (arrayFechas) => {
    const [startDate, endDate] = arrayFechas.map((fecha) => new Date(fecha));
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
};

export default getTotalPrice;
