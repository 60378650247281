import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import AllCards from "./AllCards";
import bannerLandingDestacatos from "../../../img/Casa-Villa-Espejo-1.png";

import ExplorerDestacados from "./ExplorerDestacados";
import NavBarHome from "../../NavBarHome";
import BannerCuotealo from "../../home/BannerCuotealo";

import axios from "axios";
import MapAlojamientos from "./MapAlojamientos";
import { useWindowDimension } from "../../../hooks/useWindowDimension";
import BannerCuotealoFooter from "../../home/BannerCuotealoFooter";
import { useParams } from "react-router";

const LandingDestacados = () => {
    const [windowWidth, windowsHeight] = useWindowDimension();

    const [vistaAdministrador, setVistaAdministrador] = useState(false);
    const [refreshHouses, setRefreshHouses] = useState(false);

    const [allProducts, setAllProducts] = useState([]);
    const [allProductsMap, setAllProductsMap] = useState([]);

    const [selectedDays, setSelectedDays] = useState([]);
    const [cantDays, setCantDays] = useState(0);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [checking, setChecking] = useState(true);
    const [mapShouldFly, setMapShouldFly] = useState(false);
    const mapRef = useRef(null);

    const [numero_noches, setnumero_noches] = useState(0);

    const [personas, setPersonas] = useState(0);

    const { filtros } = useParams();

    let filtrosSplit = filtros?.split("&");

    const [opciones, setOpciones] = useState("populares");
    const getHouses = async () => {
        let filtros =
            filtrosSplit?.length === 4
                ? filtrosObjeto(filtrosSplit)
                : {
                      fecha_inicio: "",
                      fecha_final: "",
                      numero_personas: 0,
                      numero_noches: 0,
                  };

        try {
            setChecking(true);
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/casa/allCasasDestacadas`,
                {
                    opciones,
                    fecha_inicio: filtros.fecha_inicio,
                    fecha_final: filtros.fecha_final,
                    numero_personas: filtros.numero_personas,
                    numero_noches: filtros.numero_noches,
                }
            );
            setChecking(false);
            // setAllProducts(data.data);
            setAllProductsMap(data);
            setMapShouldFly(true);

            setSelectedMarker(data.data[0]);
        } catch (error) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        getHouses();
    }, [refreshHouses, opciones, filtros]);

    const filtrosObjeto = (filtros, pagina) => {
        let objeto = {
            fecha_final: filtros[0].split("=")[1],
            fecha_inicio: filtros[1].split("=")[1],
            numero_personas: filtros[2].split("=")[1],
            numero_noches: filtros[3]?.split("=")[1],
        };

        setnumero_noches(objeto.numero_noches);
        setPersonas(objeto.numero_personas);

        if (
            filtrosSplit[0].split("=")[1] !== "" &&
            filtrosSplit[0].split("=")[1] !== null &&
            filtrosSplit[1].split("=")[1] !== "" &&
            filtrosSplit[1].split("=")[1] !== null
        ) {
            const startDate = new Date(
                `${filtrosSplit[1].split("=")[1]}T00:00:00`
            );
            const endDate = new Date(
                `${filtrosSplit[0].split("=")[1]}T00:00:00`
            );

            // Calcular la diferencia en días
            const diffInMilliseconds = endDate.getTime() - startDate.getTime();
            const numberOfNights = Math.ceil(
                diffInMilliseconds / (1000 * 60 * 60 * 24)
            );

            setSelectedDays([startDate, endDate]);
            setCantDays(numberOfNights);
        }

        return objeto;
    };

    return (
        <div>
            <div className="border-b border-[#0F80F2] mb-7  ">
                <NavBarHome landing="destacados" />
                <BannerCuotealo />

                <div className="grid lg1170:grid-cols-[60%,40%] h-full">
                    <div className="flex w-full p-4 mt-2 text-white lg1170:hidden">
                        <div className="relative w-full h-full transition-all max-h-[400px] duration-300 rounded-lg bg-gradient-to-t from-[#053465] to-transparent ">
                            <div className="absolute bottom-0 w-full h-full bg-gradient-to-tr from-[#053465] to-transparent z-10 rounded-lg"></div>
                            <img
                                src={bannerLandingDestacatos}
                                className="w-full h-full mx-auto transition-all duration-200 ease-out rounded-lg"
                                alt={`Banner landing destacados`}
                            />
                            <div className="absolute bottom-0 z-20 w-full p-4 text-white md723:p-8">
                                <p className="flex ">Conoce nuestras</p>

                                <p className="text-2xl font-bold ">
                                    Casas <br></br> Exclusivas
                                </p>
                                <p className="mt-1 text-sm ">
                                    Seleccionamos las mejores casas para{" "}
                                    <br></br>tu mejor comodidad y disponibilidad
                                </p>

                                <div className="flex gap-5 mt-2 text-xs">
                                    <p className="text-[#0F80F2] bg-white px-5 py-2 rounded-3xl">
                                        Explóralos aquí
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" lg1170:hidden h-96">
                        <MapAlojamientos
                            allProducts={allProductsMap}
                            selectedMarker={selectedMarker}
                            setSelectedMarker={setSelectedMarker}
                            mapShouldFly={mapShouldFly}
                            setMapShouldFly={setMapShouldFly}
                            mapRef={mapRef}
                            cantDays={cantDays}
                            selectedDays={selectedDays}
                        />
                    </div>
                    <div
                        className={`lg1170:h-screen ${
                            windowWidth < 1170 ? "" : "overflow-y-auto"
                        }`}
                    >
                        <div className=" text-white  transition-all ease-out duration-[200ms] p-4 w-full  xl:w-[90%]  mx-auto mt-2 lg1170:flex hidden">
                            <div className="relative w-full h-full transition-all max-h-[400px] duration-300 rounded-lg bg-gradient-to-t from-[#053465] to-transparent ">
                                <div className="absolute bottom-0 w-full h-full bg-gradient-to-tr from-[#053465] to-transparent z-10 rounded-lg"></div>
                                <img
                                    src={bannerLandingDestacatos}
                                    className="w-full h-full mx-auto transition-all duration-200 ease-out rounded-lg"
                                    alt={`Banner landing destacados`}
                                />
                                <div className="absolute bottom-0 z-20 w-full p-4 text-white md723:p-8">
                                    <p className="flex text-xl ">
                                        Conoce nuestras
                                    </p>

                                    <p className="hidden text-4xl font-bold md723:flex">
                                        Casas <br></br> Exclusivas
                                    </p>
                                    <p className="text-sm  max-w-[500px] mt-2 hidden md723:flex">
                                        Seleccionamos las mejores casas para{" "}
                                        <br></br>tu mejor comodidad y
                                        disponibilidad
                                    </p>

                                    <div className="gap-5 mt-6 text-xs md723:flex">
                                        <p className="text-[#0F80F2] bg-white px-5 py-2 rounded-3xl">
                                            Explóralos aquí
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ExplorerDestacados
                            vistaAdministrador={vistaAdministrador}
                            setVistaAdministrador={setVistaAdministrador}
                            setRefreshHouses={setRefreshHouses}
                            opciones={opciones}
                            setOpciones={setOpciones}
                        />

                        <AllCards
                            vistaAdministrador={vistaAdministrador}
                            refreshHouses={refreshHouses}
                            setRefreshHouses={setRefreshHouses}
                            checking={checking}
                            allHouses={allProductsMap}
                            setAllHouses={setAllProducts}
                            setSelectedMarker={setSelectedMarker}
                            setMapShouldFly={setMapShouldFly}
                            selectedDays={selectedDays}
                            personas={personas}
                            cantDays={numero_noches}
                        />
                    </div>
                    <div className="hidden lg1170:block lg1170:h-screen">
                        <MapAlojamientos
                            allProducts={allProductsMap}
                            selectedMarker={selectedMarker}
                            setSelectedMarker={setSelectedMarker}
                            mapShouldFly={mapShouldFly}
                            setMapShouldFly={setMapShouldFly}
                            mapRef={mapRef}
                            cantDays={cantDays}
                            selectedDays={selectedDays}
                            personas={personas}
                        />
                    </div>
                </div>
            </div>
            <BannerCuotealoFooter />
            <Footer messageWhatsapp={""} />
        </div>
    );
};

export default LandingDestacados;
