import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import NavBar from "../NavBar";
import Footer from "../Footer";
import axios from "axios";
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

import useScrollTo from "../../hooks/useScrollToTop";
import NavBarHome from "../NavBarHome";
import Cuotealo from "../cuotealo/Cuotealo";
import BannerCuotealo from "../home/BannerCuotealo";

const DetailedBlog = () => {
    const { idBlog } = useParams();

    const [blog, setBlog] = useState(null);
    const [previous, setPrevious] = useState([]);
    const [next, setNext] = useState([]);
    const navigate = useNavigate();

    useScrollTo();
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    useEffect(() => {
        getBlogDetails(idBlog);
    }, [idBlog]);

    const getBlogDetails = async (id) => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/entrada/detalle/${id}/`
        );

        setBlog(json.data);

        const jsonNext = await axios.get(
            `${process.env.REACT_APP_PROXY}/entrada/getPreviousNext/${id}/`
        );
        let pre = jsonNext.data.filter((i) => i.lugar === "Anterior");
        let nex = jsonNext.data.filter((i) => i.lugar === "Siguiente");
        setPrevious(pre);
        setNext(nex);
    };

    const mes = {
        "01": "Enero",
        "02": "Febrero",
        "03": "Marzo",
        "04": "Abril",
        "05": "Mayo",
        "06": "Junio",
        "07": "Julio",
        "08": "Agosto",
        "09": "Septiembre",
        10: "Octubre",
        11: "Noviembre",
        12: "Diciembre",
    };
    return (
        <div>
            <NavBarHome />
            <BannerCuotealo />
            <div className="w-full max-h-[550px] overflow-hidden">
                <img
                    className="object-cover w-full h-full"
                    alt="banner de la entrada"
                    src={
                        `${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/entrada/getbanner/` +
                        blog?.banner
                    }
                />
            </div>
            <div className="px-4 bg-white xs:px-10 ">
                <div className="w-full mx-auto xs:w-10/12">
                    {/* <p className="text-[#053465] text-base mt-14">
                        {mes[blog?.fecha_registro?.substring(5, 7)] +
                            " " +
                            blog?.fecha_registro?.substring(8, 10) +
                            ", " +
                            blog?.fecha_registro?.substring(0, 4)}
                    </p> */}
                    <h2 className="text-[#053465] text-lg xs:text-2xl lg:text-4xl font-bold my-5">
                        {blog?.titulo}
                    </h2>
                    {/* <div className="mb-5">
                        <FacebookShareButton
                            className="mr-4"
                            url={`https://www.bluecorner.com.pe/blog/${idBlog}`}>
                            <FacebookIcon size={60} />
                        </FacebookShareButton>
                        <LinkedinShareButton
                            className="xs:mx-4 "
                            url={`https://www.bluecorner.com.pe/blog/${idBlog}`}>
                            <LinkedinIcon size={60} />
                        </LinkedinShareButton>
                        <PinterestShareButton
                            className="mx-4"
                            url={`https://www.bluecorner.com.pe/blog/${idBlog}`}>
                            <PinterestIcon size={60} />
                        </PinterestShareButton>
                        <TwitterShareButton
                            className=" xs:mx-4"
                            url={`https://www.bluecorner.com.pe/blog/${idBlog}`}>
                            <TwitterIcon size={60} />
                        </TwitterShareButton>{" "}
                        <WhatsappShareButton
                            className="mx-3 xs:mx-4"
                            url={`https://www.bluecorner.com.pe/blog/${idBlog}`}>
                            <WhatsappIcon size={60} />
                        </WhatsappShareButton>
                    </div> */}
                </div>
                {/* <hr className="w-10/12 mx-auto" /> */}

                <br></br>

                <div
                    className="w-full mx-auto xs:w-10/12 "
                    dangerouslySetInnerHTML={{ __html: blog?.descripcion }}
                />
                <br></br>
                <br></br>
                <br></br>
                {/* <div className="grid w-10/12 mx-auto my-5 md:flex md:justify-around">
                    <div>
                        <h2 className="text-[#053465] font-bold text-3xl">
                            Compartir
                        </h2>
                    </div>

                    <div>
                        <FacebookShareButton
                            className="md:mr-4"
                            url={`https://blue-corner.vercel.app/blog/${idBlog}`}>
                            <FacebookIcon size={60} />
                        </FacebookShareButton>
                        <LinkedinShareButton
                            className="md:mx-4"
                            url={`https://blue-corner.vercel.app/blog/${idBlog}`}>
                            <LinkedinIcon size={60} />
                        </LinkedinShareButton>
                        <PinterestShareButton
                            className="md:mx-4"
                            url={`https://blue-corner.vercel.app/blog/${idBlog}`}>
                            <PinterestIcon size={60} />
                        </PinterestShareButton>
                        <TwitterShareButton
                            className="md:mx-4"
                            url={`https://blue-corner.vercel.app/blog/${idBlog}`}>
                            <TwitterIcon size={60} />
                        </TwitterShareButton>
                    </div>
                </div> */}
                {/* <div className="bg-[#f7f7f7] border border-gray-300  border-solid w-10/12 mx-auto p-8 ">
                    <div className="flex justify-between">
                        <div className=" text-[#053465] text-xs relative">
                            <span
                                onClick={() =>
                                    navigate(`/blog/${previous[0]?.id}`)
                                }
                                className={
                                    previous.length
                                        ? " text-[#053465] text-2xl font-bold pr-2 top-[5px] right-[100%] absolute cursor-pointer"
                                        : "hidden"
                                }>
                                {"<"}
                            </span>
                            {""}
                            <span
                                onClick={() =>
                                    navigate(`/blog/${previous[0]?.id}`)
                                }
                                className={
                                    previous.length
                                        ? "text-[#053465] cursor-pointer"
                                        : "hidden"
                                }>
                                ENTRADA ANTERIOR
                            </span>
                        </div>
                        <div className="relative text-xs ">
                            <span
                                onClick={() => navigate(`/blog/${next[0]?.id}`)}
                                className={
                                    next.length
                                        ? "text-[#053465] cursor-pointer"
                                        : "hidden"
                                }>
                                ENTRADA SIGUIENTE
                            </span>
                            <span
                                onClick={() => navigate(`/blog/${next[0]?.id}`)}
                                className={
                                    next.length
                                        ? " text-[#053465] text-2xl font-bold pl-2 top-[5px] absolute cursor-pointer"
                                        : "hidden"
                                }>
                                {">"}
                            </span>
                        </div>
                    </div>
                    <div className="flex justify-between ">
                        <p className="md:text-lg xl:text-xl font-serif text-[#053465] ">
                            <span
                                onClick={() =>
                                    navigate(`/blog/${previous[0]?.id}`)
                                }
                                className="cursor-pointer">
                                {previous[0]?.titulo}
                            </span>
                        </p>
                        <p className="md:text-lg xl:text-xl font-serif text-[#053465] ">
                            <span
                                onClick={() => navigate(`/blog/${next[0]?.id}`)}
                                className="cursor-pointer">
                                {next[0]?.titulo}
                            </span>
                        </p>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    );
};

export default DetailedBlog;
