import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Lazy } from "swiper";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner";
import colores from "../../helpers/colores";

import "swiper/css/lazy";

const ImagesDetailedCasa = ({
    currentProduct,
    setModalImagesVisible,
    modalImagesVisible,
}) => {
    const authUser = useSelector((state) => state.authReducer);
    const totalImages = Number(currentProduct.imagenes.length) + 1;
    const [contImages, setContImages] = useState(1);

    const handleSlideChange = (swiper) => {
        let newIndex = swiper.realIndex + 1; // Swiper usa base 0, así que sumamos 1

        if (newIndex > totalImages) {
            newIndex = 1; // Si se pasa, vuelve al inicio
        }
        if (newIndex < 1) {
            newIndex = totalImages; // Si retrocede desde 1, vuelve al final
        }

        setContImages(newIndex);
    };

    return (
        <div className="relative">
            <header className={` mx-auto `}>
                {currentProduct?.imagen_principal ? (
                    <div className="grid xs:grid-cols-[60%,40%] mt-3 relative overflow-hidden">
                        <div
                            className={
                                currentProduct.oferta_activada === "Si"
                                    ? "absolute left-[-40px] z-20 py-2 text-center -rotate-45 bg-red-700 w-44 top-7"
                                    : "hidden"
                            }
                        >
                            <p className="text-sm text-white">
                                {currentProduct.oferta}% de descuento
                            </p>
                        </div>

                        <div
                            onClick={() =>
                                setModalImagesVisible(!modalImagesVisible)
                            }
                            className=" relative w-full  h-[250px] flex md:hidden overflow-hidden  "
                        >
                            <Swiper
                                modules={[Lazy]}
                                slidesPerView={1}
                                watchSlidesProgress
                                lazy={true}
                                spaceBetween={100}
                                rewind={true}
                                onSlideChange={handleSlideChange}
                            >
                                <SwiperSlide className="cursor-pointer">
                                    <img
                                        loading="lazy"
                                        className="object-cover w-full h-full rounded-xl swiper-lazy"
                                        alt="Imagen del producto"
                                        data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${currentProduct.imagen_principal_minificada}`}
                                        data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${currentProduct.imagen_principal_minificada}`}
                                    />
                                    <div className="swiper-lazy-preloader">
                                        <div className="flex flex-col w-full h-full gap-2 p-3 bg-gray-200">
                                            <div className="bg-slate-50 w-full h-[80%] rounded-xl"></div>
                                            <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                            <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                {currentProduct.imagenes.map((imagen) => (
                                    <SwiperSlide key={imagen.id}>
                                        <img
                                            loading="lazy"
                                            className="object-cover w-full h-full rounded-xl swiper-lazy"
                                            alt="Imagen del producto"
                                            data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${imagen.imagen_minificada}`}
                                            data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${imagen.imagen_minificada}`}
                                        />
                                        <div className="swiper-lazy-preloader">
                                            <div className="flex flex-col w-full h-full gap-2 p-3 bg-gray-200">
                                                <div className="bg-slate-50 w-full h-[80%] rounded-xl"></div>
                                                <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="absolute z-30 bottom-6 right-4 font-semibold rounded-lg p-1 px-3 bg-[#000000] bg-opacity-50 text-white text-xs">
                                {contImages} / {totalImages}
                            </div>
                        </div>

                        <div className=" items-center justify-center hidden md:flex ">
                            <img
                                alt="Imagen no encontrada"
                                src={`${
                                    process.env.REACT_APP_PROXY_ARCHIVOS
                                }${"/bluecorner/v1/casa/getImagenPrincipal/"}${
                                    currentProduct.imagen_principal
                                }`}
                                className="object-cover w-full h-[240px] xs:h-[300px] 2xl:h-[480px] rounded-b-xl xs:rounded-none md:rounded-l-xl hover:brightness-[80%]  duration-700 "
                            />
                        </div>
                        <div className="flex-col items-center justify-center hidden pl-4 md:flex">
                            <img
                                alt="Imagen no encontrada"
                                src={`${
                                    process.env.REACT_APP_PROXY_ARCHIVOS
                                }${"/bluecorner/v1/productoImagenes/getImagen/"}${
                                    currentProduct?.imagenes[0]?.imagen
                                }`}
                                className="object-cover w-full pb-2 rounded-tr-xl   h-[120px] xs:h-[150px]   2xl:h-[240px] hover:brightness-[80%]  duration-700 "
                            />
                            <img
                                alt="Imagen no encontrada"
                                src={`${
                                    process.env.REACT_APP_PROXY_ARCHIVOS
                                }${"/bluecorner/v1/productoImagenes/getImagen/"}${
                                    currentProduct?.imagenes[1]?.imagen
                                }`}
                                className="object-cover w-full pt-2 rounded-br-xl    h-[120px] xs:h-[150px]  2xl:h-[240px] hover:brightness-[80%]  duration-700 "
                            />
                        </div>
                    </div>
                ) : (
                    <div className="my-56">
                        <Spinner />
                    </div>
                )}
            </header>
            {currentProduct?.imagen_principal && (
                <>
                    <button
                        className={`hidden md:block xs:text-xs lg:text-base absolute p-3 font-medium text-[${
                            colores.secundario
                        }] transition ease-linear delay-75 z-20 bg-white border-2 border-[${
                            colores.secundario
                        }] right-7   sm:bottom-4 top-40 sm:top-auto rounded-xl hover:bg-[${
                            colores.secundario
                        }] hover:text-white ${
                            currentProduct?.imagen_principal ? "" : "hidden"
                        }`}
                        onClick={() =>
                            setModalImagesVisible(!modalImagesVisible)
                        }
                    >
                        <span>Mostrar todas las fotos</span>
                    </button>
                </>
            )}
        </div>
    );
};

export default ImagesDetailedCasa;
