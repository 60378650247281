import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import "swiper/css/lazy";
import "../../empresas/paquetesEmpresa/Swiper.css";
import useHeaders from "../../../hooks/useHeaders";
import { ReactSortable } from "react-sortablejs";
import { useSelector } from "react-redux";
import CardSanValentin from "./CardSanValentin";
import { Paginado } from "../../helpers/Paginado";
import { Swiper, SwiperSlide } from "swiper/react";

const AllCards = ({ vistaAdministrador, refreshHouses, setRefreshHouses }) => {
    const [allHouses, setAllHouses] = useState([]);
    const [totalHouses, setTotalHouses] = useState(0);
    const [checking, setChecking] = useState(true);
    const { headers } = useHeaders();

    const [opciones, setOpciones] = useState("todos");
    const [pageNumber, setpageNumber] = useState(1);

    const authUser = useSelector((state) => state.authReducer);
    const LABELS_PER_PAGE = 16;

    const [loading, setLoading] = useState(false);

    const getHouses = async () => {
        setLoading(true);
        try {
            setChecking(true);
            console.log(pageNumber);
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/casasSemanaSanta/getAll?page=${pageNumber}&limit=${LABELS_PER_PAGE}&opciones=${opciones}`
            );

            setChecking(false);

            setAllHouses(data.result);
            setTotalHouses(data.total);
        } catch (error) {
            console.log(error.response);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getHouses();
    }, [refreshHouses, opciones, pageNumber]);

    const handleDragEnd = async () => {
        const allHousesID = allHouses.map((house) => house.id);

        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casaSanValentin/updateOrden/`,
                { array_orden: allHousesID },
                { headers }
            );
        } catch (error) {
            console.log(error.response);
        }
    };

    const pagination = (pageNumber) => {
        setLoading(true);
        setpageNumber(pageNumber);
    };

    return (
        <div>
            <div className="justify-start items-center gap-5  mt-5  xl:px-32 2xl:px-28 px-4 md:flex hidden ">
                <div
                    className={`${
                        opciones === "todos"
                            ? " text-white bg-[#0F80F2]"
                            : "bg-[#0F80F2] bg-opacity-20 text-[#053465] "
                    }  rounded-full p-1 flex justify-center items-center cursor-pointer`}
                    onClick={() => {
                        setOpciones("todos");
                        setpageNumber(1);
                    }}
                >
                    <div className="p-1.5 bg-white rounded-full">
                        <svg
                            className="w-5 h-5"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M20.9225 7.06002L12.9225 2.62002C12.3925 2.32002 11.7525 2.32002 11.2225 2.62002L3.22251 7.06002C2.67251 7.37002 2.32251 7.96002 2.32251 8.59002V11.15C2.32251 11.6 2.55251 12 2.94251 12.23C3.06251 12.3 3.19251 12.34 3.32251 12.37V18C3.32251 20.07 5.00251 21.75 7.07251 21.75H17.0725C19.1425 21.75 20.8225 20.07 20.8225 18V12.37C20.9525 12.34 21.0825 12.3 21.2025 12.23C21.5925 12 21.8225 11.6 21.8225 11.15V8.59002C21.8225 7.96002 21.4825 7.37002 20.9225 7.06002ZM3.82251 8.59002C3.82251 8.50002 3.87251 8.41002 3.95251 8.37002L11.9525 3.92002C12.0325 3.88002 12.1225 3.88002 12.1925 3.92002L20.1925 8.36002C20.2725 8.40002 20.3225 8.49002 20.3225 8.58002V10.72L12.9225 6.61002C12.6525 6.46002 12.3625 6.39002 12.0725 6.39002C11.7825 6.39002 11.4925 6.46002 11.2225 6.61002L3.82251 10.72V8.58002V8.59002ZM10.8225 16.75H13.3225V20.25H10.8225V16.75ZM10.9825 15.25C11.0425 15.14 11.1025 15.03 11.1825 14.94L11.8925 14.23C11.9925 14.13 12.1525 14.13 12.2425 14.23L12.9525 14.94C13.0425 15.03 13.1025 15.14 13.1525 15.25H10.9725H10.9825ZM19.3225 18C19.3225 19.24 18.3125 20.25 17.0725 20.25H14.8225V15.83C14.8225 15.1 14.5325 14.41 14.0125 13.89L13.3025 13.18C12.6225 12.5 11.5125 12.5 10.8325 13.18L10.1225 13.89C9.60251 14.41 9.31251 15.1 9.31251 15.83V20.25H7.06251C5.82251 20.25 4.81251 19.24 4.81251 18V12C4.81251 12 4.79251 11.93 4.79251 11.9L11.9425 7.93002C12.0225 7.89002 12.1125 7.89002 12.1825 7.93002L19.3325 11.9C19.3325 11.9 19.3125 11.97 19.3125 12V18H19.3225Z"
                                fill="#053465"
                            />
                        </svg>
                    </div>

                    <div className="  mx-3 font-semibold ">Todos</div>
                </div>

                <div
                    className={`${
                        opciones === "campo"
                            ? " text-white bg-[#0F80F2]"
                            : "bg-[#0F80F2] bg-opacity-20 text-[#053465] "
                    }  rounded-full p-1 flex justify-center items-center cursor-pointer`}
                    onClick={() => {
                        setOpciones("campo");
                        setpageNumber(1);
                    }}
                >
                    <div className="p-1.5 bg-white rounded-full">
                        <svg
                            className="w-5 h-5"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_106_169)">
                                <path
                                    d="M6.85714 12H9.14286C9.61143 12 10 11.5467 10 11C10 10.4533 9.61143 10 9.14286 10H6.85714C6.38857 10 6 10.4533 6 11C6 11.5467 6.38857 12 6.85714 12Z"
                                    fill="#053465"
                                />
                                <path
                                    d="M24.0646 19.2531H21.2954V16.1814C22.7108 15.7945 23.7569 14.5634 23.7569 13.0979V9.58069C23.7569 7.79862 22.2431 6.35655 20.3723 6.35655C18.5015 6.35655 16.9877 7.79862 16.9877 9.58069V13.0979C16.9877 14.5634 18.0338 15.7945 19.4492 16.1814V19.2531H15.1046C15.1169 19.1593 15.1415 19.0655 15.1415 18.96V11.2338C15.1415 10.6828 14.92 10.1669 14.5138 9.78L9.59077 5.09035C8.75385 4.2931 7.38769 4.2931 6.55077 5.09035L5.30769 6.27448V5.46552C5.30769 4.65655 4.61846 4 3.76923 4H2.53846C1.68923 4 1 4.65655 1 5.46552V18.9483C1 20.0738 1.97231 21 3.15385 21H24.0769C24.5815 21 25 20.6014 25 20.1207C25 19.64 24.5815 19.2414 24.0769 19.2414L24.0646 19.2531ZM18.8338 13.0979V9.58069C18.8338 8.77172 19.5231 8.11517 20.3723 8.11517C21.2215 8.11517 21.9108 8.77172 21.9108 9.58069V13.0979C21.9108 13.9069 21.2215 14.5634 20.3723 14.5634C19.5231 14.5634 18.8338 13.9069 18.8338 13.0979ZM3.44923 5.77035V8.04483L2.83385 8.63103V5.77035H3.44923ZM2.83385 18.96V11.2338C2.83385 11.1517 2.87077 11.0814 2.92 11.0228L7.84308 6.3331C7.84308 6.3331 7.97846 6.25103 8.06462 6.25103C8.15077 6.25103 8.22462 6.27448 8.28615 6.3331L13.2092 11.0228C13.2092 11.0228 13.2954 11.1517 13.2954 11.2338V18.96C13.2954 19.1241 13.16 19.2531 12.9877 19.2531H11.4492V15.4428C11.4492 14.3172 10.4769 13.391 9.29538 13.391H6.83385C5.65231 13.391 4.68 14.3172 4.68 15.4428V19.2531H3.14154C2.96923 19.2531 2.83385 19.1241 2.83385 18.96ZM9.60308 19.2531H6.52615V15.4428C6.52615 15.2786 6.66154 15.1497 6.83385 15.1497H9.29538C9.46769 15.1497 9.60308 15.2786 9.60308 15.4428V19.2531Z"
                                    fill="#053465"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_106_169">
                                    <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0.923065)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className="  mx-3 font-semibold "> Casas de campo</div>
                </div>

                <div
                    className={`${
                        opciones === "playa"
                            ? " text-white bg-[#0F80F2]"
                            : "bg-[#0F80F2] bg-opacity-20 text-[#053465] "
                    }  rounded-full p-1 flex justify-center items-center cursor-pointer`}
                    onClick={() => {
                        setOpciones("playa");
                        setpageNumber(1);
                    }}
                >
                    <div className="p-1.5 bg-white rounded-full">
                        <svg
                            className="w-5 h-5"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.91042 19.997C2.04006 19.997 2.18149 19.9607 2.31113 19.9001C3.72544 19.1609 5.16331 18.7853 6.57761 18.7853H6.61297C7.80334 18.7853 8.47513 19.0397 9.26478 19.3427C10.0898 19.6456 11.0209 19.997 12.4941 19.997C13.9791 19.997 14.9102 19.6456 15.7352 19.3427C16.5249 19.0518 17.2084 18.7853 18.3988 18.7853H18.4342C19.8603 18.7853 21.2981 19.1609 22.7124 19.9001C23.1485 20.1303 23.6789 19.9485 23.9028 19.5002C24.1267 19.0518 23.95 18.5066 23.5139 18.2763C21.8403 17.4039 20.1431 17.0161 18.3988 16.9676C18.2927 16.9676 18.2102 16.9797 18.1042 16.9797V15.15H18.3988C19.5303 15.15 20.4613 14.1927 20.4613 13.0294V7.87945H20.756C21.1449 7.87945 21.4749 7.62498 21.5928 7.24933C21.7106 6.87369 21.581 6.46169 21.2628 6.23146L13.0127 0.172675C12.7063 -0.0575584 12.2937 -0.0575584 11.9873 0.172675L3.73722 6.23146C3.419 6.46169 3.28936 6.87369 3.40722 7.24933C3.52508 7.62498 3.86687 7.87945 4.24401 7.87945H4.53866V13.0294C4.53866 14.1927 5.46974 15.15 6.60118 15.15H6.89583V16.9797C6.78976 16.9797 6.70726 16.9676 6.60118 16.9676H6.55404C4.85688 16.9676 3.14793 17.4039 1.48613 18.2763C1.05005 18.5066 0.873261 19.0518 1.09719 19.5002C1.25041 19.8153 1.56863 19.997 1.88684 19.997H1.91042ZM12.5177 2.02666L18.0099 6.06181H7.02547L12.5177 2.02666ZM6.33011 13.0294V7.87945H18.7052V13.0294C18.7052 13.1991 18.5756 13.3324 18.4106 13.3324H15.7588V10.6059C15.7588 9.44261 14.8277 8.48533 13.6963 8.48533H11.3391C10.2077 8.48533 9.27657 9.44261 9.27657 10.6059V13.3324H6.62475C6.45975 13.3324 6.33011 13.1991 6.33011 13.0294ZM11.0444 13.3324V10.6059C11.0444 10.4363 11.1741 10.303 11.3391 10.303H13.6963C13.8613 10.303 13.9909 10.4363 13.9909 10.6059V13.3324H11.0444ZM8.68728 15.15H16.3481V17.2342C15.9002 17.3554 15.5113 17.4887 15.1459 17.6341C14.3563 17.9249 13.6845 18.1794 12.5177 18.1794C11.3509 18.1794 10.6673 17.9249 9.88944 17.6341C9.52407 17.5008 9.13514 17.3554 8.69906 17.2463V15.15H8.68728Z"
                                fill="#053465"
                            />
                            <path
                                d="M23.5134 22.2975C21.8381 21.4358 20.1392 20.9451 18.3931 21.0049C16.8947 21.0049 15.9627 21.352 15.125 21.6632C14.3346 21.9504 13.6621 22.2018 12.4941 22.2018C11.3261 22.2018 10.6418 21.9504 9.86316 21.6632C9.03731 21.364 8.10527 21.0169 6.60693 21.0049H6.55974C4.86083 21.0049 3.15013 21.4358 1.48662 22.2975C1.0501 22.5249 0.873131 23.0635 1.09729 23.5063C1.25067 23.8175 1.56921 23.997 1.88775 23.997C2.01753 23.997 2.15911 23.9611 2.28888 23.9013C3.70464 23.1712 5.14399 22.8002 6.55974 22.8002H6.59513C7.78672 22.8002 8.45921 23.0515 9.24967 23.3507C10.0755 23.65 11.0076 23.997 12.4823 23.997C13.9688 23.997 14.9009 23.65 15.7267 23.3507C16.5172 23.0635 17.2015 22.8002 18.3931 22.8002H18.4285C19.856 22.8002 21.2954 23.1712 22.7111 23.9013C23.1476 24.1287 23.6785 23.9492 23.9027 23.5063C24.1269 23.0635 23.9499 22.5249 23.5134 22.2975Z"
                                fill="#053465"
                            />
                        </svg>
                    </div>

                    <div className="  mx-3 font-semibold "> Casas de playa</div>
                </div>
            </div>

            <div className="justify-start items-center gap-2  mt-5  xl:px-32 2xl:px-28 px-4 md:hidden flex ">
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={10}
                    autoWidth={false}
                >
                    <SwiperSlide className="!w-auto">
                        <div
                            className={`${
                                opciones === "todos"
                                    ? " text-white bg-[#0F80F2]"
                                    : "bg-[#0F80F2] bg-opacity-20 text-[#053465] "
                            }  rounded-full p-1  justify-center items-center cursor-pointer flex `}
                            onClick={() => {
                                setOpciones("todos");
                                setpageNumber(1);
                            }}
                        >
                            <div className="p-1.5 bg-white rounded-full">
                                <svg
                                    className="w-5 h-5"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20.9225 7.06002L12.9225 2.62002C12.3925 2.32002 11.7525 2.32002 11.2225 2.62002L3.22251 7.06002C2.67251 7.37002 2.32251 7.96002 2.32251 8.59002V11.15C2.32251 11.6 2.55251 12 2.94251 12.23C3.06251 12.3 3.19251 12.34 3.32251 12.37V18C3.32251 20.07 5.00251 21.75 7.07251 21.75H17.0725C19.1425 21.75 20.8225 20.07 20.8225 18V12.37C20.9525 12.34 21.0825 12.3 21.2025 12.23C21.5925 12 21.8225 11.6 21.8225 11.15V8.59002C21.8225 7.96002 21.4825 7.37002 20.9225 7.06002ZM3.82251 8.59002C3.82251 8.50002 3.87251 8.41002 3.95251 8.37002L11.9525 3.92002C12.0325 3.88002 12.1225 3.88002 12.1925 3.92002L20.1925 8.36002C20.2725 8.40002 20.3225 8.49002 20.3225 8.58002V10.72L12.9225 6.61002C12.6525 6.46002 12.3625 6.39002 12.0725 6.39002C11.7825 6.39002 11.4925 6.46002 11.2225 6.61002L3.82251 10.72V8.58002V8.59002ZM10.8225 16.75H13.3225V20.25H10.8225V16.75ZM10.9825 15.25C11.0425 15.14 11.1025 15.03 11.1825 14.94L11.8925 14.23C11.9925 14.13 12.1525 14.13 12.2425 14.23L12.9525 14.94C13.0425 15.03 13.1025 15.14 13.1525 15.25H10.9725H10.9825ZM19.3225 18C19.3225 19.24 18.3125 20.25 17.0725 20.25H14.8225V15.83C14.8225 15.1 14.5325 14.41 14.0125 13.89L13.3025 13.18C12.6225 12.5 11.5125 12.5 10.8325 13.18L10.1225 13.89C9.60251 14.41 9.31251 15.1 9.31251 15.83V20.25H7.06251C5.82251 20.25 4.81251 19.24 4.81251 18V12C4.81251 12 4.79251 11.93 4.79251 11.9L11.9425 7.93002C12.0225 7.89002 12.1125 7.89002 12.1825 7.93002L19.3325 11.9C19.3325 11.9 19.3125 11.97 19.3125 12V18H19.3225Z"
                                        fill="#053465"
                                    />
                                </svg>
                            </div>

                            <div className="  mx-3 font-semibold ">Todos</div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="!w-auto">
                        <div
                            className={`${
                                opciones === "campo"
                                    ? " text-white bg-[#0F80F2]"
                                    : "bg-[#0F80F2] bg-opacity-20 text-[#053465] "
                            }  rounded-full p-1 flex justify-center items-center cursor-pointer`}
                            onClick={() => {
                                setOpciones("campo");
                                setpageNumber(1);
                            }}
                        >
                            <div className="p-1.5 bg-white rounded-full">
                                <svg
                                    className="w-5 h-5"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_106_169)">
                                        <path
                                            d="M6.85714 12H9.14286C9.61143 12 10 11.5467 10 11C10 10.4533 9.61143 10 9.14286 10H6.85714C6.38857 10 6 10.4533 6 11C6 11.5467 6.38857 12 6.85714 12Z"
                                            fill="#053465"
                                        />
                                        <path
                                            d="M24.0646 19.2531H21.2954V16.1814C22.7108 15.7945 23.7569 14.5634 23.7569 13.0979V9.58069C23.7569 7.79862 22.2431 6.35655 20.3723 6.35655C18.5015 6.35655 16.9877 7.79862 16.9877 9.58069V13.0979C16.9877 14.5634 18.0338 15.7945 19.4492 16.1814V19.2531H15.1046C15.1169 19.1593 15.1415 19.0655 15.1415 18.96V11.2338C15.1415 10.6828 14.92 10.1669 14.5138 9.78L9.59077 5.09035C8.75385 4.2931 7.38769 4.2931 6.55077 5.09035L5.30769 6.27448V5.46552C5.30769 4.65655 4.61846 4 3.76923 4H2.53846C1.68923 4 1 4.65655 1 5.46552V18.9483C1 20.0738 1.97231 21 3.15385 21H24.0769C24.5815 21 25 20.6014 25 20.1207C25 19.64 24.5815 19.2414 24.0769 19.2414L24.0646 19.2531ZM18.8338 13.0979V9.58069C18.8338 8.77172 19.5231 8.11517 20.3723 8.11517C21.2215 8.11517 21.9108 8.77172 21.9108 9.58069V13.0979C21.9108 13.9069 21.2215 14.5634 20.3723 14.5634C19.5231 14.5634 18.8338 13.9069 18.8338 13.0979ZM3.44923 5.77035V8.04483L2.83385 8.63103V5.77035H3.44923ZM2.83385 18.96V11.2338C2.83385 11.1517 2.87077 11.0814 2.92 11.0228L7.84308 6.3331C7.84308 6.3331 7.97846 6.25103 8.06462 6.25103C8.15077 6.25103 8.22462 6.27448 8.28615 6.3331L13.2092 11.0228C13.2092 11.0228 13.2954 11.1517 13.2954 11.2338V18.96C13.2954 19.1241 13.16 19.2531 12.9877 19.2531H11.4492V15.4428C11.4492 14.3172 10.4769 13.391 9.29538 13.391H6.83385C5.65231 13.391 4.68 14.3172 4.68 15.4428V19.2531H3.14154C2.96923 19.2531 2.83385 19.1241 2.83385 18.96ZM9.60308 19.2531H6.52615V15.4428C6.52615 15.2786 6.66154 15.1497 6.83385 15.1497H9.29538C9.46769 15.1497 9.60308 15.2786 9.60308 15.4428V19.2531Z"
                                            fill="#053465"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_106_169">
                                            <rect
                                                width="24"
                                                height="24"
                                                fill="white"
                                                transform="translate(0.923065)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div className="  mx-3 font-semibold ">
                                {" "}
                                Casas de campo
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="!w-auto">
                        <div
                            className={`${
                                opciones === "playa"
                                    ? " text-white bg-[#0F80F2]"
                                    : "bg-[#0F80F2] bg-opacity-20 text-[#053465] "
                            }  rounded-full p-1 flex justify-center items-center cursor-pointer`}
                            onClick={() => {
                                setOpciones("playa");
                                setpageNumber(1);
                            }}
                        >
                            <div className="p-1.5 bg-white rounded-full">
                                <svg
                                    className="w-5 h-5"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.91042 19.997C2.04006 19.997 2.18149 19.9607 2.31113 19.9001C3.72544 19.1609 5.16331 18.7853 6.57761 18.7853H6.61297C7.80334 18.7853 8.47513 19.0397 9.26478 19.3427C10.0898 19.6456 11.0209 19.997 12.4941 19.997C13.9791 19.997 14.9102 19.6456 15.7352 19.3427C16.5249 19.0518 17.2084 18.7853 18.3988 18.7853H18.4342C19.8603 18.7853 21.2981 19.1609 22.7124 19.9001C23.1485 20.1303 23.6789 19.9485 23.9028 19.5002C24.1267 19.0518 23.95 18.5066 23.5139 18.2763C21.8403 17.4039 20.1431 17.0161 18.3988 16.9676C18.2927 16.9676 18.2102 16.9797 18.1042 16.9797V15.15H18.3988C19.5303 15.15 20.4613 14.1927 20.4613 13.0294V7.87945H20.756C21.1449 7.87945 21.4749 7.62498 21.5928 7.24933C21.7106 6.87369 21.581 6.46169 21.2628 6.23146L13.0127 0.172675C12.7063 -0.0575584 12.2937 -0.0575584 11.9873 0.172675L3.73722 6.23146C3.419 6.46169 3.28936 6.87369 3.40722 7.24933C3.52508 7.62498 3.86687 7.87945 4.24401 7.87945H4.53866V13.0294C4.53866 14.1927 5.46974 15.15 6.60118 15.15H6.89583V16.9797C6.78976 16.9797 6.70726 16.9676 6.60118 16.9676H6.55404C4.85688 16.9676 3.14793 17.4039 1.48613 18.2763C1.05005 18.5066 0.873261 19.0518 1.09719 19.5002C1.25041 19.8153 1.56863 19.997 1.88684 19.997H1.91042ZM12.5177 2.02666L18.0099 6.06181H7.02547L12.5177 2.02666ZM6.33011 13.0294V7.87945H18.7052V13.0294C18.7052 13.1991 18.5756 13.3324 18.4106 13.3324H15.7588V10.6059C15.7588 9.44261 14.8277 8.48533 13.6963 8.48533H11.3391C10.2077 8.48533 9.27657 9.44261 9.27657 10.6059V13.3324H6.62475C6.45975 13.3324 6.33011 13.1991 6.33011 13.0294ZM11.0444 13.3324V10.6059C11.0444 10.4363 11.1741 10.303 11.3391 10.303H13.6963C13.8613 10.303 13.9909 10.4363 13.9909 10.6059V13.3324H11.0444ZM8.68728 15.15H16.3481V17.2342C15.9002 17.3554 15.5113 17.4887 15.1459 17.6341C14.3563 17.9249 13.6845 18.1794 12.5177 18.1794C11.3509 18.1794 10.6673 17.9249 9.88944 17.6341C9.52407 17.5008 9.13514 17.3554 8.69906 17.2463V15.15H8.68728Z"
                                        fill="#053465"
                                    />
                                    <path
                                        d="M23.5134 22.2975C21.8381 21.4358 20.1392 20.9451 18.3931 21.0049C16.8947 21.0049 15.9627 21.352 15.125 21.6632C14.3346 21.9504 13.6621 22.2018 12.4941 22.2018C11.3261 22.2018 10.6418 21.9504 9.86316 21.6632C9.03731 21.364 8.10527 21.0169 6.60693 21.0049H6.55974C4.86083 21.0049 3.15013 21.4358 1.48662 22.2975C1.0501 22.5249 0.873131 23.0635 1.09729 23.5063C1.25067 23.8175 1.56921 23.997 1.88775 23.997C2.01753 23.997 2.15911 23.9611 2.28888 23.9013C3.70464 23.1712 5.14399 22.8002 6.55974 22.8002H6.59513C7.78672 22.8002 8.45921 23.0515 9.24967 23.3507C10.0755 23.65 11.0076 23.997 12.4823 23.997C13.9688 23.997 14.9009 23.65 15.7267 23.3507C16.5172 23.0635 17.2015 22.8002 18.3931 22.8002H18.4285C19.856 22.8002 21.2954 23.1712 22.7111 23.9013C23.1476 24.1287 23.6785 23.9492 23.9027 23.5063C24.1269 23.0635 23.9499 22.5249 23.5134 22.2975Z"
                                        fill="#053465"
                                    />
                                </svg>
                            </div>

                            <div className="  mx-3 font-semibold ">
                                {" "}
                                Casas de playa
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            {checking ? (
                <div className="justify-center w-full py-10">
                    <Spinner />
                </div>
            ) : (
                allHouses.length > 0 && (
                    <div>
                        <div className="p-4 xl:px-32 2xl:px-28">
                            <ReactSortable
                                disabled={true}
                                onEnd={() => handleDragEnd()}
                                animation={200}
                                delayOnTouchStart={true}
                                delay={2}
                                list={allHouses}
                                setList={setAllHouses}
                                className="w-full  gap-4 mt-4 grid 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1"
                            >
                                {allHouses.map((product, i) => (
                                    <CardSanValentin
                                        tipo={product.tipo}
                                        name={product.id}
                                        key={i}
                                        product={product}
                                        vistaAdministrador={vistaAdministrador}
                                        setRefreshHouses={setRefreshHouses}
                                    />
                                ))}
                            </ReactSortable>
                        </div>

                        <div
                            className={
                                loading
                                    ? "hidden"
                                    : "container flex items-center justify-between p-1 mx-auto"
                            }
                        >
                            <Paginado
                                elementsPerPage={LABELS_PER_PAGE}
                                cantTotalElements={totalHouses}
                                pagination={pagination}
                                actualPage={pageNumber}
                            />
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default AllCards;
