import React from "react";

import Testimonio1 from "../../../img/landing-casa-mario/image-testimonio-1.png";
import Testimonio2 from "../../../img/landing-casa-mario/image-testimonio-2.png";
import Testimonio3 from "../../../img/landing-casa-mario/image-testimonio-3.png";

const Testimonios = [
    {
        id: 1,
        nombre: "María L.",
        testimonio:
            "La mejor experiencia en familia. La casa es espectacular y superó nuestras expectativas. ¡Volveremos pronto!",
        imagen: Testimonio3,
    },
    {
        id: 2,
        nombre: "Luis R.",
        testimonio:
            "Perfecta para desconectar del estrés de la ciudad. La atención fue excelente y la casa tiene todo lo necesario.",
        imagen: Testimonio2,
    },
    {
        id: 3,
        nombre: "Carmen P.",
        testimonio:
            "La piscina y las áreas verdes son increíbles. Ideal para grupos grandes. 100% recomendada.",
        imagen: Testimonio1,
    },
];

const Testimonials = () => {
    return (
        <div className="flex flex-col md:flex-row justify-center items-center w-4/5 mx-auto gap-3 mt-5">
            {Testimonios.map((testimonio) => (
                <div className="bg-white rounded-lg py-5 px-5 flex-col justify-center items-center w-full">
                    <img
                        className="md:w-12 md:h-12 w-10 h-10 mx-auto"
                        src={testimonio.imagen}
                        alt="avatar"
                    />

                    <div className="text-center text-[#053465] font-bold md:text-lg text-base mt-2">
                        {testimonio.nombre}
                    </div>

                    <div className="text-center text-[#0F80F2] md:text-sm text-xs mt-2">
                        "{testimonio.testimonio}"
                    </div>
                </div>
            ))}{" "}
        </div>
    );
};

export default Testimonials;
