import React, { useState } from "react";
import Footer from "../../Footer";
import Benefits from "./Benefits";
import Images from "./Images";
import { Banner } from "./Banner";
import Testimonials from "./Testimonials";
import Reservs from "./Reservs";

const LandingCasaMario = () => {
    const [vistaAdministrador, setVistaAdministrador] = useState(false);
    const [refreshHouses, setRefreshHouses] = useState(false);

    return (
        <div className="bg-[#F4F1EA]">
            <div className="">
                <Banner />
            </div>
            <div>
                <Benefits />
            </div>

            <div>
                <Images />
            </div>

            <div>
                <Testimonials />
            </div>

            <div>
                <Reservs />
            </div>
            <Footer messageWhatsapp={""} />
        </div>
    );
};

export default LandingCasaMario;
