import getTotalReservaInicial from "../../detailedScreens/getTotalReservaInicial";
import priceToMonedaElegida from "../../helpers/priceToMonedaElegida";
const moneda_elegida = localStorage.getItem("moneda");
export const getGarantiaTotal = (product, cantDays, selectedDays) => {
    let totalWarranty = product.garantia;

    if (product.tipo_garantia === "Valor") {
        totalWarranty = priceToMonedaElegida(
            totalWarranty,
            product.tipo_moneda_garantia
        );
    } else if (product.tipo_garantia === "Porcentaje") {
        totalWarranty = Math.ceil(
            Number(
                getTotalReservaInicial(cantDays, selectedDays, product) *
                    (totalWarranty / 100)
            )
        );
    } else if (product.tipo_garantia === "Por noche") {
        totalWarranty = priceToMonedaElegida(
            Number(product.garantia * cantDays),
            product.tipo_moneda_garantia
        );
    } else if (product.tipo_garantia === "Por día") {
        totalWarranty = priceToMonedaElegida(
            Number(product.garantia * cantDays),
            product.tipo_moneda_garantia
        );
    } else if (product.tipo_garantia === "Rango de días") {
        if (product.rango_dias_garantias) {
            const selected_rango = product.rango_dias_garantias.filter(
                (range) => {
                    if (
                        cantDays >= range.dia_inicio &&
                        cantDays <= range.dia_final
                    ) {
                        return range;
                    }
                }
            );
            if (selected_rango.length > 0) {
                totalWarranty = priceToMonedaElegida(
                    selected_rango[0].precio,
                    product.tipo_moneda_garantia
                );
            } else {
                totalWarranty = 0;
            }
        } else {
            totalWarranty = 0;
        }
    }

    return `${totalWarranty} ${moneda_elegida}`;
};
