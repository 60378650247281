import React from "react";

import logo_cuotealo from "../../img/Home/logo_cuotealo.png";
import logo_bcp from "../../img/Home/logo_bcp.png";

const BannerCuotealo = () => {
    return (
        <div className="w-full p-2 bg-gradient-to-r from-[#053465] to-[#0A69CB] flex justify-center items-start text-white md818:gap-2 gap-1">
            <div className="xl:text-2xl 2xl:text-3xl lg:text-xl md818:text-lg xs400:text-[10px] text-[9px] ">
                ¡PAGA EN CUOTAS EL ALQUILER DE TU CASA! <b>PÁGALO CON</b>
            </div>
            <img
                src={logo_cuotealo}
                alt="logo_cuotealo"
                className=" xl:w-32 2xl:w-40 lg:w-28 md818:w-24 w-12 h-auto mt-0.5 md818:mt-0"
            />
            <img
                src={logo_bcp}
                alt="logo_bcp"
                className="md818:w-auto w-10 h-auto lg:mt-2 2xl:mt-3 mt-1.5 "
            />
        </div>
    );
};

export default BannerCuotealo;
