import getPriceAditionalService from "../helpers/getTotalPrice/getPriceAditionalService";
import getTotalReservaSimple from "./getTotalReservaSimple";

const getTotalReservaFinal = (
    cantDays,
    selectedDays,
    currentProduct,
    aditionalServices,
    es_empleado_empresa,
    getGarantiaTotal,
    membresia,
    isCurrentProduct = false
) => {
    let totalReservaFinal = getTotalReservaSimple(
        cantDays,
        selectedDays,
        currentProduct,
        es_empleado_empresa,
        membresia
    );

    if (isCurrentProduct) {
        totalReservaFinal += Number(
            Number(
                getGarantiaTotal(currentProduct, cantDays, selectedDays).split(
                    " "
                )[0]
            ).toFixed(2)
        );
    } else {
        totalReservaFinal += Number(
            Number(getGarantiaTotal().split(" ")[0]).toFixed(2)
        );
    }

    aditionalServices.forEach((service) => {
        totalReservaFinal += Number(
            getPriceAditionalService(
                service,
                cantDays,
                selectedDays,
                currentProduct
            )
        );
    });

    return Number(totalReservaFinal.toFixed(2));
};

export default getTotalReservaFinal;
