import axios from "axios";
export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const CREATE_LABEL = "CREATE_LABEL";
export const RESET_CREATE_LABEL = "RESET_CREATE_LABEL";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const ONE_BLOGS = "ONE_BLOGS";
export const RESET_ONE_BLOG = "RESET_ONE_BLOG";
export const SEARCH_BLOGS = "SEARCH_BLOGS";
export const ENABLE_BLOGS = "ENABLE_BLOGS";
export const RESET_ENEBLE_BLOG = "RESET_ENEBLE_BLOG";

export const editLabel = (id, payload) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/etiqueta/actualizar/${id}`,
            payload,
            { headers }
        );

        return dispatch({
            type: CREATE_LABEL,
            payload: json.data.message,
        });
    };
};

export const getAllblogs = (pageNumber = 1, enabled = "Si", regpagina = 8) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/entrada/all/${pageNumber}/${enabled}/${regpagina}`,
            { headers }
        );
        console.log("json", json.data);
        return dispatch({
            type: GET_ALL_BLOGS,
            payload: json.data,
        });
    };
};

export const searchBlogsByName = (enabled, input) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/entrada/all/1/${enabled}/?titulo=${input}`,
            { headers }
        );

        return dispatch({
            type: SEARCH_BLOGS,
            payload: json.data,
        });
    };
};

export const resetCreateLabel = () => {
    return {
        type: RESET_CREATE_LABEL,
    };
};
export const resetEnebleBlog = () => {
    return {
        type: RESET_ENEBLE_BLOG,
    };
};

export const deleteBlogs = (id) => {
    console.log(id);
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/entrada/eliminar/${id}`,
            { headers }
        );
        console.log(json.data);
        return dispatch({
            type: ENABLE_BLOGS,
            payload: json.data.message,
        });
    };
};
export const enableBlogs = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.put(
            `${process.env.REACT_APP_PROXY}/entrada/restaurar/${id}`,
            {},
            { headers }
        );

        return dispatch({
            type: ENABLE_BLOGS,
            payload: json.data.message,
        });
    };
};

export const oneBlogs = (id) => {
    return async function (dispatch) {
        const token = localStorage.getItem("token");
        const headers = {
            authorization: token,
        };
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/entrada/detalle/${id}`,
            { headers }
        );

        return dispatch({
            type: ONE_BLOGS,
            payload: json.data,
        });
    };
};
export const resetOnBlog = () => {
    return async function (dispatch) {
        return dispatch({
            type: RESET_ONE_BLOG,
            payload: [],
        });
    };
};
