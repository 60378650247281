import React from "react";
import NavBar from "../NavBar";

import { useNavigate } from "react-router-dom";
import medalla_bronce from "../../img/medalla-bronce.png";
import medalla_plata from "../../img/medalla-plata.png";
import medalla_oro from "../../img/medalla-oro.png";

import { Helmet } from "react-helmet";

import Footer from "../Footer";
import useScrollTo from "../../hooks/useScrollToTop";
import NavBarHome from "../NavBarHome";

export const Loyalty = () => {
    const navigate = useNavigate();

    useScrollTo();
    return (
        <div>
            <NavBarHome explorer={false} />
            <Helmet>
                <link
                    rel="canonical"
                    href="https://example.com/dresses/green-dresses"
                />
                <meta
                    name="description"
                    content={`Blue Corner, Alquileres, Embarcaciones, Alojamientos`}
                />
            </Helmet>

            <div className="flex flex-col mx-auto bg-blue-100 sm:w-10/12">
                <div className="h-400 bg-blue-600">
                    <h1 className="text-white text-center text-7xl">
                        programa de lealtad de clientes
                    </h1>
                    <p className="my-20 mx-20 text-2xl text-white">
                        Reserva con nosotros y obten descuentos segun la
                        cantidad de noches que reserves con nosotros!. Estos
                        descuentos lo tendras a partir de tu segunda reserva!
                    </p>
                </div>

                <div className="flex flex-col items-center justify-center flex-1 text-3xl sm:flex-row lg:text-4xl">
                    <div className="flex flex-col items-center justify-center w-3/4 h-64 gap-2 m-4 text-center bg-blue-500 rounded-lg xs:w-1/2 sm:m-12">
                        {/* Contenido de la columna izquierda */}
                        <p className="font-bold text-white ">Bronce</p>

                        <p className="font-bold text-white ">
                            1 a 3 noches alquiladas
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center w-3/4 h-64 gap-2 m-4 rounded-lg xs:w-1/2 sm:m-12 bg-slate-50">
                        {/* Contenido de la columna derecha */}
                        <p className="font-bold text-blue-400 ">-10 %</p>
                        <div>
                            <img
                                src={medalla_bronce}
                                alt="No se encontro imagen"
                                height={200}
                                width={200}
                            />
                        </div>
                    </div>
                    <hr className="w-11/12 mx-auto border-[#053465] my-1 sm:hidden" />
                </div>
                <div className="flex flex-col items-center justify-center flex-1 text-3xl sm:flex-row lg:text-4xl">
                    <div className="flex flex-col items-center justify-center w-3/4 h-64 gap-2 m-4 text-center bg-blue-500 rounded-lg xs:w-1/2 sm:m-12">
                        {/* Contenido de la columna izquierda */}
                        <p className="font-bold text-white ">Plateado</p>

                        <p className="font-bold text-white ">
                            4 a 9 noches alquiladas
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center w-3/4 h-64 gap-2 m-4 rounded-lg xs:w-1/2 sm:m-12 bg-slate-50">
                        {/* Contenido de la columna derecha */}
                        <p className="font-bold text-blue-400 ">-12 %</p>
                        <img
                            src={medalla_plata}
                            alt="No se encontro imagen"
                            height={200}
                            width={200}
                        />
                    </div>
                    <hr className="w-11/12 mx-auto border-[#053465] my-1 sm:hidden" />
                </div>

                <div className="flex flex-col items-center justify-center flex-1 text-3xl sm:flex-row lg:text-4xl">
                    <div className="flex flex-col items-center justify-center w-3/4 h-64 gap-2 m-4 text-center bg-blue-500 rounded-lg xs:w-1/2 sm:m-12">
                        {/* Contenido de la columna izquierda */}
                        <p className="font-bold text-white ">Dorado</p>
                        <p className="font-bold text-white ">
                            10 noches alquiladas o mas
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center w-3/4 h-64 gap-2 m-4 rounded-lg xs:w-1/2 sm:m-12 bg-slate-50">
                        {/* Contenido de la columna derecha */}
                        <p className="font-bold text-blue-400 ">-15 %</p>
                        <img
                            src={medalla_oro}
                            alt="No se encontro imagen"
                            height={200}
                            width={200}
                        />
                    </div>
                    <hr className="w-11/12 mx-auto border-[#053465] my-1 sm:hidden" />
                </div>

                <div className="flex flex-col mx-auto my-20 bg-blue-100 sm:w-10/12">
                    <table className="min-w-full border text-center text-sm font-light border-blue-500">
                        <thead className="border-b font-medium border-blue-500">
                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500"></td>
                                <td
                                    colSpan="3"
                                    className="border-r px-6 py-4 border-blue-500 text-base text-blue-600"
                                >
                                    Tipos Categorias
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500 text-base text-blue-600">
                                    Requerimiento
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500 ">
                                    Bronze
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Plateado
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Dorado
                                </td>
                            </tr>
                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500 text-base text-blue-600">
                                    Noches Alquiladas / año
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500 ">
                                    1 a 3
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    4 a 9
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    10 +
                                </td>
                            </tr>
                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500"></td>
                                <td className="border-r px-6 py-4 border-blue-500"></td>
                                <td className="border-r px-6 py-4 border-blue-500"></td>
                                <td className="border-r px-6 py-4 border-blue-500"></td>
                            </tr>

                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500 text-base text-blue-600">
                                    Beneficios
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500"></td>
                                <td className="border-r px-6 py-4 border-blue-500"></td>
                                <td className="border-r px-6 py-4 border-blue-500"></td>
                            </tr>

                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500 text-base text-blue-600">
                                    Descuentos Exclusivos
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    10%
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    12%
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    15%
                                </td>
                            </tr>

                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500 text-base text-blue-600">
                                    Prioridad Promociones Growth
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Si
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Si
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Si
                                </td>
                            </tr>

                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500 text-base text-blue-600">
                                    Early Check In
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    No
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Si
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Si
                                </td>
                            </tr>
                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500 text-base text-blue-600">
                                    Early Check Out
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    No
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Si
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Si
                                </td>
                            </tr>

                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500 text-base text-blue-600">
                                    Regalo de Bienvenida
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    No
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    No
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Si
                                </td>
                            </tr>
                            <tr>
                                <td className="border-r px-6 py-4 border-blue-500 text-base text-blue-600">
                                    Flexibilidad de plazos de pago
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    No
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    No
                                </td>
                                <td className="border-r px-6 py-4 border-blue-500">
                                    Si
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <Footer />
        </div>
    );
};
