import React from "react";
import NavBar from "../NavBar";
import quienessomos1 from "../../img/quienesomos1.jpg";
import barco from "../../img/barco-quienes-somos.jpg";
import { Link, useNavigate } from "react-router-dom";
import casasBlue from "../../img/Partner/casas-blue.png";
import embarcacionesBlue from "../../img/Partner/embarcaciones-blue.png";
import actividadesBlue from "../../img/Partner/actividades-blue.png";
import corporativo from "../../img/Partner/corporativo-blue.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Helmet } from "react-helmet";

//equipo
import andres from "../../img/equipo/andres-241x300.jpg";
import diego from "../../img/equipo/diego-241x300.jpg";
import ylian from "../../img/equipo/yilan-241x300.jpg";
import ignacio from "../../img/equipo/ignacio-241x300.jpg";
import betty from "../../img/equipo/betty-241x300.jpg";
import martin from "../../img/equipo/martin-241x300.jpg";
import giancarlo from "../../img/equipo/giancarlo-241x300.jpg";
import Footer from "../Footer";
import useScrollTo from "../../hooks/useScrollToTop";
import NavBarHome from "../NavBarHome";

const testimonials = [
    {
        id: 0,
        text: "Compré una clase de surf para 2, fui con una amiga y la pasamos súper bien. Nos gusto mucho la forma en que se dio la clase, nos enseñaron los tips básicos del surf y también nos dieron mucha seguridad en el mar. Es una experiencia muy recomendable y si eres amante del mar la vas a pasar muy bien.",
        clientName: "Adriana Rios",
        clientType: "CLIENTE DE ACTIVIDADES",
    },
    {
        id: 1,
        text: "¡Agradecido con la atención de Valerie de Blue Corner, y la amabilidad de los propietarios, el departamento muy lindo, tal cual como están las fotos, me dieron todas las facilidades para ir a verlo y conocerlo, la atención fue super buena y personalizada, los recomiendo",
        clientName: "Julio Echeandía",
        clientType: "CLIENTE DE CASAS",
    },
    {
        id: 2,
        text: "Mi experiencia con Blue Corner fue maravillosa! Nos brindaron un servicio de calidad y muy cordial este verano a mis amigos y a mi en alta mar. Difícil de olvidar! Lo volveríamos a repetir muchas veces, 100% recomendado!  Que vengan más éxitos y espero volverlos a ver muy pronto",
        clientName: "Silvia Zegarra",
        clientType: "CLIENTE DE EMBARCACIONES",
    },
];

export const QuienesSomos = () => {
    const navigate = useNavigate();
    const personal = [
        {
            imagen: andres,
            nombre: "Andres Canessa",
            puesto: "CEO y Socio",
        },
        {
            imagen: diego,
            nombre: "Diego Perea",
            puesto: "Socio",
        },
        {
            imagen: ylian,
            nombre: "Ylian Alvarado",
            puesto: "Producción",
        },
        {
            imagen: ignacio,
            nombre: "Ignacio Villa",
            puesto: "Product Manager",
        },
        {
            imagen: betty,
            nombre: "Betty Zapata",
            puesto: "Legal",
        },
        {
            imagen: martin,
            nombre: "Martin Ortiz",
            puesto: "Finanzas",
        },
        {
            imagen: giancarlo,
            nombre: "Giancarlo Lopez",
            puesto: "Mentor",
        },
    ];
    useScrollTo();
    return (
        <div>
            <NavBarHome explorer={false} />
            <Helmet>
                <link
                    rel="canonical"
                    href="https://example.com/dresses/green-dresses"
                />
                <meta
                    name="description"
                    content={`Blue Corner, Alquileres, Embarcaciones, Alojamientos`}
                />
            </Helmet>
            <div className="flex flex-col  select-none ">
                <div
                    className="w-full bg-no-repeat bg-cover max-h-60"
                    style={{
                        backgroundImage: `url(${quienessomos1})`,
                        filter: "brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
                    }}
                >
                    <div
                        style={{
                            backgroundImage:
                                "linear-gradient(180deg, #053465 0%, rgba(5, 52, 101, 0) 100%)",
                        }}
                        className="pt-12 "
                    >
                        <div className="pt-8 mx-auto text-2xl font-normal text-center text-white ">
                            <span
                                style={{
                                    letterSpacing: "10px",
                                }}
                                className="font-sans font-bold tracking-widest text-center uppercase "
                            >
                                Nacimos en Lima, Peru
                            </span>
                        </div>
                    </div>{" "}
                    <div className="w-full h-24 text-center ">
                        <span className="mt-5 text-xl italic font-semibold text-white "></span>
                    </div>
                </div>
            </div>

            <div className="grid sm:grid-cols-[30%_70%] grid-cols-1 px-12 pt-20 text-[#053465]">
                <div>
                    <p
                        style={{
                            letterSpacing: "3px",
                        }}
                        className="text-sm font-bold uppercase "
                    >
                        Sobre nosotros
                    </p>
                    <hr className="my-3 border-[#053465] w-6/12"></hr>
                    <p className="font-bold">
                        Somos una plataforma web que tiene como propósito
                        CONECTAR a las personas con operadores de actividades y
                        experiencias cerca a la naturaleza.
                    </p>
                </div>
                <div className="mt-4 sm:px-16 sm:pb-14 sm:mt-0">
                    <p className="mb-4">
                        BlueCorner nace con el objetivo de ayudar a los
                        operadores turísticos / propietarios a crecer y
                        formalizar su negocio con herramientas de gestión
                        digital para desarrollar el turismo en el Perú y la
                        región.
                    </p>
                </div>
            </div>
            <div className="flex flex-col p-12 sm:flex-row sm:justify-around">
                <div>
                    <div className="mt-5 text-center ">
                        <span className="text-[#C58B68] font-bold text-3xl">
                            +
                        </span>
                        <span className="text-[#C58B68] font-bold text-3xl">
                            300
                        </span>
                    </div>
                    <div className="text-center ">
                        <p className="text-[#053465] text-sm">
                            Alquileres ajustados a tu medida
                        </p>
                    </div>
                </div>
                <div>
                    <div className="mt-5 text-center ">
                        <span className="text-[#C58B68] font-bold text-3xl">
                            +
                        </span>
                        <span className="text-[#C58B68] font-bold text-3xl">
                            135
                        </span>
                    </div>
                    <div className="text-center ">
                        <p className="text-[#053465] text-sm">
                            Propiedades en alquiler
                        </p>
                    </div>
                </div>
                <div>
                    <div className="mt-5 text-center ">
                        <span className="text-[#C58B68] font-bold text-3xl">
                            +
                        </span>
                        <span className="text-[#C58B68] font-bold text-3xl">
                            180
                        </span>
                    </div>
                    <div className="text-center ">
                        <p className="text-[#053465] text-sm">
                            Experiencias al rededor del Perú
                        </p>
                    </div>
                </div>
                <div>
                    <div className="mt-5 text-center ">
                        <span className="text-[#C58B68] font-bold text-3xl">
                            +
                        </span>
                        <span className="text-[#C58B68] font-bold text-3xl">
                            50
                        </span>
                    </div>
                    <div className="text-center ">
                        <p className="text-[#053465] text-sm">
                            Modelos y tipos de embarcaciones
                        </p>
                    </div>
                </div>
                <div>
                    <div className="mt-5 text-center ">
                        <span className="text-[#C58B68] font-bold text-3xl">
                            +
                        </span>
                        <span className="text-[#C58B68] font-bold text-3xl">
                            30
                        </span>
                    </div>
                    <div className="text-center ">
                        <p className="text-[#053465] text-sm">
                            Modelos de paquetes corporativos
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid sm:grid-cols-[30%_70%] grid-cols-1 px-12 pt-20 text-[#053465] bg-[#F4F2F1]">
                <div>
                    <p
                        style={{
                            letterSpacing: "3px",
                        }}
                        className="text-sm font-bold uppercase "
                    >
                        UNIDADES DE NEGOCIO
                    </p>
                    <hr className="my-3 border-[#053465] w-6/12"></hr>
                </div>
                <div className="mt-4 sm:px-16 sm:pb-14 sm:mt-0">
                    <p className="mb-4">
                        Buscamos centralizar la oferta de experiencias y
                        actividades alrededor del mar con el fin de promover a
                        las personas estar mas conectadas con la naturaleza
                    </p>
                </div>
            </div>
            <div className="bg-[#F4F2F1] pb-4">
                <div className="grid grid-cols-2 text-center lg:flex lg:justify-around ">
                    <div className="flex flex-col max-w-[242px] select-none md:mx-auto mt-10 md:mt-0">
                        <Link to="/aliado-para-casas">
                            <img
                                src={casasBlue}
                                alt={casasBlue}
                                className="cursor-pointer w-[170px] mx-auto "
                            />
                        </Link>
                        <h1 className="text-xl font-semibold text-[#053465]">
                            Casas
                        </h1>
                        <span className="pt-1 text-[#053465]">
                            Alquila o compra casas de playa o campo
                        </span>
                        <Link to="/aliado-para-casas">
                            <div className="justify-center items-center  flex w-8 h-8  bg-[#C58B68] rounded-full mx-auto mt-7 hover:bg-blue-600 cursor-pointer ">
                                <span className="flex items-center text-3xl font-extrabold text-white align-middle">
                                    <p className="h-[2.6rem]">+</p>
                                </span>
                            </div>
                        </Link>
                        <Link to="/aliado-para-casas" className="mt-4">
                            <span className="text-[#C58B68] font-bold mt-4 text-sm hover:text-blue-600 cursor-pointer">
                                DESCUBRE MÁS
                            </span>
                        </Link>
                    </div>
                    <div className="flex flex-col max-w-[242px] mt-10 select-none md:mx-auto md:mt-0">
                        <Link to="/aliado-para-embarcaciones">
                            <img
                                src={embarcacionesBlue}
                                alt={embarcacionesBlue}
                                className="cursor-pointer w-[170px] mx-auto"
                            />
                        </Link>
                        <h1 className="text-xl font-semibold text-[#053465]">
                            Embarcaciones
                        </h1>
                        <span className="pt-1 text-[#053465]">
                            Alquila o compra embarcaciones
                        </span>
                        <Link to="/aliado-para-embarcaciones">
                            <div className="justify-center items-center  flex w-8 h-8  bg-[#C58B68] rounded-full mx-auto mt-14 hover:bg-blue-600 cursor-pointer ">
                                <span className="flex items-center text-3xl font-extrabold text-white align-middle">
                                    <p className="h-[2.6rem]">+</p>
                                </span>
                            </div>
                        </Link>
                        <Link to="/aliado-para-embarcaciones" className="mt-4">
                            <span className="text-[#C58B68] font-bold mt-4 text-sm hover:text-blue-600 cursor-pointer">
                                DESCUBRE MÁS
                            </span>
                        </Link>
                    </div>{" "}
                    <div className="flex flex-col max-w-[242px] mt-10 select-none md:mx-auto md:mt-0">
                        <Link to="/aliado-para-actividades">
                            <img
                                src={actividadesBlue}
                                alt={actividadesBlue}
                                className="cursor-pointer w-[170px] mx-auto"
                            />
                        </Link>
                        <h1 className="text-xl font-semibold text-[#053465]">
                            Actividades
                        </h1>
                        <span className="pt-1 text-[#053465]">
                            Reserva tu próxima aventura ya sea en el mar o en la
                            playa
                        </span>
                        <Link to="/aliado-para-actividades">
                            <div className="justify-center items-center  flex w-8 h-8  bg-[#C58B68] rounded-full mx-auto mt-7 hover:bg-blue-600 cursor-pointer ">
                                <span className="flex items-center text-3xl font-extrabold text-white align-middle">
                                    <p className="h-[2.6rem]">+</p>
                                </span>
                            </div>
                        </Link>
                        <Link to="/aliado-para-actividades" className="mt-4">
                            <span className="text-[#C58B68] font-bold mt-4 text-sm hover:text-blue-600 cursor-pointer">
                                DESCUBRE MÁS
                            </span>
                        </Link>
                    </div>
                    <div className="flex flex-col max-w-[242px] mt-10 select-none md:mx-auto md:mt-0">
                        <Link to="/corporativo">
                            <img
                                src={corporativo}
                                alt={corporativo}
                                className="cursor-pointer w-[170px] mx-auto"
                            />
                        </Link>
                        <h1 className="text-xl font-semibold text-[#053465]">
                            Corporativo
                        </h1>
                        <span className="pt-1 text-[#053465]">
                            Ten un evento diferente con nuestros paquetes
                            corporativos
                        </span>
                        <Link to="/corporativo">
                            <div className="justify-center items-center  flex w-8 h-8  bg-[#C58B68] rounded-full mx-auto mt-7 hover:bg-blue-600 cursor-pointer ">
                                <span className="flex items-center text-3xl font-extrabold text-white align-middle">
                                    <p className="h-[2.6rem]">+</p>
                                </span>
                            </div>
                        </Link>
                        <Link to="/corporativo" className="mt-4">
                            <span className="text-[#C58B68] font-bold mt-4 text-sm hover:text-blue-600 cursor-pointer">
                                DESCUBRE MÁS
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="grid lg1170:grid-cols-[30%_70%] grid-cols-1 pl-12 pt-20 text-[#053465] mb-6">
                <div>
                    <p
                        style={{
                            letterSpacing: "3px",
                        }}
                        className="text-sm font-bold uppercase "
                    >
                        NUESTRA ESENCIA
                    </p>
                    <hr className="my-3 border-[#053465] w-6/12"></hr>
                    <p className="font-bold">Compartimos la misma pasión</p>
                    <br></br>

                    <p className="">
                        Somos una plataforma online de experiencias alrededor de
                        la naturaleza, especialmente el mar.
                    </p>

                    <br></br>
                    <p className="">
                        Nuestro propósito es ayudar a operadores turísticos a
                        visibilizarse y crear conexiones entre los operadores
                        turísticos, empresas y público en general con nuestra
                        pasión por el mar y la naturaleza; juntos crecer en una
                        era digital y de experiencias. Esto lo hacemos mediante:
                    </p>
                    <br></br>
                    <ul className="ml-10 list-disc text-[#053465] tracking-wide">
                        <li>
                            Herramientas fáciles de utilizar para operadores y
                            clientes
                        </li>
                    </ul>
                    <ul className="ml-10 list-disc text-[#053465] tracking-wide">
                        <li>
                            Variedad de operadores para garantizar una oferta
                            diferente de experiencias
                        </li>
                    </ul>
                    <ul className="ml-10 list-disc text-[#053465] tracking-wide">
                        <li>
                            Apertura para acercar experiencias a más audiencias
                        </li>
                    </ul>
                    <ul className="ml-10 list-disc text-[#053465] tracking-wide">
                        <li>Innovación constante en nuestros servicios</li>
                    </ul>
                    <ul className="ml-10 list-disc text-[#053465] tracking-wide">
                        <li>
                            Respeto al ecosistema y a la cultura local de
                            cualquier país
                        </li>
                    </ul>
                    <ul className="ml-10 list-disc text-[#053465] tracking-wide">
                        <li>Transmitir la pasión por la naturaleza</li>
                    </ul>
                </div>

                <div
                    className="hidden pl-8 mr-4 bg-cover lg1170:block "
                    // style={{
                    //     backgroundImage: `url(${barco})`,
                    // }}
                >
                    <img
                        src={barco}
                        alt={barco}
                        className="w-full max-h-[670px] h-auto"
                    />
                </div>
            </div>
            <div className="bg-[#053465] px-12">
                <p
                    style={{
                        letterSpacing: "3px",
                    }}
                    className="font-bold text-center text-white uppercase pt-28"
                >
                    Testimonios
                </p>
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={20}
                    slidesPerView={1}
                    rewind={true}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                >
                    {testimonials.map((testimonial) => (
                        <SwiperSlide
                            key={testimonial.id}
                            className="flex flex-col p-8  rounded-xl h-3/5 min-h-[180px]   cursor-pointer md:min-h-[300px] text-center"
                        >
                            <p className="flex mx-auto text-lg text-white md:mt-10 md:w-3/5">{`"${testimonial.text}"`}</p>
                            <span className="mt-5 text-xl font-bold text-white md:mt-14">
                                {testimonial.clientName}
                            </span>
                            <span className="font-semibold text-[#C58B68] text-md">
                                {testimonial.clientType}
                            </span>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="flex justify-center pb-10">
                    <button
                        onClick={() => {
                            navigate("/aliados");
                        }}
                    >
                        <span className="flex p-2 mr-2 font-semibold text-white bg-blue-500 rounded-lg min-w-max md:p-3">
                            Conviertete en aliado
                        </span>
                    </button>
                </div>
            </div>
            <div className="grid lg1170:grid-cols-[30%_70%] grid-cols-1 px-12 pb-5 pt-28 text-[#053465] mb-6">
                <div>
                    <p
                        style={{
                            letterSpacing: "3px",
                        }}
                        className="text-sm font-bold uppercase "
                    >
                        NUESTRO EQUIPO
                    </p>
                    <hr className="my-3 border-[#053465] w-6/12"></hr>
                    <p className="font-bold">
                        Un equipo que comparte la misma pasión
                    </p>
                    <br></br>
                </div>

                <div
                    className="hidden pl-8 mr-4 bg-cover lg1170:block "
                    // style={{
                    //     backgroundImage: `url(${barco})`,
                    // }}
                ></div>
            </div>
            <div className="mb-10">
                {/* //1 */}
                <div className="grid grid-cols-2 md723:grid-cols-4">
                    {personal.map((persona, i) => {
                        return (
                            <div
                                key={i}
                                className="flex flex-col max-w-[242px] mt-20 select-none md:mx-auto  text-center"
                            >
                                <img
                                    src={persona.imagen}
                                    alt={persona.imagen}
                                    className="rounded-full w-[85px]  mx-auto"
                                />

                                <h1 className="text-2xl font-semibold text-[#053465]">
                                    {persona.nombre}
                                </h1>
                                <span className="pt-1 text-[#C58B68]">
                                    {persona.puesto}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Footer />
        </div>
    );
};
