import React, { useState } from "react";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import AllCards from "./AllCards";
import ExplorerVerano from "./ExplorerVerano";
import NavBarHome from "../../NavBarHome";

const PromoVerano = () => {
    const [vistaAdministrador, setVistaAdministrador] = useState(false);
    const [refreshHouses, setRefreshHouses] = useState(false);

    return (
        <div>
            <NavBarHome explorer={false} />
            <ExplorerVerano
                vistaAdministrador={vistaAdministrador}
                setVistaAdministrador={setVistaAdministrador}
                setRefreshHouses={setRefreshHouses}
            />
            <AllCards
                vistaAdministrador={vistaAdministrador}
                refreshHouses={refreshHouses}
                setRefreshHouses={setRefreshHouses}
            />
            <Footer />
        </div>
    );
};

export default PromoVerano;
