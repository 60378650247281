import React, { useState, useEffect, lazy, Suspense } from "react";
import axios from "axios";

import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

// import FacebookLogin from "./../../helpers/LogeoFacebook";

import { loginUser } from "../../../redux/actions/authActions";
import { useNavigate } from "react-router";
import ModalOlvidadoContraseña from "../../contraseña/ModalOlvidadoContraseña";
// import { GoogleLogin } from "@react-oauth/google";
import UserRegister from "../../myAccount/UserRegister";
import colores from "../../helpers/colores";
import encabezado from "../../../img/Encabezado.png";

import imagenInicio from "../../../img/imagen-casa-modal-inicio-sesion.jpeg";
import imagenRegistro from "../../../img/imagen-casa-modal-registro.png";

const FacebookLogin = lazy(() => import("./../../helpers/LogeoFacebook"));
const GoogleLogin = lazy(() => import("./../../helpers/LogeoGoogle.js"));

const ModalLogeo = ({
    modalLogeo,
    setModalLogeo,
    tipo,
    id_pedido,
    setLoadingFavorito,
    id_cliente,
    admin = false,
}) => {
    const [input, setInput] = useState({
        new_password: "",
    });

    const emailStorage = localStorage.getItem("email");
    const [olvido, setOlvido] = useState(false);
    const [registro, setRegistro] = useState(admin);
    const [Acceder, setAcceder] = useState("Ingresar");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPass, setShowPass] = useState(false);
    const [bandera, setBandera] = useState(true);

    const [banderaReseña, setBanderaReseña] = useState(true);
    const [login, setLogin] = useState({
        correo: emailStorage || "",
        password: "",
        rememberme: false,
        nombre: "",
        apellidos: "",
    });
    const authUser = useSelector((state) => state.authReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const googleDatos = async (data) => {
        const credencialToken = jwt_decode(data.credential);

        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}/autenticacion/loginGoogle`,
            {
                email: credencialToken.email,
            }
        );

        if (json.data.message === "logeo exitoso") {
            dispatch(loginUser(null, null, json.data));
        } else {
            Swal.fire({
                title: "Error",
                text: `${json.data.title}`,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            setLoading(false);
            setAcceder("Ingresar");
        }
    };

    const googleDatosRegister = async (data) => {
        const credencialToken = jwt_decode(data.credential);

        const json = await axios.post(
            `${process.env.REACT_APP_PROXY}${"/cliente/"}`,
            {
                correo: credencialToken.email,
                nombre: credencialToken.given_name,
                apellido_paterno: credencialToken.family_name,
                tipo_usuario: 5,
                tipo_logueo: "Google",
                password: "",
                apellido_materno: "",
                tipo_documentacion: "",
                dni: "",
                direccion: "",
                celular: "",
            }
        );

        if (json.data.message === "logeo exitoso") {
            dispatch(loginUser(null, null, null, json.data));
            Swal.fire({
                icon: "success",
                title: "Registro exitoso",
            });
            handleClose();
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: `${json.data.title}`,
            });
        }
    };

    const responseFacebook = async (data) => {
        let nombre = data.name?.split(" ");

        if (nombre) {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}/autenticacion/loginGoogle`,
                {
                    email: data.email,
                }
            );

            if (json.data.message === "logeo exitoso") {
                dispatch(loginUser(null, null, json.data));
            } else {
                Swal.fire({
                    title: "Error",
                    text: `${json.data.title}`,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
                setLoading(false);
                setAcceder("Ingresar");
            }
        }
    };

    const responseFacebookRegister = async (data) => {
        let nombre = data.name?.split(" ");

        if (nombre) {
            const json = await axios.post(
                `${process.env.REACT_APP_PROXY}${"/cliente/"}`,
                {
                    correo: data.email,
                    nombre: nombre[0],
                    apellido_paterno: "",
                    tipo_usuario: 5,
                    tipo_logueo: "Facebook",
                    password: "",
                    apellido_materno: "",
                    tipo_documentacion: "",
                    dni: "",
                    direccion: "",
                    celular: "",
                }
            );

            if (json.data.message === "logeo exitoso") {
                dispatch(loginUser(null, null, null, json.data));
                Swal.fire({
                    icon: "success",
                    title: "Registro exitoso",
                });
                handleClose();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${json.data.title}`,
                });
            }
        }
    };

    const handleSubmitRegister = (e) => {
        e.preventDefault();

        /* const errors = validateInput(register); */
        const errors = {};
        setErrors(errors);

        console.log(errors);
        if (Object.entries(errors).length === 0) {
            const logeoUsuario = {
                correo: login.correo,
                password: login.password,
                tipo_usuario: 5,
                nombre: login.nombre,
                apellido_materno: login.apellidos,
                apellido_paterno: "",
                tipo_documentacion: "",
                dni: "",
                direccion: "",
                celular: "",
                tipo_logueo: "Correo",
            };
            setAcceder("Registrando...");

            axios
                .post(
                    `${process.env.REACT_APP_PROXY}${"/cliente/"}`,
                    logeoUsuario
                )
                .then((response) => {
                    if (response.data.message === "logeo exitoso") {
                        if (
                            authUser.tipo_usuario < 3 &&
                            authUser.tipo_usuario !== null
                        ) {
                            handleClose();
                        } else {
                            dispatch(
                                loginUser(null, null, null, response.data)
                            );
                            handleClose();
                        }
                    } else {
                        setAcceder("Registrarme");
                        Swal.fire({
                            title: "Ya existe un usuario asociado a ese email",
                            text: "Intente recuperar su contraseña",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        });
                    }
                });
        }
    };

    useEffect(() => {
        /* global google*/
        google.accounts.id.initialize({
            client_id:
                "383088837702-acs6qsjvqiaihnfnkv6ggk0npke4coji.apps.googleusercontent.com",
            callback: googleDatos,
        });
    }, []);
    useEffect(() => {
        if (authUser.tipo_usuario && !admin) {
            if (id_cliente) {
                if (!banderaReseña) {
                    setBandera(false);
                    setLoading(false);
                    setBanderaReseña(true);
                    setAcceder("Ingresar");
                    Swal.fire({
                        title: "Bienvenido",
                        text: registro
                            ? " se ha registrado correctamente"
                            : "se ha iniciado sesión correctamente",
                        icon: "success",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        setModalLogeo(!modalLogeo);
                    });
                }
                setBanderaReseña(false);
            } else if (bandera) {
                setBandera(false);
                setLoading(false);
                setAcceder("Ingresar");
                Swal.fire({
                    title: "Bienvenido",
                    text: registro
                        ? " se ha registrado correctamente"
                        : "se ha iniciado sesión correctamente",
                    icon: "success",
                    confirmButtonText: "Ok",
                }).then((result) => {
                    setBandera(true);
                    tipo
                        ? window.location.reload()
                        : setModalLogeo(!modalLogeo);
                });
            }
        }
    }, [authUser]);

    const validateInput = (form) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores = {};
        if (!regExpEmail.test(form.correo)) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        }
        return errores;
    };
    const handleInputChange = (e) => {
        if (e.target.name === "rememberme") {
            setLogin({
                ...login,
                rememberme: e.target.checked,
            });
        } else {
            setLogin({
                ...login,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        /* const errors = validateInput(login); */
        const errors = "";
        setErrors(errors);
        if (Object.entries(errors).length === 0) {
            if (login.rememberme) localStorage.setItem("email", login.correo);
            else localStorage.removeItem("email");
            setLoading(true);
            setAcceder("Accediendo...");
            dispatch(loginUser(login.correo, login.password));
        }
    };

    window.handleCredentialResponse = function (res) {
        console.log(res);
    };

    const handleClose = () => {
        setBandera(true);
        setLoading(false);
        setRegistro(false);
        setAcceder("Ingresar");
        setLoadingFavorito && setLoadingFavorito(true);
        setLogin({
            correo: emailStorage || "",
            password: "",
            rememberme: false,
            nombre: "",
            apellidos: "",
        });
        setModalLogeo(!modalLogeo);
        // dispatch(clearUserSelected());
    };

    return (
        <div
            className={`relative z-[60] items-center justify-center ${
                modalLogeo ? "flex" : "hidden"
            }`}>
            {olvido && (
                <ModalOlvidadoContraseña
                    olvido={olvido}
                    setOlvido={setOlvido}
                />
            )}

            <div
                className={
                    "fixed top-0 w-[130%] h-full bg-gray-900 bg-opacity-80 sticky-0"
                }>
                <div className="flex items-center justify-center px-24 py-8 xs:px-28 2xl:container 2xl:mx-auto md723:px-36 md920:px-28 sm:py-28">
                    <div className="grid md723:grid-cols-[55%,45%] md920:grid-cols-[60%,40%] w-full max-w-4xl bg-white rounded-2xl overflow-hidden shadow-lg dark:bg-white">
                        <div className="relative w-full h-full">
                            <img
                                src={registro ? imagenRegistro : imagenInicio}
                                alt="banner de inicio sesión"
                                className="object-cover w-full h-72 md723:h-full"
                            />
                            <div className="absolute bottom-0 w-full h-2/4 bg-gradient-to-t from-[#053465] to-transparent z-10"></div>
                            <div className="absolute z-20 top-6 left-6">
                                <img
                                    src={encabezado}
                                    alt="Encabezado"
                                    className="md:w-[65%] hidden md723:flex"
                                />
                            </div>
                            <div className="absolute bottom-0 z-20 w-full p-4 text-white md723:p-8">
                                <p className="hidden text-2xl font-bold md723:flex">
                                    {registro
                                        ? "¡Crea tu cuenta ahora!"
                                        : "¡Bienvenido de nuevo!"}
                                </p>
                                <p className="flex text-xl font-bold md723:hidden">
                                    Inicia sesión
                                </p>
                                {registro ? (
                                    <p className="mt-4 text-xs font-medium max-w-[500px] mx-auto hidden md723:flex">
                                        Al crear una cuenta, disfrutará de casas
                                        de campo, playa y embarcaciones,
                                        reservas más rápidas y ofertas
                                        exclusivas.
                                    </p>
                                ) : (
                                    <p className="text-sm font-medium max-w-[500px] mx-auto hidden md723:flex">
                                        Inicia sesión en tu cuenta para
                                        administrar tus reservas, <br></br>{" "}
                                        preferencias y más.
                                    </p>
                                )}

                                <p className="text-xs  max-w-[500px] mx-auto flex md723:hidden mt-2">
                                    ¡Bienvenido de nuevo! Por favor, introduce
                                    tus datos.
                                </p>
                                <div className="flex-wrap items-center justify-center hidden gap-5 mt-6 text-xs md723:flex">
                                    <p>© Copyright BlueCorner 2025</p>
                                    <p className="cursor-pointer hover:underline">
                                        Términos y Condiciones
                                    </p>
                                    <p className="cursor-pointer hover:underline">
                                        Políticas de uso y privacidad
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="relative flex flex-col justify-center w-full max-w-md p-4 mx-auto md723:p-8">
                            <p
                                onClick={() => handleClose()}
                                className="absolute cursor-pointer top-2 right-4">
                                <svg
                                    class="h-6 w-6 text-gray-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </p>
                            <div className="flex-col hidden md723:flex">
                                <h1
                                    className={`text-2xl font-bold text-center text-[${colores.primario}]`}>
                                    {registro
                                        ? "¡Regístrate ahora!"
                                        : "Inicia sesión"}
                                </h1>
                                <p className="mt-2 text-center text-[#0F80F2] text-xs">
                                    {registro
                                        ? "¡Regístrate ahora para comenzar tu viaje!"
                                        : "¡Bienvenido de nuevo! Por favor, introduce tus datos."}
                                </p>
                            </div>

                            <form
                                onSubmit={(e) =>
                                    registro
                                        ? handleSubmitRegister(e)
                                        : handleSubmit(e)
                                }
                                className="mt-2 md723:mt-6">
                                {registro && (
                                    <div className="grid grid-cols-2 gap-2 mb-4 md723:gap-4">
                                        <div>
                                            <label className="block mb-2 text-xs font-bold">
                                                Nombre
                                            </label>
                                            <input
                                                type="text"
                                                name="nombre"
                                                value={login.nombre}
                                                onChange={handleInputChange}
                                                placeholder="Nombre"
                                                className={`w-full p-3 rounded-lg  text-xs bg-[#ececec] ring-2 ring-gray-200 outline-none focus:ring-[${colores.secundario}] text-[#053465] placeholder:text-[#053465]`}
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-2 text-xs font-bold">
                                                Apellidos
                                            </label>
                                            <input
                                                type="text"
                                                name="apellidos"
                                                value={login.apellidos}
                                                onChange={handleInputChange}
                                                placeholder="Apellidos"
                                                className={`w-full p-3 rounded-lg  text-xs bg-[#ececec] ring-2 ring-gray-200 outline-none focus:ring-[${colores.secundario}] text-[#053465] placeholder:text-[#053465]`}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="mb-4">
                                    <label className="block mb-2 text-xs font-bold">
                                        Correo
                                    </label>
                                    <input
                                        type="email"
                                        name="correo"
                                        value={login.correo}
                                        onChange={handleInputChange}
                                        placeholder="Ingrese su correo"
                                        className={`w-full p-3 rounded-lg  text-xs bg-[#ececec] ring-2 ring-gray-200 outline-none focus:ring-[${colores.secundario}] text-[#053465] placeholder:text-[#053465]`}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block mb-2 text-xs font-bold">
                                        Contraseña
                                    </label>
                                    <div className="relative">
                                        <input
                                            type={
                                                showPass ? "text" : "password"
                                            }
                                            name="password"
                                            value={login.password}
                                            onChange={handleInputChange}
                                            placeholder="Ingresa tu contraseña"
                                            className={`w-full p-3 rounded-lg  text-xs bg-[#ececec] ring-2 ring-gray-200 outline-none focus:ring-[${colores.secundario}] text-[#053465] placeholder:text-[#053465]`}
                                        />
                                        <span
                                            className="absolute right-3 top-3 cursor-pointer text-[${colores.secundario}]"
                                            onClick={() =>
                                                setShowPass(!showPass)
                                            }>
                                            {showPass ? (
                                                <>
                                                    <svg
                                                        className={`w-4 h-4 mx-2 text-[#053465]  cursor-pointer`}
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        onClick={() => {
                                                            setShowPass(
                                                                !showPass
                                                            );
                                                        }}>
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                        />
                                                    </svg>
                                                </>
                                            ) : (
                                                <>
                                                    <svg
                                                        className={`w-4 h-4 mx-2 text-[#053465]  cursor-pointer`}
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        onClick={() => {
                                                            setShowPass(
                                                                !showPass
                                                            );
                                                        }}>
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                        />
                                                    </svg>
                                                </>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {registro ? (
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="flex items-center ">
                                            <input
                                                type="checkbox"
                                                name="remember"
                                                className="mr-2 "
                                            />
                                            <p className="font-semibold text-[#053465] text-xs">
                                                Acepto los
                                                <span
                                                    className="text-center text-[#053465] font-bold text-xs cursor-pointer underline ml-1"
                                                    onClick={() =>
                                                        setOlvido(true)
                                                    }>
                                                    Términos y Condiciones y la
                                                    Política de Privacidad
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="flex items-center ">
                                            <input
                                                type="checkbox"
                                                name="remember"
                                                className="mr-2 "
                                            />
                                            <span className="font-semibold text-[#053465] text-xs">
                                                Recordar
                                            </span>
                                        </div>

                                        <p
                                            className="text-center text-[#053465] font-bold text-xs cursor-pointer hover:underline"
                                            onClick={() => setOlvido(true)}>
                                            ¿Olvidaste tu contraseña?
                                        </p>
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    className={`w-full py-3 rounded-lg text-xs text-white bg-[${colores.secundario}] hover:bg-opacity-90 transition disabled:bg-[#416688]`}
                                    disabled={loading}>
                                    {Acceder}
                                </button>
                            </form>

                            <div className="mt-4 text-center text-[#bebebe] text-xs">
                                {registro
                                    ? "¿Ya tienes una cuenta?"
                                    : "¿No tienes una cuenta?"}
                                <span
                                    className={`text-[${colores.primario}] cursor-pointer pl-1 hover:underline font-bold`}
                                    onClick={() => (
                                        setRegistro(!registro),
                                        setAcceder(
                                            Acceder === "Ingresar"
                                                ? "Registrarme"
                                                : "Ingresar"
                                        )
                                    )}>
                                    {registro ? "Inicia Sesión" : "Registrate"}
                                </span>
                            </div>

                            <div className="mt-6 text-center">
                                <div className="flex items-center justify-center w-full">
                                    <hr className="flex-grow border-t border-gray-300" />
                                    <p className="text-[#053465] text-xs px-2 whitespace-nowrap flex-shrink-0">
                                        O continuar con
                                    </p>
                                    <hr className="flex-grow border-t border-gray-300" />
                                </div>
                                <div className="flex justify-center gap-4 mt-4">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <GoogleLogin
                                            googleDatos={
                                                registro
                                                    ? googleDatosRegister
                                                    : googleDatos
                                            }
                                        />
                                    </Suspense>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <FacebookLogin
                                            responseFacebook={
                                                registro
                                                    ? responseFacebookRegister
                                                    : responseFacebook
                                            }
                                        />
                                    </Suspense>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center gap-5 mt-6 text-xs md723:hidden text-[#053465] pb-5">
                                <p className="font-bold cursor-pointer hover:underline">
                                    Términos y Condiciones
                                </p>
                                <p className="font-bold cursor-pointer hover:underline">
                                    Políticas de uso y privacidad
                                </p>
                                <p>© Copyright BlueCorner 2025</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalLogeo;
