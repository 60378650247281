import React from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";
import useScrollTo from "../../hooks/useScrollToTop";
import loyaltyHeader from "../../img/loyalty_terms.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBarHome from "../NavBarHome";

const LoyaltyTerms = () => {
    useScrollTo();

    return (
        <div className="">
            <NavBarHome explorer={false} />
            <Helmet>
                <link
                    rel="canonical"
                    href="https://example.com/dresses/green-dresses"
                />
                <meta
                    name="description"
                    content={`Blue Corner, Alquileres, Embarcaciones, Alojamientos`}
                />
            </Helmet>

            <div className=" bg-[#C58B68] mx-auto  py-20">
                <h2 className="py-6 text-2xl font-bold tracking-widest text-center text-white md:leading-10 md:px-48">
                    TÉRMINOS Y CONDICIONES ESPECÍFICAS DE USO DE BLUE CORNER
                    PLATAFORMA WEB
                </h2>
                <div className="flex flex-wrap self-auto gap-2 justify-evenly">
                    <Link
                        className="text-white font-semibold text-xs md:text-base border-[3px] border-white py-1 px-3 hover:bg-[#307FE2]  duration-300 md:py-2 md:px-6"
                        to="/terminos-y-condiciones"
                    >
                        GENERAL
                    </Link>

                    <Link
                        className="text-white font-semibold text-xs md:text-base border-[3px] border-white py-1 px-3 hover:bg-[#307FE2]  duration-300 md:py-2 md:px-6"
                        to="/terminos-y-condiciones/casa"
                    >
                        CASAS
                    </Link>

                    <Link
                        className="text-white font-semibold text-xs md:text-base border-[3px] border-white py-1 px-3 hover:bg-[#307FE2]  duration-300 md:py-2 md:px-6"
                        to="/terminos-y-condiciones/embarcaciones"
                    >
                        EMBARCACIONES
                    </Link>
                    <Link
                        className="text-white font-semibold text-xs md:text-base border-[3px] border-white py-1 px-3 bg-[#307FE2]  duration-300 md:py-2 md:px-6"
                        to="/terminos-y-condiciones/loyalty"
                    >
                        BLUECOINS
                    </Link>
                    <Link
                        className="text-white font-semibold text-xs md:text-base border-[3px] border-white py-1 px-3 hover:bg-[#307FE2]  duration-300 md:py-2 md:px-6"
                        to="/terminos-y-condiciones/referidos"
                    >
                        REFERIDOS
                    </Link>
                </div>
            </div>
            <div className="p-2 mx-auto">
                <div className="mt-4">
                    <p className="flex p-3 text-[#053465]">
                        <b>
                            1. Por cada S/. 600.00 dealquiler el cliente ganará
                            1000 Bluecoins
                        </b>
                    </p>

                    <p className="flex p-3 text-[#053465]">
                        <b>
                            2. Cada propiedad cuenta con un número de Bluecoins
                            determinadas para poder descontar el 10% sobre el
                            alquiler de la propiedad bajo los siguientes T&C
                        </b>
                    </p>

                    <ul className="marker:text-[#053465] list-disc pl-10">
                        <li>El alquiler deberá tener un mínimo 5 noches</li>
                        <li>
                            Las Bluecoins son para el perfil del responsable
                            legal del alquiler, no aplica para los acompañantes,
                            asimismo, no son divisibles entre los acompañantes
                            ni proporcional.
                        </li>
                        <li>
                            Las Bluecoins son intransferibles; sin embargo el
                            cliente puede alquilar la propiedad para terceros,
                            siendo el responsable legal sobre el servicio.
                        </li>
                        <li>
                            Alquiler sujeto a disponibilidad de la propiedad
                        </li>
                        <li>
                            No aplica descuento sobre el pago de la garantía y
                            pagos adicionales
                        </li>
                        <li>
                            La reserva se debe realizar con 48 horas de
                            anticipación como mínimo
                        </li>
                        <li>No es acumulable el descuento o promociones</li>
                        <li>
                            No genera Bluecoins si es que el alquiler lleva una
                            promoción aplicada
                        </li>
                        <li>
                            No se genera Bluecoins cuando se está canjeando el
                            descuento del Programa Loyalty
                        </li>
                        <li>
                            Válido el descuento para fechas festivas y feriados
                        </li>
                    </ul>
                    <p className="flex p-3 text-[#053465]">
                        <b>
                            3. Las Bluecoins caducan en un plazo de 3 meses y
                            empieza a correr cuando se hace el pago completo del
                            alquiler anterior
                        </b>
                    </p>
                </div>
            </div>
            <div className="mt-8">
                <Footer />
            </div>
        </div>
    );
};

export default LoyaltyTerms;

//  1 Por cada S/. 600.00 dealquiler el cliente ganará 1000 Bluecoins

// 2 Cada propiedad cuenta con un número de Bluecoins determinadas para poder descontar el 10% sobre el alquiler de la propiedad bajo los siguientes T&C
// El alquiler deberá tener un mínimo 5 noches
// Las Bluecoins son para el perfil del responsable legal del alquiler, no aplica para los acompañantes, asimismo, no son divisibles entre los acompañantes ni proporcional.
// Las Bluecoins son intransferibles; sin embargo el cliente puede alquilar la propiedad para terceros, siendo el responsable legal sobre el servicio.
// Alquiler sujeto a disponibilidad de la propiedad
// No aplica descuento sobre el pago de la garantía y pagos adicionales
// La reserva se debe realizar con 48 horas de anticipación como mínimo
// No es acumulable el descuento o promociones
// No genera Bluecoins si es que el alquiler lleva una promoción aplicada
// No se genera Bluecoins cuando se está canjeando el descuento del Programa Loyalty
// Válido el descuento para fechas festivas y feriados

//  3 Las Bluecoins caducan en un plazo de 3 meses y empieza a correr cuando se hace el pago completo del alquiler anterior
