import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Lazy } from "swiper";

import getImageDimensions from "./getImageDimensions";

export const ModalGaleriaImagenes = ({
    modalVisible,
    setModalVisible,
    imagenes,
    imagen_principal,
    tipo,
    producto,
}) => {
    const [photos, setPhotos] = useState([
        {
            src: `${process.env.REACT_APP_PROXY_ARCHIVOS}${
                tipo === "productos"
                    ? "/bluecorner/v1/casa/getImagenPrincipal/"
                    : "/bluecorner/v1/embarcacion/getImagenPrincipal/"
            }${imagen_principal}`,
            original: `${process.env.REACT_APP_PROXY_ARCHIVOS}${
                tipo === "productos"
                    ? "/bluecorner/v1/casa/getImagenPrincipal/"
                    : "/bluecorner/v1/embarcacion/getImagenPrincipal/"
            }${imagen_principal}`,
            width: 10,
            height: 200,
        },
    ]);

    const [index, setIndex] = useState(-1);

    const currentImage = photos?.[index];

    const nextIndex = (index + 1) % photos?.length;
    const nextImage = photos?.[nextIndex] || currentImage;
    const prevIndex = (index + photos?.length - 1) % photos?.length;
    const prevImage = photos?.[prevIndex] || currentImage;
    const [espacios, setEspacios] = useState([]);
    const handleClick = (i, item) => {
        setIndex(item.orden);
    };
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    const [espacioSeleccionado, setEspacioSeleccionado] = useState(null);
    const groupRefs = useMemo(
        () => Array.from({ length: espacios.length }, () => React.createRef()),
        [espacios.length]
    );

    const handleClickEspacio = (index) => {
        setEspacioSeleccionado(index);

        if (groupRefs[index] && groupRefs[index].current) {
            groupRefs[index].current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    useEffect(() => {
        if (espacioSeleccionado !== null) {
            // Verificar si la referencia existe antes de hacer scroll
            if (
                groupRefs[espacioSeleccionado] &&
                groupRefs[espacioSeleccionado].current
            ) {
                groupRefs[espacioSeleccionado].current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [espacioSeleccionado, groupRefs]);

    useEffect(() => {
        cargarEtiquetas();
    }, []);

    const cargarEtiquetas = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/productosEtiquetasImagenes/getAllImagenes/${producto}`
            );
            let orden = 1;
            const espaciosAdaptados = await Promise.all(
                data.map(async (espacio) => {
                    let imagenesAdaptadas = await Promise.all(
                        espacio.imagenes.map(async (imagen, index) => {
                            const imageUrl = `${
                                process.env.REACT_APP_PROXY_ARCHIVOS
                            }${
                                tipo === "productos"
                                    ? "/bluecorner/v1/productoImagenes/getImagen/"
                                    : "/bluecorner/v1/embarcacionImagenes/getImagen/"
                            }${imagen.imagen}`;

                            return {
                                src: `${process.env.REACT_APP_PROXY_ARCHIVOS}${
                                    tipo === "productos"
                                        ? "/bluecorner/v1/productoImagenes/getImagen/"
                                        : "/bluecorner/v1/embarcacionImagenes/getImagen/"
                                }${imagen.imagen_minificada}`,
                                original: imageUrl,
                                description: imagen.descripcion,
                                width: 300,
                                height: 300,
                                orden: orden++,
                            };
                        })
                    );

                    setPhotos((prevPhotos) => [
                        ...prevPhotos,
                        ...imagenesAdaptadas,
                    ]);

                    return {
                        ...espacio,
                        imagenesAdaptadas: imagenesAdaptadas,
                    };
                })
            );

            setEspacios(espaciosAdaptados);
        } catch (error) {
            console.error("Error al cargar etiquetas:", error);
        }
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };
    console.log("currentImage", currentImage);
    return (
        <div
            className={`${
                !modalVisible
                    ? "translate-y-full invisible"
                    : "translate-y-0 flex visible"
            } transition-all ease-out duration-500 flex-col bg-slate-50 absolute left-0 top-0 z-[999] h-[1000vh] w-full items-center`}
        >
            <div className="flex flex-col w-full h-full">
                <div
                    onClick={() => handleCloseModal()}
                    className="flex items-center justify-center w-8 h-8 p-2 m-4 font-medium border border-gray-300 rounded-full shadow-md cursor-pointer"
                >
                    <svg
                        className="w-4 h-4 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </div>

                <div className="w-full mx-auto xs:w-11/12">
                    <div className="w-11/12 mx-auto text-2xl font-semibold mb-4">
                        Recorrido fotográfico
                    </div>
                    <div className="hidden md:flex flex-wrap items-start justify-start w-11/12 mx-auto gap-4 mb-4 xs:justify-start">
                        {espacios.map((espacio, index) =>
                            espacio.imagenes.length ? (
                                <div
                                    onClick={() => handleClickEspacio(index)}
                                    key={index}
                                    className="py-2 cursor-pointer hover:scale-105 w-36"
                                >
                                    <img
                                        className="w-32 h-20"
                                        src={`${
                                            process.env.REACT_APP_PROXY_ARCHIVOS
                                        }${"/bluecorner/v1/productoImagenes/getImagen/"}${
                                            espacio.imagenes[0]
                                                .imagen_minificada
                                        }`}
                                        alt={espacio.nombre}
                                    />
                                    <span className="font-light capitalize ">
                                        {espacio.nombre}
                                    </span>
                                </div>
                            ) : null
                        )}
                    </div>

                    <div className="flex md:hidden flex-wrap items-start justify-start w-11/12 mx-auto gap-4 mb-4 xs:justify-start">
                        <Swiper
                            slidesPerView={2.5}
                            spaceBetween={50}
                            centeredSlides={true}
                        >
                            {espacios.map((espacio, index) =>
                                espacio.imagenes.length ? (
                                    <SwiperSlide key={index}>
                                        <div
                                            onClick={() =>
                                                handleClickEspacio(index)
                                            }
                                            key={index}
                                            className="py-2 cursor-pointer hover:scale-105 w-36"
                                        >
                                            <img
                                                className="w-32 h-32 object-cover rounded-lg"
                                                src={`${
                                                    process.env
                                                        .REACT_APP_PROXY_ARCHIVOS
                                                }${"/bluecorner/v1/productoImagenes/getImagen/"}${
                                                    espacio.imagenes[0]
                                                        .imagen_minificada
                                                }`}
                                                alt={espacio.nombre}
                                            />
                                            <span className="text-sm mt-2 capitalize ">
                                                {espacio.nombre}
                                            </span>
                                        </div>
                                    </SwiperSlide>
                                ) : null
                            )}
                        </Swiper>
                    </div>
                    {espacios.map((espacio, index) =>
                        espacio.imagenes.length ? (
                            <div
                                ref={groupRefs[index]}
                                key={espacio.id}
                                className="w-full flex flex-col gap-2 mt-6 pt-6"
                            >
                                <p className="text-lg font-bold">
                                    {espacio.nombre}
                                </p>
                                <div>
                                    {
                                        <Gallery
                                            images={espacio.imagenesAdaptadas}
                                            onClick={handleClick}
                                            enableImageSelection={false}
                                            rowHeight={300}
                                            margin={5}
                                        />
                                    }
                                </div>
                            </div>
                        ) : null
                    )}
                    {!!currentImage && (
                        /* @ts-ignore */
                        <Lightbox
                            mainSrc={currentImage.original}
                            imageTitle={currentImage.description}
                            imageCaption={currentImage.description}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={nextImage.original}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.original}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
