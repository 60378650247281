import axios from "axios";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import BackgroundImage from "../../../img/bluecorner_agradecimiento.png";
import BLUECORNER_PNG from "../../../img/Home/BLUECORNER_PNG.png";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import AllCards from "../../promos/promo-ano-nuevo/AllCards";
import Spinner from "../../Spinner";
import { ShowStarsRating } from "../../StarsRating";
import Swal from "sweetalert2";
import { CheckoutPaquetesEmpresa } from "./CheckoutPaquetesEmpresa";
import { useNavigate } from "react-router";
import CardServicios from "./CardServicios";
import NavBarHome from "../../NavBarHome";

export const ListaDeServicios = () => {
    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const navigate = useNavigate();
    const [paqueteSelect, setPaqueteSelect] = useState(null);
    const [allPaquetes, setAllPaquetes] = useState([]);
    const [ListaDePaquetes, setListaDePaquetes] = useState([]);
    const [checkout, setChecckout] = useState(false);
    const [carrito, setCarrito] = useState(null);
    const [carritoImagen, setCarritoImagen] = useState({
        indexIma: -2,
        indexCar: 1,
    });

    const [servicios, setServicios] = useState([]);

    const getPaquetes = async (pagina = 1, habilitado = "Si", input = "") => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/paquetesempresas/all/${pagina}/${habilitado}/servicio/?nombre=${input}`,

            { headers }
        );

        setAllPaquetes(json.data.data);
    };

    const continuar = () => {
        if (servicios.length === 0) {
            localStorage.setItem("servicios_completos", JSON.stringify([]));
            navigate("/paquetes-empresariales/checkout");
        } else {
            let serviciosCompletos = [];

            for (let i = 0; i < servicios.length; i++) {
                serviciosCompletos.push(allPaquetes[servicios[i]]);
            }

            localStorage.setItem(
                "servicios_completos",
                JSON.stringify(serviciosCompletos)
            );

            navigate("/paquetes-empresariales/checkout");
        }
    };

    const paqueteAlistado = (index) => {
        let color = "text-blue-500";
        ListaDePaquetes?.map((paquete) => {
            if (paquete.indexPaquete === index) {
                color = "text-green-500 scale-125";
            }
        });
        return color;
    };

    useEffect(() => {
        getPaquetes();
        let carrito = JSON.parse(localStorage.getItem("carrito"));

        setCarrito(carrito);
        let servicios = JSON.parse(localStorage.getItem("servicios"));
        console.log(servicios);
        setServicios(servicios || []);
    }, []);

    return (
        <div>
            <NavBarHome explorer={false} />
            {checkout ? (
                <CheckoutPaquetesEmpresa
                    setChecckout={setChecckout}
                    setListaDePaquetes={setListaDePaquetes}
                />
            ) : (
                <>
                    <div className={``}>
                        <div className="py-4 mt-20 text-white bg-blue-bluecorner md:text-3xl px-11 md:py-12 md:px-28">
                            <div className="mx-auto text-center md:max-w-xl">
                                <div className="mt-4 text-3xl font-bold md:text-5xl">
                                    ¿Deseas agregar algún servicio extra?
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div
                            className={
                                "flex items-center text-lg cursor-pointer pt-4 mt-2 px-4 "
                            }
                            onClick={() => navigate("/paquetes-empresariales")}
                        >
                            <svg
                                className="w-6 h-6 -ml-2 text-black"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <polyline points="15 6 9 12 15 18" />
                            </svg>
                            <span className="mx-1">Regresar</span>
                        </div>
                        <div
                            className={
                                "flex items-center text-lg cursor-pointer pt-4 mt-2 px-4 "
                            }
                            onClick={() => continuar()}
                        >
                            <span className="mx-1">Continuar</span>
                            <svg
                                className="w-6 h-6 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                {" "}
                                <polyline points="9 18 15 12 9 6" />
                            </svg>
                        </div>
                    </div>

                    <div
                        className={
                            carrito !== null
                                ? "relative mt-5 select-none rounded-xl md:mt-0 border-2 border-blue-500 w-96 mx-auto"
                                : "hidden"
                        }
                    >
                        <div className="relative w-full h-[200px]">
                            <Swiper
                                modules={[Navigation]}
                                slidesPerView={1}
                                navigation
                                pagination={{
                                    clickable: true,
                                }}
                                rewind={true}
                                loop={true}
                                longSwipesMs={1200}
                                onSlideNextTransitionStart={() =>
                                    setCarritoImagen({
                                        indexIma:
                                            carritoImagen.indexCar === 1 &&
                                            carritoImagen.indexIma ===
                                                carrito?.imagenes.length - 1
                                                ? 0
                                                : carritoImagen.indexCar === 1
                                                ? carritoImagen.indexIma + 1
                                                : 1,
                                        indexCar: 1,
                                    })
                                }
                                onSlidePrevTransitionStart={() =>
                                    setCarritoImagen({
                                        indexIma:
                                            carritoImagen.indexIma > 0 &&
                                            carritoImagen.indexCar === 1
                                                ? carritoImagen.indexIma - 1
                                                : carrito?.imagenes.length - 1,
                                        indexCar: 1,
                                    })
                                }
                            >
                                <SwiperSlide className="">
                                    {carritoImagen.indexIma > -1 &&
                                    carritoImagen.indexCar === 1 ? (
                                        <LazyLoadImage
                                            className="object-contain w-full h-64 sm:h-52 rounded-md swiper-lazy"
                                            alt="Imagen del producto"
                                            src={`${
                                                process.env
                                                    .REACT_APP_PROXY_ARCHIVOS
                                            }/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${
                                                carrito.imagenes[
                                                    carritoImagen.indexIma
                                                ]?.imagen_minificada
                                            }`}
                                        />
                                    ) : (
                                        <LazyLoadImage
                                            className="object-contain w-full h-64 sm:h-52 rounded-md swiper-lazy"
                                            alt="Imagen del producto"
                                            src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/paquetesEmpresasImagenes/getImagen/${carrito?.imagenes[0]?.imagen_minificada}`}
                                        />
                                    )}
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="bg-blue-500 h-2 w-full mt-2"></div>
                        <div className="flex flex-col justify-between items-start w-full">
                            <div className="px-4 py-2 w-full ">
                                <div
                                    className={`mt-3 2xl:text-3xl text-lg   font-bold  flex justify-center items-center`}
                                >
                                    {carrito?.nombre}
                                </div>

                                <div className="text-left text-blue-500 mt-3 font-semibold">
                                    {`Desde USD ${carrito?.precio} `}
                                    <span className="italic">
                                        ( precio referencial )
                                    </span>
                                </div>

                                <div className="h-28 mt-2 overflow-y-auto text-base text-gray-500 text-justify">
                                    {carrito?.descripcion}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="w-11/12 mx-auto" />
                    <div className="bg-gray-100">
                        <div className="w-2/3 mx-auto">
                            <div>
                                <p className="text-center font-medium text-2xl my-3">
                                    Servicios extras
                                </p>
                            </div>
                            <hr className="w-11/12 mx-auto" />
                            {allPaquetes?.length > 0 ? (
                                <div className="w-full p-4 antialiased  grid grid-cols-1 xl:grid-cols-2 gap-6">
                                    {allPaquetes.map((paquete, index) => (
                                        <CardServicios
                                            key={paquete.id}
                                            servicios={servicios}
                                            setServicios={setServicios}
                                            paquete={paquete}
                                            index={index}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="justify-center w-full mt-10">
                                    <Spinner />
                                </div>
                            )}
                            <div className="flex justify-end w-full px-4 py-2">
                                <p
                                    onClick={() => continuar()}
                                    className="p-3 text-white bg-blue-500 rounded-lg cursor-pointer hover:bg-blue-600"
                                >
                                    Continuar
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <Footer />
        </div>
    );
};
