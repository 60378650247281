import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BackgroundImage from "../../../img/bluecorner_agradecimiento.png";
import BLUECORNER_PNG from "../../../img/Home/BLUECORNER_PNG.png";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import AllCards from "../../promos/promo-ano-nuevo/AllCards";
import Spinner from "../../Spinner";
import { ShowStarsRating } from "../../StarsRating";
import Swal from "sweetalert2";
import { CheckoutPaquetesEmpresa } from "./CheckoutPaquetesEmpresa";
import { useNavigate } from "react-router";

import CardPaquete from "./CardPaquete";
import NavBarHome from "../../NavBarHome";

export const ListaDePaquetes = () => {
    const authUser = useSelector((state) => state.authReducer);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const navigate = useNavigate();
    const [paqueteSelect, setPaqueteSelect] = useState(null);
    const [allPaquetes, setAllPaquetes] = useState([]);
    const [ListaDePaquetes, setListaDePaquetes] = useState([]);

    const [checkout, setChecckout] = useState(false);

    const getPaquetes = async (pagina = 1, habilitado = "Si", input = "") => {
        const json = await axios.get(
            `${process.env.REACT_APP_PROXY}/paquetesempresas/all/${pagina}/${habilitado}/paquete/?nombre=${input}`,

            { headers }
        );
        console.log(json);
        setAllPaquetes(json.data.data);
    };

    const addRango = (index, indexPaquete) => {
        if (index === "Rango de personas") {
            setPaqueteSelect(null);
        } else if (index === "Otro rango") {
            setPaqueteSelect({
                index: index,
                indexPaquete: indexPaquete,
                id_paquetes_empresas: allPaquetes[indexPaquete].id,
                costo: 0,
                numero_personas: 0,
                nombre_paquete: allPaquetes[indexPaquete].nombre,
                descripcion_paquete: allPaquetes[indexPaquete].descripcion,
                imagenes: allPaquetes[indexPaquete].imagenes,
            });
        } else {
            setPaqueteSelect({
                ...allPaquetes[indexPaquete].personas[index],
                index: index,
                indexPaquete: indexPaquete,
                nombre_paquete: allPaquetes[indexPaquete].nombre,
                descripcion_paquete: allPaquetes[indexPaquete].descripcion,
                imagenes: allPaquetes[indexPaquete].imagenes,
                costo: allPaquetes[indexPaquete].personas[index].precio,
            });
        }
    };

    const addPaquete = (paquete) => {
        localStorage.setItem("carrito", JSON.stringify(paquete));
        localStorage.setItem("servicios", JSON.stringify([]));
        navigate("/paquetes-empresariales/servicios");
    };

    const paqueteAlistado = (index) => {
        let color = "text-blue-500";
        ListaDePaquetes?.map((paquete) => {
            if (paquete.indexPaquete === index) {
                color = "text-green-500 scale-125";
            }
        });
        return color;
    };

    useEffect(() => {
        let carrito = JSON.parse(localStorage.getItem("carrito"));
        console.log(carrito);
        setListaDePaquetes(carrito || []);
        getPaquetes();
    }, []);

    console.log({ ListaDePaquetes, paqueteSelect, allPaquetes });

    return (
        <div>
            <NavBarHome explorer={false} />
            {checkout ? (
                <CheckoutPaquetesEmpresa
                    setChecckout={setChecckout}
                    setListaDePaquetes={setListaDePaquetes}
                />
            ) : (
                <>
                    <div className={``}>
                        <div
                            style={{
                                backgroundImage: `url(${BackgroundImage})`,
                                filter: "brightness( 100% ) contrast( 113% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
                            }}
                            className={`w-full bg-[#053465]  bg-center bg-no-repeat
                bg-cover flex flex-col text-center`}
                        >
                            <div className="h-full bg-[rgba(5,52,101,0.60)] text-white pb-5">
                                <img
                                    src={BLUECORNER_PNG}
                                    alt="Logo bluecorner"
                                    className="w-full max-w-xs md:mx-24"
                                />
                                <h1 className="max-w-xl mt-12 text-3xl font-bold md:text-5xl md:text-left md:mx-28 ">
                                    ¿TIENES UN EVENTO EMPRESARIAL?
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div>
                        {allPaquetes?.length > 0 ? (
                            <div className="w-full grid-cols-2 gap-4 p-4 mt-8 md:grid lg:grid-cols-2 2xl:grid-cols-3 xl:w-3/4 2xl:w-2/3 mx-auto">
                                {allPaquetes.map((paquete) => (
                                    <CardPaquete
                                        key={paquete.id}
                                        paquete={paquete}
                                        addPaquete={addPaquete}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="justify-center w-full mt-10">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </>
            )}
            <Footer />
        </div>
    );
};
