import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useSelector } from "react-redux";
import axios from "axios";
import dividirFechaDatePicker from "../../helpers/dividirFechaDatePicker";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { Calendar } from "react-calendar";
import formatedDate_YYYY_MM_DD from "../../helpers/formatedDate_YYYY_MM_DD";
import { useParams } from "react-router";
import numberToCommas from "../../helpers/numberToCommas";
import getTotalReservaFinal from "../getTotalReservaFinal";
import getTotalReservaInicial from "../getTotalReservaInicial";
export const FormularioReservaCasa = ({
    habilitarModalFormulario,
    setHabilitarModalFormulario,
    id_casa,
    nombre_casa,
    currentProduct,
    arrayPedidos,
    arrayMeses,
    arrayDias,
    arrayFechas,
    setErrorDates,
    setCantDays,
    setSelectedDays,
    minimaDisponibilidad,
    cantDays,
    errorDates,
    pointsDiscount,
    selectedDays,
    calcularDescuento,
    aditionalServices,
    getGarantiaTotal,
}) => {
    registerLocale("es", es);
    const authUser = useSelector((state) => state.authReducer);
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const moneda_elegida = localStorage.getItem("moneda");
    const { fechas } = useParams();

    const [enabledPersonas, setEnabledPersonas] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [cargando, setCargando] = useState(false);

    const [input, setInput] = useState({
        nombre: "",
        apellido: "",
        correo: "",
        total_personas: 1,
        adultos: 1,
        ninos: 0,
        fecha_inicio: "Fecha no seleccionada",
        fecha_final: "Fecha no seleccionada",
        nota: "",
        celular: "",
    });

    const enviar = async () => {
        if (
            input.nombre === "" ||
            input.apellido === "" ||
            input.correo === "" ||
            input.total_personas === 0 ||
            input.fecha_inicio === "Fecha no seleccionada" ||
            input.fecha_final === "Fecha no seleccionada" ||
            input.celular === ""
        ) {
            Swal.fire({
                icon: "error",
                title: "Todos los campos son requeridos",
                showConfirmButton: false,
                timer: 1500,
            });
            return;
        }
        setCargando(true);

        const fechaInicioFormateada = formatDate(input.fecha_inicio);
        const fechaFinalFormateada = formatDate(input.fecha_final);

        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/casa/enviarCotizacionFormulario`,
            {
                nombre: `${input.nombre} ${input.apellido}`,
                correo: input.correo,
                personas: input.total_personas,
                adultos: input.adultos,
                ninos: input.ninos,
                inicio: fechaInicioFormateada,
                final: fechaFinalFormateada,
                casa: nombre_casa,
                celular: input.celular,
            },
            { headers }
        );
        setCargando(false);

        const mensaje = `
Hola Estoy interesad@ en reservar *${nombre_casa}*

*Mis datos son*:
*Nombre*: ${input.nombre}
*Apellido*: ${input.apellido}
*Correo*: ${input.correo}
*Cantidad de personas*: ${input.total_personas} (Adultos : ${input.adultos} - niños : ${input.ninos})

*Fecha de inicio*: ${fechaInicioFormateada}
*Fecha de salida*: ${fechaFinalFormateada}`;

        const mensajeCodificado = encodeURIComponent(mensaje);

        // const numeroWhatsApp = "554598373045";
        const numeroWhatsApp = "51941110270";

        const urlWhatsApp = `https://wa.me/${numeroWhatsApp}?text=${mensajeCodificado}`;
        window.open(urlWhatsApp, "_blank");
        return;
    };

    const handleHuespedes = (e, sumarOrestar, tipo, persona) => {
        e.preventDefault();

        if (tipo === "huespedes") {
            if (sumarOrestar === "restar") {
                if (persona === "adultos" && input.adultos > 0) {
                    setInput((prev) => {
                        return {
                            ...prev,
                            total_personas: Math.max(
                                1,
                                prev.total_personas - 1
                            ),
                            adultos: Math.max(1, prev.adultos - 1),
                        };
                    });
                } else if (persona === "ninos" && input.ninos > 0) {
                    setInput((prev) => {
                        return {
                            ...prev,
                            total_personas: Math.max(
                                0,
                                prev.total_personas - 1
                            ),
                            ninos: Math.max(0, prev.ninos - 1),
                        };
                    });
                }
            } else {
                if (
                    persona === "adultos" &&
                    currentProduct.numero_personas > input.adultos + input.ninos
                ) {
                    setInput((prev) => {
                        return {
                            ...prev,
                            total_personas: prev.total_personas + 1,
                            adultos: prev.adultos + 1,
                        };
                    });
                } else if (
                    persona === "ninos" &&
                    currentProduct.numero_personas > input.adultos + input.ninos
                ) {
                    setInput((prev) => {
                        return {
                            ...prev,
                            total_personas: prev.total_personas + 1,
                            ninos: prev.ninos + 1,
                        };
                    });
                }
            }
        }
    };

    const [selectedRange, setSelectedRange] = useState(null);
    const getDaysBetween2Dates = (arrayDates) => {
        const [dateOne, dateTwo] = arrayDates;
        let nuevosDias = 0;

        const primerAño = dateOne.getFullYear();
        const primerMes = dateOne.getMonth();
        const primerDia = dateOne.getDate();
        let nuevaPrimeraFecha = new Date(primerAño, primerMes, primerDia);

        while (nuevaPrimeraFecha <= dateTwo) {
            nuevaPrimeraFecha.setDate(nuevaPrimeraFecha.getDate() + 1);
            nuevosDias++;
        }

        return nuevosDias;
    };

    const allowReservation = (
        array_dias_reserva,
        array_dias_disponibilidad,
        numero_noches_necesarios
    ) => {
        let cantDiasCoinciden = 0;

        const new_array_disponiblidad = array_dias_disponibilidad.map((day) =>
            day.getTime()
        );

        array_dias_reserva.forEach((day_reserva) => {
            if (new_array_disponiblidad.includes(day_reserva.getTime())) {
                cantDiasCoinciden += 1;
            }
        });

        if (cantDiasCoinciden - 1 >= numero_noches_necesarios) return true;
        else return false;
    };

    const onChangeCalendar = (twoDates) => {
        let dates = twoDates;
        setSelectedRange(dates);
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        let dateOne = dates[0];
        let dateTwo = dates[1];

        let totalDays = getDaysBetween2Dates(dates);

        const allDatesSelected = getEveryDayBetween2Dates(dates).map((date) =>
            date.getTime()
        );
        const allDaysSelected = getEveryDayBetween2Dates(dates).map((date) =>
            date.getDay()
        );
        const allMonthssSelected = getEveryDayBetween2Dates(dates).map((date) =>
            date.getMonth()
        );

        const isAvailable = minimaDisponibilidad.map(
            ({ fecha_inicio, fecha_final, numero_noches }) => {
                const FDI = new Date(fecha_inicio + "T00:00:00");
                const FDF = new Date(fecha_final + "T00:00:00");
                const [FRI, FRF] = dates;

                const array_dias_disponibilidad = getEveryDayBetween2Dates([
                    FDI,
                    FDF,
                ]);
                const array_dias_reserva = getEveryDayBetween2Dates([FRI, FRF]);

                if (FRI <= FDI) {
                    if (FRF <= FDI) {
                        return {
                            available: true,
                            message: "Se puede reservar",
                        }; // case 1
                    } else {
                        if (FRF >= FDF) {
                            return {
                                available: true,
                                message: "Se puede reservar",
                            }; // case 4
                        } else {
                            if (
                                allowReservation(
                                    array_dias_reserva,
                                    array_dias_disponibilidad,
                                    numero_noches
                                )
                            ) {
                                return {
                                    available: true,
                                    message: "Se puede reservar",
                                };
                            } else {
                                return {
                                    available: false,
                                    message: `Es necesario reservar minimamente ${numero_noches} noches entre el día ${FDI.toLocaleDateString(
                                        "es-ES",
                                        options
                                    )} y el día ${FDF.toLocaleDateString(
                                        "es-ES",
                                        options
                                    )}`,
                                }; // case 2
                            }
                        }
                    }
                } else {
                    if (FRI > FDF) {
                        return {
                            available: true,
                            message: "Se puede reservar",
                        }; // case 5
                    } else {
                        if (
                            allowReservation(
                                array_dias_reserva,
                                array_dias_disponibilidad,
                                numero_noches
                            )
                        ) {
                            return {
                                available: true,
                                message: "Se puede reservar",
                            };
                        } else {
                            return {
                                available: false,
                                message: `Es necesario reservar minimamente ${numero_noches} noches entre el día ${FDI.toLocaleDateString(
                                    "es-ES",
                                    options
                                )} y el día ${FDF.toLocaleDateString(
                                    "es-ES",
                                    options
                                )}`,
                            }; // case 3
                        }
                    }
                }
            }
        );

        if (
            allDatesSelected.some(
                (date) =>
                    arrayFechas.includes(
                        formatedDate_YYYY_MM_DD(new Date(date))
                    ) ||
                    arrayPedidos.includes(
                        formatedDate_YYYY_MM_DD(new Date(date))
                    )
            ) ||
            allDaysSelected.some((day) => arrayDias.includes(day)) ||
            allMonthssSelected.some((month) => arrayMeses.includes(month))
        ) {
            setCantDays(0);
            setSelectedDays([]);
            setErrorDates("No puede elegir días no disponibles");
        } else {
            if (isAvailable.some(({ available }) => available === false)) {
                setCantDays(0);
                setSelectedDays([]);
                const { message } = isAvailable.find(
                    ({ available }) => available === false
                );
                setErrorDates(message);
            } else if (
                totalDays <
                (currentProduct.tipo_estadia === "dia"
                    ? currentProduct.alquiler_minimo
                    : currentProduct.alquiler_minimo + 1)
            ) {
                let match = false;
                const [FRI, FRF] = dates;
                minimaDisponibilidad.forEach(
                    ({ fecha_inicio, fecha_final, numero_noches }) => {
                        const FDI = new Date(fecha_inicio + "T00:00:00");
                        const FDF = new Date(fecha_final + "T00:00:00");
                        if (
                            (FDI <= FRI && FDF >= FRI) ||
                            (FDI <= FRF && FDF >= FRF) ||
                            (FRI <= FDI && FRF >= FDI) ||
                            (FRI <= FDF && FRF >= FDF)
                        ) {
                            if (totalDays >= numero_noches) {
                                match = true;
                            }
                        }
                    }
                );
                if (match) {
                    setErrorDates(null);
                    setCantDays(totalDays);
                    setSelectedDays([dateOne, dateTwo]);
                } else {
                    setCantDays(0);
                    setSelectedDays([]);
                    setErrorDates(`Seleccione un mínimo de
                    ${
                        currentProduct.alquiler_minimo +
                        (currentProduct.tipo_estadia === "dia" ? 0 : 1)
                    }
                    dias`);
                }
            } else {
                setErrorDates(null);
                setCantDays(totalDays);
                setSelectedDays([dateOne, dateTwo]);

                setInput({
                    ...input,
                    fecha_inicio: formatedDate_YYYY_MM_DD(new Date(dateOne)),
                    fecha_final: formatedDate_YYYY_MM_DD(new Date(dateTwo)),
                });
                setOpenCalendar(false);
            }
        }
    };
    useEffect(() => {
        if (fechas) {
            let filtrosSplit = fechas?.split("&");
            let objeto = {
                fecha_inicio: filtrosSplit[0].split("=")[1],
                fecha_final: filtrosSplit[1].split("=")[1],
            };

            const startDate = new Date(`${objeto.fecha_inicio}T00:00:00`);
            const endDate = new Date(`${objeto.fecha_final}T00:00:00`);
            onChangeCalendar([startDate, endDate]);
        }
    }, [fechas]);

    const calculateDisabledTiles = (date) => {
        if (arrayFechas?.length > 0) {
            const block = arrayFechas?.some((fecha) => {
                return (
                    date.getTime() ===
                        new Date(fecha + "T00:00:00").getTime() ||
                    arrayDias.some((day) => day === date.getDay()) ||
                    arrayMeses.some((month) => date.getMonth() === month)
                );
            });
            if (block) return block;
        }
        if (arrayPedidos.length > 0) {
            const block = arrayPedidos?.some((fecha) => {
                return (
                    date.getTime() ===
                        new Date(fecha + "T00:00:00").getTime() ||
                    arrayDias.some((day) => day === date.getDay()) ||
                    arrayMeses.some((month) => date.getMonth() === month)
                );
            });
            if (block) return block;
        }
        if (arrayDias.length > 0) {
            return (
                arrayDias.some((day) => date.getDay() === day) ||
                arrayMeses.some((month) => date.getMonth() === month)
            );
        }
        return arrayMeses.some((month) => date.getMonth() === month);
    };

    const getEveryDayBetween2Dates = (arrayFechas) => {
        const [startDate, endDate] = arrayFechas.map(
            (fecha) => new Date(fecha)
        );
        const date = new Date(startDate.getTime());
        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split("-"); // Divide la fecha en partes
        return `${day}/${month}/${year}`;
    };

    return (
        <div>
            <div>
                <div className="py-4 border-2 border-[#053465] rounded-lg ">
                    <p className="text-xl font-semibold text-center sm:text-2xl">
                        Datos para la reserva
                    </p>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-2 xs:grid-cols-2">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Nombre:
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="nombre"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        nombre: e.target.value,
                                    })
                                }
                                value={input.nombre}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50  "
                                placeholder="Nombre"
                            />
                        </div>
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Apellido:
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="apellido"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        apellido: e.target.value,
                                    })
                                }
                                value={input.apellido}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                placeholder="Apellido"
                            />
                        </div>
                    </div>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-2 xs:grid-cols-1">
                        <div className="relative flex flex-col">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Personas:
                            </label>
                            <div
                                onClick={() =>
                                    setEnabledPersonas(!enabledPersonas)
                                }
                                className="flex items-center justify-between p-1 px-3 border border-gray-300 rounded-lg cursor-pointer"
                            >
                                <div className="text-base">
                                    {input.total_personas}
                                </div>
                                {!enabledPersonas ? (
                                    <svg
                                        class="h-8 w-8 text-[#053465] "
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <polyline points="6 9 12 15 18 9" />
                                    </svg>
                                ) : (
                                    <svg
                                        class="h-8 w-8 text-[#053465]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <polyline points="6 15 12 9 18 15" />
                                    </svg>
                                )}
                            </div>
                            {enabledPersonas && (
                                <div className=" w-full bg-white border-2 rounded-md border-[#053465] p-1.5 ">
                                    {" "}
                                    <div>
                                        Adultos
                                        <p className="text-xs text-gray-500">
                                            De 13 años o más
                                        </p>
                                        <div className="flex items-center justify-center mx-auto mt-2 ">
                                            <button
                                                className={`w-8 py-1 mr-4 text-white transition bg-blue-500 rounded-lg hover:scale-110`}
                                                onClick={(e) =>
                                                    handleHuespedes(
                                                        e,
                                                        "restar",
                                                        "huespedes",
                                                        "adultos"
                                                    )
                                                }
                                            >
                                                -
                                            </button>

                                            <div className="w-6 text-center">
                                                {input.adultos}
                                            </div>
                                            <button
                                                className={`w-8 py-1 mx-4 text-white transition bg-blue-500  rounded-lg hover:scale-110`}
                                                onClick={(e) =>
                                                    handleHuespedes(
                                                        e,
                                                        "sumar",
                                                        "huespedes",
                                                        "adultos"
                                                    )
                                                }
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        Niños
                                        <p className="text-xs text-gray-500">
                                            De 0 a 12 años
                                        </p>
                                        <div className="flex items-center justify-center mx-auto mt-2 ">
                                            <button
                                                className={`w-8 py-1 mr-4 text-white transition bg-blue-500  rounded-lg hover:scale-110`}
                                                onClick={(e) =>
                                                    handleHuespedes(
                                                        e,
                                                        "restar",
                                                        "huespedes",
                                                        "ninos"
                                                    )
                                                }
                                            >
                                                -
                                            </button>
                                            {/* <span>{ninos}</span> */}
                                            <div className="w-6 text-center">
                                                {input.ninos}
                                            </div>
                                            <button
                                                className={`w-8 py-1 mx-4 text-white transition bg-blue-500 rounded-lg hover:scale-110`}
                                                onClick={(e) =>
                                                    handleHuespedes(
                                                        e,
                                                        "sumar",
                                                        "huespedes",
                                                        "ninos"
                                                    )
                                                }
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className="flex justify-end w-full"
                                        onClick={() =>
                                            setEnabledPersonas(false)
                                        }
                                    >
                                        <button className="text-base font-medium hover:underline">
                                            Cerrar
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Correo:
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="correo"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        correo: e.target.value,
                                    })
                                }
                                value={input.correo}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                placeholder="Correo"
                            />
                        </div>
                    </div>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-2 ">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Celular:
                            </label>
                            <input
                                autoComplete="off"
                                type="number"
                                name="personas"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        celular: e.target.value,
                                    })
                                }
                                value={input.celular}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                placeholder="Celular"
                            />
                        </div>
                    </div>
                    <div className="relative grid w-10/12 gap-4 p-1 mx-auto mt-2 xs:grid-cols-1 ">
                        <div
                            onClick={() => setOpenCalendar(!openCalendar)}
                            className="flex items-center justify-between p-2 border border-gray-300 rounded-md cursor-pointer"
                        >
                            <div className="flex flex-col">
                                <div className="mb-1 text-sm text-left text-gray-600 ">
                                    Fechas de reserva:
                                </div>
                                <div className="text-sm">
                                    {input.fecha_inicio ===
                                        "Fecha no seleccionada" &&
                                    input.fecha_final ===
                                        "Fecha no seleccionada" ? (
                                        "Fechas no seleccionadas"
                                    ) : (
                                        <div className="flex flex-col items-center justify-start gap-2">
                                            <div>
                                                <div className="font-bold ">
                                                    Fecha Inicio:{" "}
                                                </div>{" "}
                                                <div>
                                                    {formatDate(
                                                        input.fecha_inicio
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="font-bold ">
                                                    Fecha Final:{" "}
                                                </div>{" "}
                                                <div>
                                                    {formatDate(
                                                        input.fecha_final
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {!openCalendar ? (
                                <svg
                                    class="h-8 w-8 text-[#053465] "
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <polyline points="6 9 12 15 18 9" />
                                </svg>
                            ) : (
                                <svg
                                    class="h-8 w-8 text-[#053465]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <polyline points="6 15 12 9 18 15" />
                                </svg>
                            )}
                        </div>

                        {openCalendar && (
                            <div className="w-full">
                                <Calendar
                                    selectRange={true}
                                    onChange={onChangeCalendar}
                                    minDate={new Date()}
                                    tileDisabled={({
                                        activeStartDate,
                                        date,
                                        view,
                                    }) => calculateDisabledTiles(date)}
                                    value={selectedRange}
                                />
                            </div>
                        )}
                    </div>

                    {cantDays === 0 ? (
                        cantDays < currentProduct?.alquiler_minimo + 1 &&
                        errorDates ? (
                            <div className="p-1 mt-1 text-lg text-center text-red-400 bg-white rounded-lg">
                                {errorDates || (
                                    <div>
                                        Seleccione un mínimo de{" "}
                                        {currentProduct?.alquiler_minimo +
                                            (currentProduct.tipo_estadia ===
                                            "dia"
                                                ? 0
                                                : 1)}
                                        {" dias"}
                                    </div>
                                )}
                            </div>
                        ) : (
                            currentProduct.alquiler_minimo && (
                                <div className="p-1 mt-1 text-lg text-center text-red-400 bg-white rounded-lg">
                                    <div>
                                        Seleccione un mínimo de{" "}
                                        {currentProduct?.alquiler_minimo +
                                            (currentProduct.tipo_estadia ===
                                            "dia"
                                                ? 0
                                                : 1)}
                                        {" dias"}
                                    </div>
                                </div>
                            )
                        )
                    ) : currentProduct.alquiler_maximo > 0 &&
                      cantDays >
                          (currentProduct.tipo_estadia === "dia"
                              ? currentProduct.alquiler_maximo
                              : currentProduct.alquiler_maximo + 1) ? (
                        <div className="p-1 mt-1 text-lg text-center text-red-400 bg-white rounded-lg">
                            El alquiler máximo es de{" "}
                            {currentProduct.tipo_estadia === "dia"
                                ? currentProduct.alquiler_maximo
                                : currentProduct.alquiler_maximo + 1}
                            {" dias"}
                        </div>
                    ) : (
                        <div className="flex justify-center gap-3 w-full mt-2 mb-3 ">
                            <b className="italic">
                                Precio referencial de reserva:
                            </b>
                            <hr />
                            <span className="">
                                {pointsDiscount
                                    ? numberToCommas(
                                          getTotalReservaFinal(
                                              cantDays,
                                              selectedDays,
                                              currentProduct,
                                              aditionalServices,
                                              authUser.tipo_usuario === 6 ||
                                                  authUser.tipo_usuario === 7,
                                              getGarantiaTotal,
                                              authUser.membresia
                                          ) -
                                              getTotalReservaInicial(
                                                  cantDays,
                                                  selectedDays,
                                                  currentProduct
                                              ) -
                                              calcularDescuento(
                                                  getTotalReservaInicial(
                                                      cantDays,
                                                      selectedDays,
                                                      currentProduct
                                                  ),
                                                  currentProduct.oferta_activada,
                                                  currentProduct.oferta
                                              )
                                      )
                                    : numberToCommas(
                                          getTotalReservaFinal(
                                              cantDays,
                                              selectedDays,
                                              currentProduct,
                                              aditionalServices,
                                              authUser.tipo_usuario === 6 ||
                                                  authUser.tipo_usuario === 7,
                                              getGarantiaTotal,
                                              authUser.membresia
                                          ) -
                                              calcularDescuento(
                                                  getTotalReservaInicial(
                                                      cantDays,
                                                      selectedDays,
                                                      currentProduct
                                                  ),
                                                  currentProduct.oferta_activada,
                                                  currentProduct.oferta
                                              ) -
                                              getTotalReservaInicial(
                                                  cantDays,
                                                  selectedDays,
                                                  currentProduct,
                                                  true
                                              )
                                      )}{" "}
                                {moneda_elegida}
                            </span>
                        </div>
                    )}

                    <div className="relative grid w-10/12 grid-cols-1 gap-4 mx-auto mt-4 xs:mt-2 ">
                        <button
                            disabled={cargando}
                            onClick={() => enviar()}
                            className={` text-center w-full bg-blue-500 rounded-lg  hover:bg-blue-600 `}
                        >
                            <p
                                className={` font-semibold   min-w-max p-3 text-white mx-auto disabled:bg-blue-300`}
                            >
                                {cargando ? "Enviando..." : "Deseo reservar"}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
