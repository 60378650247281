import React, { useEffect, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PartnerAnswer from "../components/hubspotForm/PartnerAnswer";
import HelpCenter from "../components/helpCenter/HelpCenter";

import DetailedBlog from "../components/blog/DetailedBlog";
import MyAccount from "../components/myAccount/MyAccount";
import Reviews from "../components/reviews/Reviews";
import Store from "../components/ownersStore/Store";
import DashboardPropietario from "../components/dashboard/DashboardPropietario";
import Checkout from "../components/checkout/casas/Checkout";
import ReservaDetails from "../components/reservaDetails/ReservaDetails";
import PagoAprobado from "../components/checkout/paymentStatus/PagoAprobado";
import PagoRechazado from "../components/checkout/paymentStatus/PagoRechazado";
import PagoPendiente from "../components/checkout/paymentStatus/PagoPendiente";
import Favorite from "../components/alquilerScreen/Favorite";
import CheckoutEmbarcaciones from "../components/checkout/embarcaciones/CheckoutEmbarcaciones";
import ComprobarDisponibilidad from "../components/checkout/paymentStatus/ComprobarDisponibilidad";
import MPCheckout from "../components/checkout/mercadopago/MPCheckout";
import Conversation from "../components/conversation/Conversation";
import SpinnerVerifyToken from "./SpinnerVerifyToken";
import RecuerarContraseña from "../components/contraseña/RecuperarContraseña";
import BlueSale from "../components/promos/promo-blue-sale/BlueSale";
import LoyaltyTerms from "../components/termsAndConditions/LoyaltyTerms";
import OnboardingBienvenida from "../components/onboardingEmpresas/OnboardingBienvenida";
import DashboardEmpresas from "../components/dashboardEmpresas/DashboardEmpresas";
import AllEmpleadosEmpresas from "../components/usuarios/empleadosEmpresas/AllEmpleadosEmpresas";
import Membresias from "../components/membresias/Membresias";
import PerfilEmpresa from "../components/dashboardEmpresas/PerfilEmpresa";
import EmpleadoEmpresaLogin from "../components/myAccount/EmpleadoEmpresaLogin";
import updateLastTimeOnline from "../components/helpers/updateLastTimeOnline";
import PromoAnoNuevo from "../components/promos/promo-ano-nuevo/PromoAnoNuevo";
import CrearPromociones from "../components/dashboardEmpresas/promociones/CrearPromociones";
import AllPedidos from "../components/pedidos/alojamientos/AllPedidos";
import ReviewsDashboard from "../components/reviews/ReviewsDashboard";
import CheckoutMembresias from "../components/membresias/checkout/CheckoutMembresias";
import RegistroEmpresa from "../components/myAccount/RegistroEmpresa";
import AllPaquetesEmpresa from "../components/empresas/paquetesEmpresa/AllPaquetesEmpresa";
import CheckoutEmpresa from "../components/checkout/empresaCostoAsumido/CheckoutEmpresa";
import PromoVerano from "../components/promos/promo-verano/PromoVerano";
import PromoSanValentin from "../components/promos/promo-san-valentin/PromoSanValentin";
import ComprobarDisponibilidadPropietario from "../components/checkout/paymentStatus/ComprobarDisponibilidadPropietario";
import ReferidosTerms from "../components/termsAndConditions/ReferidosTerms";
import ComprobarDisponibilidadPaquetes from "../components/empresas/paquetesEmpresa/ComprobarDisponibilidadPaquetes";
import EventosCorporativos from "../components/corporative/EventosCorporativos";
import MembresiasHome from "../components/membresias/MembresiasHome";
import CheckoutPaquetesEmpresariales from "../components/checkout/paquetesEmpresariales/CheckoutPaquetesEmpresariales";

import { ListaDePaquetes } from "../components/empresas/paquetesEmpresa/ListaDePaquetes";
import { verifyToken } from "../redux/actions/authActions";
import { CheckoutPaquetesEmpresa } from "../components/empresas/paquetesEmpresa/CheckoutPaquetesEmpresa";
import { Scrapy } from "../Scrapy";
import { ListaDeServicios } from "../components/empresas/paquetesEmpresa/ListaDeServicios";
import { QuienesSomos } from "../components/quienesSomos/QuienesSomos";
import { Loyalty } from "../components/loyalty/Loyalty";
import { ResenasNew } from "../components/reviews/ResenasNew";
import { NavbarBC } from "../components/dashboard/NavbarBC";
import { NavbarPropietario } from "../components/dashboard/NavbarPropietario";

import CambiarPassword from "../components/dashboardEmpresas/CambiarPassword";
import EditEmpresa from "../components/dashboardEmpresas/EditEmpresa";
import CheckoutEmpresaAllPromo from "../components/checkout/empresaCostoAsumido/CheckoutEmpresaAllPromo";
import AlquileresTotales from "../components/dashboardEmpresas/estadisticas/AlquileresTotales";
import { LoyaltyNew } from "../components/loyalty/LoyaltyNew";
import { Referidos } from "../components/referidos/Referidos";
import Cuotealo from "../components/cuotealo/Cuotealo";
import SuccessCuotealo from "../components/cuotealo/SuccesCuotealo";
import PromoAnoNuevo2 from "../components/promos/promo-ano-nuevo-2/PromoAnoNuevo2";
import PromoHalloween from "../components/promos/promo-halloween/PromoHalloween";
import LandingDestacados from "../components/promos/landing-destacados/LandingDestacados";
import LandingCasaMario from "../components/promos/landing-casa-mario/LandingCasaMario";
const AlquilerAlojamiento = lazy(() =>
    import("../components/alquilerScreen/alquilerAlojamiento/Alquiler")
);
const Blogs = lazy(() => import("../components/blog/Blogs"));
const AlquilerEmbarcacion = lazy(() =>
    import("../components/alquilerScreen/alquilerEmbarcacion/Alquiler")
);

const Dashboard = lazy(() => import("../components/dashboard/Dashboard"));
const DetailedAlojamiento = lazy(() =>
    import("../components/detailedScreens/DetailedAlojamiento")
);

const DetailedEmbarcaciones = lazy(() =>
    import("../components/detailedScreens/DetailedEmbarcaciones")
);

const Home = lazy(() => import("../components/home/Home"));
const LoginPage = lazy(() => import("../components/login/LoginPage"));
const PoliticasDeUsoYPriv = lazy(() =>
    import("../components/politics/PoliticasDeUsoYPriv")
);

const Partner = lazy(() => import("../components/partners/Partner"));
const GeneralTerms = lazy(() =>
    import("../components/termsAndConditions/GeneralTerms")
);

const PartnerFooter = lazy(() =>
    import("../components/partners/PartnerFooter")
);

const PartnerCasas = lazy(() => import("../components/partners/PartnerCasas"));
const PartnerEmbarcaciones = lazy(() =>
    import("../components/partners/PartnerEmbarcaciones")
);

const PartnerActividades = lazy(() =>
    import("../components/partners/PartnerActividades")
);

const Contact = lazy(() => import("../components/contact/Contact"));
const HomeTerms = lazy(() =>
    import("../components/termsAndConditions/HomeTerms")
);

const BoatsTerms = lazy(() =>
    import("../components/termsAndConditions/BoatsTerms")
);

const Gastronomy = lazy(() => import("../components/gastronomy/Gastronomy"));
const Paquetes = lazy(() => import("../components/paquetes/Paquetes"));
const QuestionsAndAnswersBoats = lazy(() =>
    import("../components/questionsAndAnswers/QuestionsAndAnswersBoats")
);

const QuestionsAndAnswersHome = lazy(() =>
    import("../components/questionsAndAnswers/QuestionsAndAnswersHome")
);

const QuestionsAndAnswersActivities = lazy(() =>
    import("../components/questionsAndAnswers/QuestionsAndAnswersActivities")
);

const ContactAnswer = lazy(() =>
    import("../components/hubspotForm/ContactAnswer")
);

const AppRouter = () => {
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.authReducer);

    useEffect(() => {
        dispatch(verifyToken());
    }, [dispatch]);

    if (authUser.checking) return <SpinnerVerifyToken />;
    else {
        if (authUser.id_usuario) {
            updateLastTimeOnline(authUser.id_usuario);
        }
        return (
            <Routes>
                <Route path="quienes-somos" element={<QuienesSomos />} />
                <Route path="loyalty" element={<LoyaltyNew />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/cuotealo" element={<Cuotealo />} />
                <Route path="/successCuotealo" element={<SuccessCuotealo />} />
                <Route path="/" element={<Home />} />
                <Route
                    path="/alquiler-de-alojamientos"
                    element={<AlquilerAlojamiento />}
                />
                <Route
                    path="/alquiler-de-alojamientos/:filtros"
                    element={<AlquilerAlojamiento />}
                />
                <Route
                    path="/alojamientos/:idProduct/:productName"
                    element={<DetailedAlojamiento />}
                />
                <Route
                    path="/alojamientos/:idProduct/:productName/:fechas"
                    element={<DetailedAlojamiento />}
                />
                <Route
                    path="/embarcaciones/:idProduct/:productName"
                    element={<DetailedEmbarcaciones />}
                />
                <Route
                    path="/alquiler-de-embarcaciones"
                    element={<AlquilerEmbarcacion />}
                />
                <Route
                    path="/alquiler-de-embarcaciones/:filtros"
                    element={<AlquilerEmbarcacion />}
                />
                <Route
                    path="/politicas-de-uso-y-privacidad"
                    element={<PoliticasDeUsoYPriv />}
                />
                <Route path="aliados" element={<Partner />} />
                <Route path="membresias" element={<MembresiasHome />} />
                <Route
                    path="eventos-corporativos"
                    element={<EventosCorporativos />}
                />
                <Route
                    path="terminos-y-condiciones"
                    element={<GeneralTerms />}
                />
                <Route path="contacto" element={<Contact />} />
                <Route
                    path="/terminos-y-condiciones/casa"
                    element={<HomeTerms />}
                />
                <Route
                    path="/terminos-y-condiciones/embarcaciones"
                    element={<BoatsTerms />}
                />
                <Route
                    path="/terminos-y-condiciones/loyalty"
                    element={<LoyaltyTerms />}
                />
                <Route
                    path="/terminos-y-condiciones/referidos"
                    element={<ReferidosTerms />}
                />
                <Route path="/referidos" element={<Referidos />} />
                <Route
                    path="/conviertete-en-aliado"
                    element={<PartnerFooter />}
                />
                <Route path="/aliado-para-casas" element={<PartnerCasas />} />
                <Route
                    path="/aliado-para-embarcaciones"
                    element={<PartnerEmbarcaciones />}
                />
                <Route
                    path="/aliado-para-actividades"
                    element={<PartnerActividades />}
                />
                <Route path="/urban-kitchen" element={<Gastronomy />} />
                <Route path="/paquetes-de-contenido" element={<Paquetes />} />
                <Route
                    path="preguntas-y-respuestas/embarcaciones"
                    element={<QuestionsAndAnswersBoats />}
                />
                <Route
                    path="preguntas-y-respuestas/casas"
                    element={<QuestionsAndAnswersHome />}
                />
                <Route
                    path="preguntas-y-respuestas/actividades"
                    element={<QuestionsAndAnswersActivities />}
                />
                <Route path="contacto-gracias" element={<ContactAnswer />} />
                <Route path="gracias-aliados" element={<PartnerAnswer />} />
                <Route path="centro-de-ayuda" element={<HelpCenter />} />
                <Route path="blog" element={<Blogs />} />
                <Route path="/blog/:idBlog" element={<DetailedBlog />} />
                <Route path="/reviews/:tipo/:idPedido" element={<Reviews />} />
                <Route
                    path="/resena/:idPedido/:puntuacion"
                    element={<ResenasNew />}
                />
                <Route path="/tienda/:idOwner/:slugStore" element={<Store />} />
                <Route path="/checkout/" element={<Checkout />} />
                <Route path="/checkout/:idReserva" element={<Checkout />} />
                <Route
                    path="/checkout-corporativo/:idReserva/:tipoPedido"
                    element={<CheckoutEmpresa />}
                />{" "}
                <Route
                    path="/checkout-promo-corporativo/:idEmpresa"
                    element={<CheckoutEmpresaAllPromo />}
                />
                <Route
                    path="/checkout-embarcacion/"
                    element={<CheckoutEmbarcaciones />}
                />
                <Route
                    path="/checkout-embarcacion/:idReserva"
                    element={<CheckoutEmbarcaciones />}
                />
                <Route
                    path="/detalles-de-reserva/:idReserva"
                    element={<ReservaDetails />}
                />
                <Route
                    path="/pago_aprobado/:tipo/:idPedido"
                    element={<PagoAprobado />}
                />
                <Route
                    path="/recuperar_cuenta/:token/:id"
                    element={<RecuerarContraseña />}
                />
                <Route
                    path="/pago_rechazado/mercadopago"
                    element={<PagoRechazado />}
                />
                <Route
                    path="/pago_pendiente/mercadopago"
                    element={<PagoPendiente />}
                />
                <Route
                    path="/mercadopago-checkout/:tipo_producto/:idReserva"
                    element={<MPCheckout />}
                />
                <Route path="/blue-sale" element={<BlueSale />} />
                {/* <Route path="/promo-ano-nuevo" element={<PromoAnoNuevo />} /> */}
                <Route
                    path="/promo-semana-santa"
                    element={<PromoSanValentin />}
                />
                <Route
                    path="/casas-exclusivas"
                    element={<LandingDestacados />}
                />
                <Route
                    path="/casas-exclusivas/:filtros"
                    element={<LandingDestacados />}
                />
                {/*  <Route path="/promo-ano-nuevo" element={<PromoAnoNuevo2 />} />*/}
                {/* <Route path="/promo-halloween" element={<PromoHalloween />} /> */}
                <Route path="/promo-verano" element={<PromoVerano />} />
                <Route path="/casa-mario" element={<LandingCasaMario />} />
                <Route
                    path="/comprobando-disponibilidad"
                    element={<ComprobarDisponibilidad />}
                />
                <Route
                    path="/comprobando-disponibilidad/:disponible/:idReserva"
                    element={<ComprobarDisponibilidadPropietario />}
                />
                <Route
                    path="/comprobando-disponibilidad-paquetes"
                    element={<ComprobarDisponibilidadPaquetes />}
                />
                <Route
                    path="/my-account/:idEmpresa/:nameEmpresa"
                    element={<EmpleadoEmpresaLogin />}
                />
                <Route
                    path="/my-account-empresa"
                    element={<RegistroEmpresa />}
                />
                {authUser.logged &&
                    (authUser.tipo_usuario === 6 ||
                        authUser.tipo_usuario === 1 ||
                        authUser.tipo_usuario === 2) && (
                        <>
                            <Route
                                path="/dashboard-empresas/paquetes/checkout/:idPedido"
                                element={<CheckoutPaquetesEmpresariales />}
                            />
                            <Route
                                path="/dashboard-empresas"
                                element={<DashboardEmpresas />}
                            />
                            <Route
                                path="/dashboard-empresas/usuarios"
                                element={<AllEmpleadosEmpresas />}
                            />
                            <Route
                                path="/dashboard-empresas/promociones"
                                element={<CrearPromociones />}
                            />
                            <Route
                                path="/dashboard-empresas/estadisticas"
                                element={<AlquileresTotales />}
                            />
                            <Route
                                path="/dashboard-empresas/membresias"
                                element={<Membresias />}
                            />
                            <Route
                                path="/dashboard-empresas/paquetes"
                                element={<AllPaquetesEmpresa />}
                            />
                            <Route
                                path="/dashboard-empresas/checkout/:idEmpresa/:tipoMembresia"
                                element={<CheckoutMembresias />}
                            />
                        </>
                    )}
                {authUser.logged && authUser.tipo_usuario > 5 && (
                    <>
                        <Route
                            path="/dashboard-empresas/perfil"
                            element={<PerfilEmpresa />}
                        />
                        <Route
                            path="/dashboard-empresas/reservaciones"
                            element={<AllPedidos empresa={true} />}
                        />
                        <Route
                            path="/dashboard-empresas/resenas"
                            element={<ReviewsDashboard empresa={true} />}
                        />
                        <Route
                            path="/dashboard-empresas/cambiar-password"
                            element={<CambiarPassword />}
                        />
                        <Route
                            path="/dashboard-empresas/editar-empresa"
                            element={<EditEmpresa />}
                        />
                        <Route
                            path="/dashboard-empresas/favoritos"
                            element={
                                <Favorite
                                    empresa={true}
                                    desdeDashboard={true}
                                />
                            }
                        />
                    </>
                )}
                {/* {!authUser.logged && <Route path="/my-account" element={<MyAccount />} />} */}
                <Route path="/my-account" element={<MyAccount />} />
                <Route
                    path="/my-account/register"
                    element={<MyAccount state={true} />}
                />
                {authUser.logged && authUser.tipo_usuario < 3 && (
                    <>
                        <Route
                            path="/dashboardss/:select"
                            element={<Dashboard />}
                        />
                        <Route path="/dashboard" element={<NavbarBC />} />
                        <Route
                            path="/dashboard/:select"
                            element={<NavbarBC />}
                        />
                        <Route
                            path="/dashboard/:select/:idEmpresa/:nameEmpresa"
                            element={<NavbarBC />}
                        />
                    </>
                )}
                {authUser.logged && authUser.tipo_usuario < 5 && (
                    <>
                        <Route
                            path="/dashboard/:select/editar/:tipoLink/:idEmpresa"
                            element={<NavbarBC />}
                        />
                        <Route
                            path="/perfil/:select/editar/:tipoLink/:idEmpresa"
                            element={<NavbarPropietario />}
                        />
                    </>
                )}
                {authUser.logged && (
                    <>
                        <Route path="/favoritos" element={<Favorite />} />
                        <Route
                            path="/paquetes-empresariales"
                            element={<ListaDePaquetes />}
                        />
                        <Route
                            path="/paquetes-empresariales/servicios"
                            element={<ListaDeServicios />}
                        />
                        <Route
                            path="/paquetes-empresariales/checkout"
                            element={<CheckoutPaquetesEmpresa />}
                        />
                    </>
                )}
                {authUser.logged &&
                    authUser.tipo_usuario > 2 &&
                    authUser.tipo_usuario < 6 && (
                        <>
                            {/* <Route
                                path="/perfil/:select"
                                element={<DashboardPropietario />}
                            /> */}

                            <Route
                                path="/perfil/:select"
                                element={<NavbarPropietario />}
                            />
                        </>
                    )}
                <>
                    <Route
                        path="/conversacion/:tipo/:id_pedido"
                        element={<Conversation />}
                    />
                </>
                <Route path="scrapy" element={<Scrapy />} />
                {/* Rutas para empresas*/}
                <Route path="/miEmpresa" element={<OnboardingBienvenida />} />
                {/* Rutas para empresas*/}
                {/* //Redirect al home en cualquier otra ruta, dejar al Final de las Route */}
                <Route path="/*" element={<Navigate to="/" replace />} />
            </Routes>
        );
    }
};

export default AppRouter;
