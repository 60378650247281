import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import BLUECORNER_PNG from "../img/Home/Logo_azul_horizontal.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/actions/authActions";
import NavBarAdministrativo from "./NavBarAdministrativo";

import imagenOfertas from "../img/Home/image-ofertas.png";
import { Swiper, SwiperSlide } from "swiper/react";

import useColores from "../hooks/useColores";
import { es } from "date-fns/locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, set } from "date-fns";
import AutoComplete from "./home_old/AutoComplete";
import axios from "axios";
import ModalLogeo from "./alquilerScreen/alquilerAlojamiento/ModalLogeo";

const NavBarHome = ({ landing = "home", explorer = true }) => {
    const monedaStorage = localStorage.getItem("moneda");
    const reserva = localStorage.getItem("carrito") || null;
    if (!monedaStorage) {
        localStorage.setItem("moneda", "PEN");
    }

    const { filtros } = useParams();

    let filtrosSplit = filtros?.split("&");

    const navigate = useNavigate();
    const [modalLogeo, setModalLogeo] = useState(false);

    const [burguerMenu, setBurguerMenu] = useState(false);
    const [perfil, setPerfil] = useState(false);
    const [modalMoneda, setModalMoneda] = useState(false);
    const [tipoMoneda, setTipoMoneda] = useState(
        monedaStorage ? monedaStorage : "PEN"
    );
    const [background, setBackground] = useState(null);
    const [logoEmpresa, setLogoEmpresa] = useState(null);
    const [text, setText] = useState("");
    const [textType, setTextType] = useState("");
    const [personas, setPersonas] = useState({
        niños: 0,
        adultos: 0,
    });
    const dispatch = useDispatch();
    const debounceInput = useRef(null);

    const [coordinates, setCoordinates] = useState({
        lat: -12.050653,
        lng: -76.8467069,
        zoom: 10,
    });

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDateEmbarcacion, setStartDateEmbarcacion] = useState(null);
    const [startDate, endDate] = dateRange;
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [isOpenHuespedes, setIsOpenHuespedes] = useState(false);
    const [fields, setFields] = useState([]);

    const handleDateChange = (dates) => {
        if (landing === "embarcacion") {
            setStartDateEmbarcacion(dates); // Guarda solo una fecha
            setIsOpenCalendar(false); // Cierra el DatePicker después de seleccionar
        } else {
            const [start, end] = dates;
            setDateRange(dates);
            if (start && end) {
                setIsOpenCalendar(false); // Cierra el DatePicker después de seleccionar ambas fechas
            }
        }
    };

    const filtrosObjeto = (filtros) => {
        if (landing === "embarcacion") {
            let objeto = {
                duracion: filtros[3]?.split("=")[1].split(","),
                fecha: filtros[2].split("=")[1],
                id_usuario: filtros[5].split("=")[1],
                numero_personas: filtros[4].split("=")[1],
                pagina: filtros[6].split("=")[1],
                query_tipo: filtros[0].split("=")[1],
                query_value: filtros[1].split("=")[1],
                tipo_embarcacion: filtros[7].split("=")[1],
            };

            setPersonas({
                ...personas,
                adultos: Number(objeto.numero_personas),
            });
            setText(objeto.query_value);
            setTextType(objeto.query_tipo);

            if (objeto.fecha) {
                setStartDateEmbarcacion(new Date(objeto.fecha + "T00:00:00"));
            }
        } else if (landing === "destacados") {
            let objeto = {
                fecha_final: filtros[0].split("=")[1],
                fecha_inicio: filtros[1].split("=")[1],
                numero_personas: filtros[2].split("=")[1],
                numero_noches: filtros[3]?.split("=")[1],
            };

            setPersonas({
                ...personas,
                adultos: Number(objeto.numero_personas),
            });

            if (objeto.fecha_inicio && objeto.fecha_final) {
                setDateRange([
                    new Date(objeto.fecha_inicio + "T00:00:00"),
                    new Date(objeto.fecha_final + "T00:00:00"),
                ]);
            }
        } else {
            let objeto = {
                query_tipo: filtros[0].split("=")[1],
                query_value: filtros[1].split("=")[1],
                fecha_final: filtros[2].split("=")[1],
                fecha_inicio: filtros[3].split("=")[1],
                numero_banos: filtros[4].split("=")[1],
                numero_camas: filtros[5].split("=")[1],
                numero_habitaciones: filtros[6].split("=")[1],
                numero_personas: filtros[7].split("=")[1],
                id_usuario: filtros[8].split("=")[1],
                pagina: filtros[9].split("=")[1],
                tipo_alojamiento: filtros[10].split("=")[1],
                atributos: filtros[11]?.split("=")[1].split(","),
                reglas: filtros[12]?.split("=")[1].split(","),
                ubicacion: filtros[13]?.split("=")[1].split(","),
                precio_rango: filtros[14].split("=")[1],
                tipo_moneda: filtros[15].split("=")[1],
                order: filtros[16].split("=")[1],
                mayor_menor: filtros[17].split("=")[1],
                latitud: filtros[18]?.split("=")[1],
                longitud: filtros[19]?.split("=")[1],
                zoom: filtros[20]?.split("=")[1],
                numero_noches: filtros[21]?.split("=")[1],
            };

            setPersonas({
                ...personas,
                adultos: Number(objeto.numero_personas),
            });
            setText(objeto.query_value);
            setTextType(objeto.query_tipo);

            setCoordinates({
                lat: objeto.latitud ? Number(objeto.latitud) : -12.050653,
                lng: objeto.longitud ? Number(objeto.longitud) : -76.8467069,
                zoom: objeto.zoom ? Number(objeto.zoom) : 10,
            });

            if (objeto.fecha_inicio && objeto.fecha_final) {
                setDateRange([
                    new Date(objeto.fecha_inicio + "T00:00:00"),
                    new Date(objeto.fecha_final + "T00:00:00"),
                ]);
            }
        }
    };

    const handleInputChangeDebounce = (e) => {
        setText(e.target.value);
        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") setFields([]);
        debounceInput.current = setTimeout(async () => {
            if (landing === "embarcacion") {
                const { data } = await axios(
                    `${
                        process.env.REACT_APP_PROXY
                    }/${"embarcacion"}/autocomplete?autocomplete=${
                        e.target.value
                    }`
                );
                setFields(data);
            } else {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/${"casa"}/autocomplete`,
                    {
                        query: `${e.target.value}`,
                        id_usuario: authUser.id_usuario
                            ? authUser.id_usuario
                            : 0,
                    }
                );
                console.log(data);
                setFields(data);
            }
        }, 500);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setFields([]);
        }, 200);
    };

    const formatDate = (date) => {
        return date ? format(date, "yyyy-MM-dd") : "";
    };

    const formatDisplayDate = (date) => {
        return date
            ? format(date, "dd MMM yyyy", { locale: es })
            : "Agregar Fecha";
    };

    const { idEmpresa } = useParams();
    const getLogoEmpresa = localStorage.getItem("logo_empresa");
    const getBackgroundEmpresa = localStorage.getItem("background_navbar");

    const authUser = useSelector((state) => state.authReducer);
    const { primario, secundario, setColores } = useColores();

    const [bannerVisible, setBannerVisible] = useState(true);

    const cambioDeMoneda = (moneda) => {
        localStorage.setItem("moneda", moneda);
        setTipoMoneda(moneda);
        setModalMoneda(false);
        window.location.reload();
    };

    useEffect(() => {
        if (burguerMenu) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }

        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [burguerMenu]);

    useLayoutEffect(() => {
        if (idEmpresa) {
            setBackground(getBackgroundEmpresa);
            setLogoEmpresa(getLogoEmpresa);
        }
    }, []);

    const handleFavorito = () => {
        if (authUser.logged === false) {
            /*  setHabilitarModal(true); */
            setModalLogeo(true);
        } else {
            navigate("/favoritos");
        }
    };

    const params = () => {
        let url = "";
        if (landing === "embarcacion") {
            let formattedStartDate = "";

            if (startDateEmbarcacion) {
                const start = new Date(startDateEmbarcacion);

                formattedStartDate = start.toISOString().split("T")[0];
            }

            let filters = {
                duracion: [],
                fecha: formattedStartDate,
                id_usuario: authUser.id_usuario ? authUser.id_usuario : 0,
                numero_personas:
                    Number(personas.adultos) + Number(personas.niños),
                pagina: 1,
                query_tipo: textType,
                query_value: text,
                tipo_embarcacion: 0,
            };

            url = `query_tipo=${filters.query_tipo}&query_value=${
                filters.query_value
            }&fecha=${filters.fecha}&duracion=${
                filters.duracion
            }&numero_personas=${filters.numero_personas}&id=${
                filters.id_usuario ? filters.id_usuario : 0
            }&pagina=${filters.pagina}&tipo_embarcacion=${
                filters.tipo_embarcacion
            }`;
        } else if (landing === "destacados") {
            let formattedStartDate = "";
            let formattedEndDate = "";
            let numeroNoches = 0;

            if (startDate && endDate) {
                const start = new Date(startDate);
                const end = new Date(endDate);

                const diffTime = Math.abs(end - start);
                numeroNoches = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                // Formatear fechas a YYYY-MM-DD
                formattedStartDate = start.toISOString().split("T")[0];
                formattedEndDate = end.toISOString().split("T")[0];
            }

            let filters = {
                fecha_inicio: formattedStartDate,
                fecha_final: formattedEndDate,

                numero_personas: 0,
                adultos: personas.adultos,
                ninos: personas.niños,
                numero_noches: numeroNoches,
            };

            url = `fecha_final=${
                endDate ? filters.fecha_final : ""
            }&fecha_inicio=${
                startDate ? filters.fecha_inicio : ""
            }&numero_personas=${
                filters.adultos + filters.ninos
            }&numero_noches=${filters.numero_noches}`;
        } else {
            let formattedStartDate = "";
            let formattedEndDate = "";
            let numeroNoches = 0;

            if (startDate && endDate) {
                const start = new Date(startDate);
                const end = new Date(endDate);

                const diffTime = Math.abs(end - start);
                numeroNoches = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                // Formatear fechas a YYYY-MM-DD
                formattedStartDate = start.toISOString().split("T")[0];
                formattedEndDate = end.toISOString().split("T")[0];
            }

            let filters = {
                fecha_inicio: formattedStartDate,
                fecha_final: formattedEndDate,
                query_value: "",
                query_tipo: "",
                numero_personas: 0,
                numero_habitaciones: 0,
                numero_camas: 0,
                numero_banos: 0,
                precio_rango: 0,
                tipo_moneda: "",
                atributos: [],
                reglas: [],
                ubicacion: [],
                tipo_alojamiento: 0,
                pagina: 1,
                order: "precio",
                latitud: coordinates.lat,
                longitud: coordinates.lng,
                zoom: coordinates.zoom,
                adultos: personas.adultos,
                ninos: personas.niños,
                numero_noches: numeroNoches,
            };

            url = `query_tipo=${textType}&query_value=${text}&fecha_final=${
                endDate ? filters.fecha_final : ""
            }&fecha_inicio=${
                startDate ? filters.fecha_inicio : ""
            }&numero_banos=0&numero_camas=0&numero_habitaciones=0&numero_personas=${
                filters.adultos + filters.ninos
            }&id=${
                authUser.id_usuario ? authUser.id_usuario : 0
            }&pagina=1&tipo_alojamiento=${filters.tipo_alojamiento}&atributos=${
                filters.atributos
            }&reglas=${filters.reglas}&ubicacion=${
                filters.ubicacion
            }&precio_rango=${filters.precio_rango}&tipo_moneda=${
                filters.tipo_moneda
            }&order=${filters.order}&mayor_menor=&latitud=${
                filters.latitud
            }&longitud=${filters.longitud}&zoom=10&numero_noches=${
                filters.numero_noches
            }`;
        }

        return url;
    };

    const handleBusqueda = () => {
        setIsOpenCalendar(false);
        setIsOpenHuespedes(false);
        setFields([]);

        if (landing === "home" || landing === "alojamientos") {
            navigate("/alquiler-de-alojamientos/" + params());
        }

        if (landing === "embarcacion") {
            navigate("/alquiler-de-embarcaciones/" + params());
        }

        if (landing === "destacados") {
            navigate("/casas-exclusivas/" + params());
        }
    };

    useEffect(() => {
        if (filtrosSplit?.length === 22 && landing !== "embarcacion") {
            filtrosObjeto(filtrosSplit);
        }

        if (filtros && landing === "embarcacion") {
            filtrosObjeto(filtrosSplit);
        }
    }, [filtros]);

    return (
        <nav className="select-none">
            {authUser.tipo_usuario !== null && authUser.tipo_usuario <= 2 && (
                <NavBarAdministrativo
                    link={"/dashboard/anuncios"}
                    text={"DASHBOARD"}
                />
            )}

            <div className="absolute w-full ">
                {modalLogeo && (
                    <ModalLogeo
                        modalLogeo={modalLogeo}
                        setModalLogeo={setModalLogeo}
                    />
                )}
            </div>

            <div className="flex items-center justify-between w-full gap-4 p-4 mx-auto text-xs bg-white xl:w-10/12 lg:w-11/12 2xl:text-base lg:text-sm ">
                <div
                    onClick={() => {
                        navigate("/");
                    }}
                    className="mr-3 cursor-pointer"
                >
                    <img
                        src={BLUECORNER_PNG}
                        alt="Blue Corner"
                        className="h-auto md818:w-36 w-28"
                    />
                </div>

                <div className="items-center justify-center hidden gap-4 md818:flex 2xl:gap-10 xl:gap-6">
                    {" "}
                    <div
                        onClick={() => {
                            navigate(
                                "/alquiler-de-alojamientos/query_tipo=&query_value=Campo&fecha_final=&fecha_inicio=&numero_banos=0&numero_camas=0&numero_habitaciones=0&numero_personas=0&id=5&pagina=1&tipo_alojamiento=0&atributos=&reglas=&ubicacion=&precio_rango=0&tipo_moneda=&order=precio&mayor_menor=&latitud=0&longitud=0&zoom=10&numero_noches=0"
                            );
                        }}
                        className=" text-[#0F80F2] font-semibold cursor-pointer "
                    >
                        Casas de Campo
                    </div>
                    <div
                        onClick={() => {
                            navigate(
                                "/alquiler-de-alojamientos/query_tipo=&query_value=Playa&fecha_final=&fecha_inicio=&numero_banos=0&numero_camas=0&numero_habitaciones=0&numero_personas=0&id=5&pagina=1&tipo_alojamiento=0&atributos=&reglas=&ubicacion=&precio_rango=0&tipo_moneda=&order=precio&mayor_menor=&latitud=0&longitud=0&zoom=10&numero_noches=0"
                            );
                        }}
                        className=" text-[#0F80F2] font-semibold cursor-pointer "
                    >
                        Casas de Playa
                    </div>
                    <div
                        onClick={() => {
                            navigate("/alquiler-de-embarcaciones/");
                        }}
                        className=" text-[#0F80F2] font-semibold cursor-pointer "
                    >
                        Embarcaciones
                    </div>
                    <div
                        onClick={() => {
                            navigate("/blog");
                        }}
                        className=" text-[#0F80F2] font-semibold cursor-pointer "
                    >
                        Blog
                    </div>
                    {/* <div className="text-[#0F80F2] font-semibold flex justify-center items-center gap-1 bg-[#0F80F2] bg-opacity-20 rounded-full py-1 px-4 ">
                        <img src={imagenOfertas} alt="imagen ofertas" />
                        Ofertas
                    </div> */}
                </div>

                <div className="flex items-center justify-center gap-3 2xl:gap-4 md:gap-2">
                    {!authUser.logged && (
                        <div
                            onClick={() => setModalLogeo(true)}
                            className="flex cursor-pointer items-center justify-center gap-1 rounded-full bg-[#0F80F2] px-3 py-1 bg-opacity-10"
                        >
                            <svg
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_111_453)">
                                    <path
                                        d="M0.5 13.3157C0.5 13.6944 0.813817 14 1.20258 14H13.7974C14.1862 14 14.5 13.6944 14.5 13.3157C14.5 10.4373 12.6546 7.97393 10.055 6.97491C10.9262 6.28152 11.493 5.23688 11.5351 4.06908C11.6101 1.92506 9.81616 0.0638639 7.61475 -5.37839e-07C5.41335 -0.063865 3.46019 1.7289 3.46019 3.93222C3.46019 5.15933 4.0363 6.25415 4.94496 6.97491C2.34543 7.97393 0.5 10.4373 0.5 13.3157ZM4.93091 4.52069C4.5 2.65037 6.1815 1.01271 8.10187 1.43239C9.06674 1.64223 9.84895 2.40404 10.0644 3.34376C10.4906 5.20951 8.81382 6.84718 6.89813 6.43206C5.93326 6.22222 5.15105 5.46497 4.9356 4.52069H4.93091ZM7.49766 7.86445C10.3454 7.86445 12.7014 9.94461 13.048 12.6269H1.94731C2.29391 9.94461 4.64988 7.86445 7.49766 7.86445Z"
                                        fill="#053465"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_111_453">
                                        <rect
                                            width="14"
                                            height="14"
                                            fill="white"
                                            transform="translate(0.5)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className="text-[#053465] ">
                                Inicia Sesión
                            </span>
                        </div>
                    )}

                    {authUser.logged && (
                        <div className="items-center text-center  sm:justify-between z-[999] ">
                            <div
                                onMouseOut={() => (
                                    setPerfil(false), setBurguerMenu(false)
                                )}
                                onMouseOver={() => (
                                    setPerfil(true), setBurguerMenu(false)
                                )}
                                onClick={() => (
                                    setPerfil(!perfil), setBurguerMenu(false)
                                )}
                                className="relative block cursor-pointer"
                            >
                                <img
                                    src={`${process.env.REACT_APP_PROXY}/autenticacion/getImage/${authUser.avatar}`}
                                    className={`rounded-full w-10 h-10 border-[2px] object-cover cursor-pointer border-[#307FE2] `}
                                    alt="foto de perfil"
                                />

                                <div
                                    className={
                                        perfil
                                            ? `absolute top-6 md723:top-6 w-[166px] z-[999]  pt-5 right-0 text-[${primario}]`
                                            : "hidden"
                                    }
                                >
                                    <div
                                        className={
                                            perfil
                                                ? "block bg-white  top-16 md723:top-16 py-2 rounded-lg shadow-md"
                                                : "hidden"
                                        }
                                    >
                                        <div
                                            onClick={() => {
                                                authUser.tipo_usuario !==
                                                    null &&
                                                authUser.tipo_usuario <= 2
                                                    ? navigate(
                                                          "/dashboard/datos"
                                                      )
                                                    : authUser.tipo_usuario < 6
                                                    ? navigate("/perfil/datos")
                                                    : navigate(
                                                          "/dashboard-empresas/perfil"
                                                      );
                                            }}
                                            className={` w-40 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4`}
                                        >
                                            <p>Mi perfil</p>
                                        </div>
                                        {authUser.tipo_usuario === 6 &&
                                            authUser.tipo_usuario !== null && (
                                                <div
                                                    onClick={() => {
                                                        navigate(
                                                            "/dashboard-empresas/editar-empresa"
                                                        );
                                                    }}
                                                    className={` w-40 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4`}
                                                >
                                                    <p>Mi empresa</p>
                                                </div>
                                            )}
                                        <div
                                            onClick={() => {
                                                authUser.tipo_usuario < 6
                                                    ? navigate(
                                                          "/perfil/reservaciones"
                                                      )
                                                    : navigate(
                                                          "/dashboard-empresas/reservaciones"
                                                      );
                                            }}
                                            className={
                                                authUser.tipo_usuario > 2
                                                    ? ` w-40 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4`
                                                    : "hidden"
                                            }
                                        >
                                            <p>Alquileres</p>
                                        </div>

                                        <hr
                                            className={`w-40 mx-auto border-[${primario}] my-1`}
                                        ></hr>

                                        <div
                                            onClick={() => (
                                                setPerfil(false),
                                                dispatch(logoutUser())
                                            )}
                                            className={` w-40 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-4`}
                                        >
                                            <p>Cerrar sesión</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div
                        onClick={handleFavorito}
                        className="flex cursor-pointer items-center justify-center gap-1 rounded-full bg-[#0F80F2] px-1.5 py-1.5 bg-opacity-10"
                    >
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.92883 15.375C8.83818 15.3759 8.74888 15.353 8.66985 15.3086C8.3843 15.1492 1.75696 11.384 1.75696 6.60939C1.75696 5.77314 2.02008 4.95809 2.50904 4.27969C2.998 3.60128 3.68803 3.09393 4.48136 2.82948C5.2747 2.56503 6.13113 2.55691 6.92934 2.80625C7.72756 3.05559 8.42708 3.54976 8.92883 4.21877C9.43058 3.54976 10.1301 3.05559 10.9283 2.80625C11.7265 2.55691 12.583 2.56503 13.3763 2.82948C14.1696 3.09393 14.8597 3.60128 15.3486 4.27969C15.8376 4.95809 16.1007 5.77314 16.1007 6.60939C16.1007 8.64142 14.9253 10.7266 12.6077 12.8117C11.5554 13.7578 10.4095 14.5944 9.18782 15.3086C9.10879 15.353 9.01948 15.3759 8.92883 15.375ZM5.74133 3.68752C4.9664 3.68752 4.22321 3.99535 3.67526 4.54331C3.1273 5.09127 2.81946 5.83446 2.81946 6.60939C2.81946 10.275 7.73352 13.4957 8.92883 14.2262C10.1241 13.4957 15.0382 10.275 15.0382 6.60939C15.0388 5.93392 14.8054 5.27911 14.3776 4.75635C13.9499 4.23359 13.3542 3.87519 12.692 3.74212C12.0297 3.60904 11.3419 3.70952 10.7454 4.02646C10.1489 4.3434 9.6806 4.85721 9.42024 5.48048C9.38021 5.57792 9.31213 5.66126 9.22463 5.71991C9.13713 5.77856 9.03417 5.80988 8.92883 5.80988C8.8235 5.80988 8.72054 5.77856 8.63304 5.71991C8.54554 5.66126 8.47745 5.57792 8.43743 5.48048C8.21631 4.94808 7.84197 4.4934 7.36193 4.17416C6.8819 3.85493 6.31782 3.68554 5.74133 3.68752Z"
                                fill="#053465"
                            />
                        </svg>
                    </div>

                    <div
                        className={`${reserva && " cursor-pointer"} relative`}
                        onClick={() => reserva && navigate(reserva)}
                    >
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.42891 24.3572C6.76105 24.3572 6.18932 24.1194 5.71373 23.6438C5.23813 23.1682 5.00033 22.5965 5.00033 21.9286C5.00033 21.2607 5.23813 20.689 5.71373 20.2134C6.18932 19.7378 6.76105 19.5 7.42891 19.5C8.09677 19.5 8.66849 19.7378 9.14409 20.2134C9.61968 20.689 9.85748 21.2607 9.85748 21.9286C9.85748 22.5965 9.61968 23.1682 9.14409 23.6438C8.66849 24.1194 8.09677 24.3572 7.42891 24.3572ZM19.5718 24.3572C18.9039 24.3572 18.3322 24.1194 17.8566 23.6438C17.381 23.1682 17.1432 22.5965 17.1432 21.9286C17.1432 21.2607 17.381 20.689 17.8566 20.2134C18.3322 19.7378 18.9039 19.5 19.5718 19.5C20.2396 19.5 20.8114 19.7378 21.287 20.2134C21.7625 20.689 22.0003 21.2607 22.0003 21.9286C22.0003 22.5965 21.7625 23.1682 21.287 23.6438C20.8114 24.1194 20.2396 24.3572 19.5718 24.3572ZM6.39676 4.92859L9.31105 11H17.8111L21.1503 4.92859H6.39676ZM5.24319 2.50002H23.1539C23.6194 2.50002 23.9736 2.70746 24.2164 3.12234C24.4593 3.53722 24.4694 3.95716 24.2468 4.38216L19.9361 12.1536C19.7134 12.5584 19.4149 12.872 19.0405 13.0947C18.6661 13.3173 18.2563 13.4286 17.8111 13.4286H8.76462L7.42891 15.8572H22.0003V18.2857H7.42891C6.51819 18.2857 5.8301 17.886 5.36462 17.0866C4.89914 16.2872 4.87891 15.4929 5.30391 14.7036L6.94319 11.7286L2.57176 2.50002H0.143188V0.0714417H4.08962L5.24319 2.50002Z"
                                fill="#053465"
                            />
                        </svg>
                        <div
                            className={`text-xs bg-[#0F80F2] rounded-full absolute w-4 h-4 flex justify-center items-center -top-2 -right-2 text-white  `}
                        >
                            {reserva && (
                                <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
                            )}
                            {reserva ? 1 : 0}
                        </div>
                    </div>
                </div>
            </div>

            <div className="  flex flex-col  items-center justify-center gap-4 bg-white xl:w-10/12 lg:w-11/12 w-full mx-auto px-4 md818:py-2  text-sm   md818:border-t border-[#053465] ">
                {explorer && (
                    <div className="relative items-center justify-center hidden px-1 py-2 mx-auto mb-5 rounded-lg shadow-lg max-w-fit md818:flex ">
                        {isOpenCalendar && (
                            <div className="absolute z-50 p-2 -translate-x-1/2 bg-white rounded-lg shadow-lg md920:top-14 top-24 left-1/2 w-max">
                                <DatePicker
                                    locale={es}
                                    selectsRange={landing !== "embarcacion"}
                                    selected={
                                        landing === "embarcacion"
                                            ? startDate
                                            : undefined
                                    }
                                    startDate={
                                        landing !== "embarcacion"
                                            ? startDate
                                            : undefined
                                    }
                                    endDate={
                                        landing !== "embarcacion"
                                            ? endDate
                                            : undefined
                                    }
                                    onChange={handleDateChange}
                                    monthsShown={
                                        landing !== "embarcacion" ? 2 : 1
                                    }
                                    inline
                                />
                            </div>
                        )}

                        {isOpenHuespedes && (
                            <div className="absolute right-0 z-50 p-4 bg-white rounded-lg shadow-lg md920:top-14 top-24">
                                <div className="flex flex-col items-center justify-start gap-5 w-72 ">
                                    <div className="flex items-start justify-between w-full ">
                                        <div className="flex flex-col items-start justify-center">
                                            <div className="text-[#053465] text-base font-bold ">
                                                Adultos
                                            </div>
                                            <div className="text-xs text-gray-500 ">
                                                De: 13 años o más
                                            </div>
                                        </div>

                                        <div className="flex justify-center items-center p-1.5 rounded-full border  font-semibold border-[#0F80F2] gap-3">
                                            <div
                                                onClick={() =>
                                                    setPersonas({
                                                        ...personas,
                                                        adultos:
                                                            personas.adultos > 0
                                                                ? personas.adultos -
                                                                  1
                                                                : 0,
                                                    })
                                                }
                                                className="flex justify-center items-center font-semibold cursor-pointer text-xl rounded-full w-6 h-6 bg-[#D9D9D9] text-[#424242] "
                                            >
                                                <svg
                                                    width="12"
                                                    height="3"
                                                    viewBox="0 0 12 3"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.5585 1.41779H1.1637"
                                                        stroke="#424242"
                                                        strokeWidth="1.87897"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="text-[#0F80F2] font-semibold">
                                                {personas.adultos}
                                            </div>
                                            <div
                                                onClick={() =>
                                                    setPersonas({
                                                        ...personas,
                                                        adultos:
                                                            personas.adultos +
                                                            1,
                                                    })
                                                }
                                                className="flex justify-center items-center font-semibold cursor-pointer rounded-full w-6 h-6 bg-[#D9D9D9] text-[#424242]"
                                            >
                                                <svg
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 13 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M6.47245 11.1152V1.7204M11.1699 6.41782H1.77502"
                                                        stroke="#424242"
                                                        strokeWidth="1.87897"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-start justify-between w-full ">
                                        <div className="flex flex-col items-start justify-center">
                                            <div className="text-[#053465] text-base font-bold ">
                                                Niños
                                            </div>
                                            <div className="text-xs text-gray-500 ">
                                                De: 0 años a 12 años
                                            </div>
                                        </div>

                                        <div className="flex justify-center items-center p-1.5 rounded-full border  font-semibold border-[#0F80F2] gap-3">
                                            <div
                                                onClick={() =>
                                                    setPersonas({
                                                        ...personas,
                                                        niños:
                                                            personas.niños > 0
                                                                ? personas.niños -
                                                                  1
                                                                : 0,
                                                    })
                                                }
                                                className="flex justify-center items-center font-semibold cursor-pointer text-xl rounded-full w-6 h-6 bg-[#D9D9D9] text-[#424242] "
                                            >
                                                <svg
                                                    width="12"
                                                    height="3"
                                                    viewBox="0 0 12 3"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.5585 1.41779H1.1637"
                                                        stroke="#424242"
                                                        strokeWidth="1.87897"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="text-[#0F80F2] font-semibold">
                                                {personas.niños}
                                            </div>
                                            <div
                                                onClick={() =>
                                                    setPersonas({
                                                        ...personas,
                                                        niños:
                                                            personas.niños + 1,
                                                    })
                                                }
                                                className="flex justify-center items-center font-semibold cursor-pointer rounded-full w-6 h-6 bg-[#D9D9D9] text-[#424242]"
                                            >
                                                <svg
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 13 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M6.47245 11.1152V1.7204M11.1699 6.41782H1.77502"
                                                        stroke="#424242"
                                                        strokeWidth="1.87897"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {fields.length > 0 && (
                            <AutoComplete
                                fields={fields}
                                backGroundStyle="hover:bg-slate-200 "
                                setInput={setText}
                                setInputType={setTextType}
                                type={
                                    landing === "embarcacion" ? "boat" : "house"
                                }
                            />
                        )}
                        {landing !== "destacados" && (
                            <div className="flex items-center justify-center gap-1 px-5 ">
                                <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.6692 9.05763C17.6692 13.5162 13.8813 20.9692 11.2553 20.9692C8.62925 20.9692 4.84137 13.5162 4.84137 9.05763C4.84137 5.51532 7.71298 2.64371 11.2553 2.64371C14.7976 2.64371 17.6692 5.51532 17.6692 9.05763Z"
                                        stroke="#053465"
                                        strokeWidth="1.37441"
                                    />
                                    <circle
                                        cx="2.29068"
                                        cy="2.29068"
                                        r="2.29068"
                                        transform="matrix(1 0 0 -1 8.96457 11.3483)"
                                        stroke="#053465"
                                        strokeWidth="1.37441"
                                    />
                                </svg>

                                <div className="flex flex-col items-start justify-start mx-auto w-44">
                                    {/* Placeholder */}

                                    {/* Input */}
                                    <input
                                        type="text"
                                        value={text}
                                        onBlur={handleBlur}
                                        onChange={handleInputChangeDebounce}
                                        className="w-full px-2 py-0 text-sm focus:outline-none "
                                        placeholder="Buscar"
                                    />

                                    <span
                                        className={` text-gray-500 text-xs px-2 `}
                                    >
                                        ¿A dónde deseas ir?
                                    </span>
                                </div>
                            </div>
                        )}

                        <div
                            onClick={() => setIsOpenCalendar(!isOpenCalendar)}
                            className="flex justify-center items-center gap-1 border-l border-[#EEEEEE] px-5  cursor-pointer "
                        >
                            <svg
                                width="23"
                                height="23"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.81433 4.31169V2.64573H8.18874V4.31169H15.2828V2.64573H16.6572V4.31169H17.6428C19.483 4.31169 20.9747 5.80343 20.9747 7.64359V9.72602H20.9747V10.9755H20.9747V17.6393C20.9747 19.4795 19.483 20.9712 17.6428 20.9712H5.98113C4.14097 20.9712 2.64923 19.4795 2.64923 17.6393V7.64359C2.64923 5.80343 4.14098 4.31169 5.98114 4.31169H6.81433ZM6.81433 5.56115H5.98114C4.83104 5.56115 3.89869 6.49349 3.89869 7.64359V9.72602H19.7252V7.64359C19.7252 6.49349 18.7929 5.56115 17.6428 5.56115H16.6572V7.64358H15.2828V5.56115H8.18874V7.64358H6.81433V5.56115ZM3.89869 17.6393V10.9755H19.7252V17.6393C19.7252 18.7894 18.7929 19.7217 17.6428 19.7217H5.98113C4.83103 19.7217 3.89869 18.7894 3.89869 17.6393Z"
                                    fill="#053465"
                                />
                            </svg>
                            <div className="flex flex-col items-start justify-start mx-auto ">
                                {/* Placeholder */}

                                {/* Input */}
                                <div
                                    className={`w-full py-0 px-2 focus:outline-none  ${
                                        startDate || startDateEmbarcacion
                                            ? "text-gray-900 font-semibold"
                                            : "text-gray-400"
                                    }  text-sm`}
                                >
                                    {landing !== "embarcacion"
                                        ? formatDisplayDate(startDate)
                                        : formatDisplayDate(
                                              startDateEmbarcacion
                                          )}
                                </div>

                                <span
                                    className={` text-gray-500 text-xs px-2 `}
                                >
                                    Fecha de llegada
                                </span>
                            </div>
                        </div>
                        {landing !== "embarcacion" && (
                            <div
                                onClick={() =>
                                    setIsOpenCalendar(!isOpenCalendar)
                                }
                                className="flex justify-center items-center gap-1 px-5 border-l border-[#EEEEEE]  cursor-pointer "
                            >
                                <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M6.81433 4.31169V2.64573H8.18874V4.31169H15.2828V2.64573H16.6572V4.31169H17.6428C19.483 4.31169 20.9747 5.80343 20.9747 7.64359V9.72602H20.9747V10.9755H20.9747V17.6393C20.9747 19.4795 19.483 20.9712 17.6428 20.9712H5.98113C4.14097 20.9712 2.64923 19.4795 2.64923 17.6393V7.64359C2.64923 5.80343 4.14098 4.31169 5.98114 4.31169H6.81433ZM6.81433 5.56115H5.98114C4.83104 5.56115 3.89869 6.49349 3.89869 7.64359V9.72602H19.7252V7.64359C19.7252 6.49349 18.7929 5.56115 17.6428 5.56115H16.6572V7.64358H15.2828V5.56115H8.18874V7.64358H6.81433V5.56115ZM3.89869 17.6393V10.9755H19.7252V17.6393C19.7252 18.7894 18.7929 19.7217 17.6428 19.7217H5.98113C4.83103 19.7217 3.89869 18.7894 3.89869 17.6393Z"
                                        fill="#053465"
                                    />
                                </svg>
                                <div className="flex flex-col items-start justify-start mx-auto ">
                                    {/* Placeholder */}

                                    {/* Input */}
                                    <div
                                        className={`w-full py-0 px-2 focus:outline-none  ${
                                            endDate
                                                ? "text-gray-900 font-semibold"
                                                : "text-gray-400"
                                        }  text-sm`}
                                    >
                                        {formatDisplayDate(endDate)}
                                    </div>

                                    <span
                                        className={` text-gray-500 text-xs px-2 `}
                                    >
                                        Fecha de salida
                                    </span>
                                </div>
                            </div>
                        )}

                        <div
                            onClick={() => setIsOpenHuespedes(!isOpenHuespedes)}
                            className="flex justify-center items-center gap-1 px-5 border-l border-[#EEEEEE]  cursor-pointer "
                        >
                            <svg
                                width="23"
                                height="23"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clip-path="url(#clip0_326_648)">
                                    <g clip-path="url(#clip1_326_648)">
                                        <path
                                            d="M11.192 13.6427C7.99188 13.6427 5.38684 11.0308 5.38684 7.82234C5.38684 4.61388 7.98842 2.00202 11.192 2.00202C14.3955 2.00202 16.9971 4.61388 16.9971 7.82234C16.9971 11.0308 14.392 13.6427 11.192 13.6427ZM11.192 3.22644C8.66303 3.22644 6.60806 5.28679 6.60806 7.82234C6.60806 10.3579 8.66303 12.4182 11.192 12.4182C13.7209 12.4182 15.7758 10.3579 15.7758 7.82234C15.7758 5.28679 13.7174 3.22644 11.192 3.22644Z"
                                            fill="#053465"
                                        />
                                        <path
                                            d="M17.8863 22.002H4.11383C3.34236 22.002 2.65737 21.5997 2.28028 20.9302C1.89973 20.2538 1.91011 19.4491 2.31141 18.7797C3.78864 16.3239 7.19629 14.7387 11.0018 14.7387C14.8073 14.7387 18.2149 16.3239 19.6922 18.7797C20.0935 19.4491 20.1038 20.2504 19.7233 20.9302C19.3462 21.6031 18.6578 22.002 17.8897 22.002H17.8863ZM10.9983 15.9632C7.66678 15.9632 4.59471 17.3471 3.35273 19.411C3.17976 19.6954 3.1763 20.0388 3.3389 20.3267C3.50149 20.6146 3.78172 20.7776 4.11037 20.7776H17.8863C18.2149 20.7776 18.4952 20.6146 18.6578 20.3267C18.8204 20.0388 18.8134 19.6954 18.6439 19.411C17.4054 17.3506 14.3333 15.9632 10.9983 15.9632Z"
                                            fill="#053465"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_326_648">
                                        <rect
                                            width="21.9906"
                                            height="21.9906"
                                            fill="white"
                                            transform="translate(0.278931 0.813181)"
                                        />
                                    </clipPath>
                                    <clipPath id="clip1_326_648">
                                        <rect
                                            width="18"
                                            height="20"
                                            fill="white"
                                            transform="translate(2 2.00202)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>

                            <div className="flex flex-col items-start justify-start mx-auto ">
                                {/* Placeholder */}

                                {/* Input */}
                                <div
                                    className={`w-full py-0 px-2 focus:outline-none  ${
                                        personas.adultos + personas.niños > 0
                                            ? "text-gray-900 font-semibold"
                                            : "text-gray-400"
                                    }  text-sm`}
                                >
                                    {personas.adultos + personas.niños} personas
                                </div>

                                <span
                                    className={` text-gray-500 text-xs px-2 `}
                                >
                                    ¿Cuantas personas?
                                </span>
                            </div>
                        </div>

                        <div
                            onClick={handleBusqueda}
                            className="flex items-center justify-center gap-1 px-3 "
                        >
                            <div className=" bg-[#0F80F2] rounded-lg text-white cursor-pointer flex justify-center items-center px-3 py-1.5">
                                <svg
                                    width="22"
                                    height="23"
                                    viewBox="0 0 22 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="11.0077"
                                        cy="11.3503"
                                        r="6.64298"
                                        stroke="white"
                                        stroke-width="1.37441"
                                    />
                                    <path
                                        d="M15.589 16.3898L18.7959 19.5968"
                                        stroke="white"
                                        stroke-width="1.37441"
                                        stroke-linecap="round"
                                    />
                                </svg>

                                <span className={` text-sm px-2 `}>Buscar</span>
                            </div>
                        </div>
                    </div>
                )}

                {explorer && (
                    <div className="flex flex-col items-center justify-center w-full gap-5 px-1 mx-auto md818:hidden ">
                        <div className="relative flex items-center justify-start w-full gap-1 px-5 py-3 mx-auto rounded-lg shadow-lg md:w-4/5 xs:w-5/6 ">
                            {fields.length > 0 && (
                                <AutoComplete
                                    fields={fields}
                                    backGroundStyle="hover:bg-slate-200 "
                                    setInput={setText}
                                    setInputType={setTextType}
                                    type="house"
                                />
                            )}
                            <svg
                                width="23"
                                height="23"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.6692 9.05763C17.6692 13.5162 13.8813 20.9692 11.2553 20.9692C8.62925 20.9692 4.84137 13.5162 4.84137 9.05763C4.84137 5.51532 7.71298 2.64371 11.2553 2.64371C14.7976 2.64371 17.6692 5.51532 17.6692 9.05763Z"
                                    stroke="#053465"
                                    strokeWidth="1.37441"
                                />
                                <circle
                                    cx="2.29068"
                                    cy="2.29068"
                                    r="2.29068"
                                    transform="matrix(1 0 0 -1 8.96457 11.3483)"
                                    stroke="#053465"
                                    strokeWidth="1.37441"
                                />
                            </svg>

                            <div className="flex flex-col items-start justify-start w-44">
                                {/* Placeholder */}

                                {/* Input */}
                                <input
                                    type="text"
                                    value={text}
                                    onBlur={handleBlur}
                                    onChange={handleInputChangeDebounce}
                                    className="w-full px-2 py-0 text-sm focus:outline-none "
                                    placeholder="Buscar"
                                />

                                <span
                                    className={` text-gray-500 text-xs px-2 `}
                                >
                                    ¿A dónde deseas ir?
                                </span>
                            </div>
                        </div>

                        <div className="relative flex items-center justify-between w-full py-3 rounded-lg shadow-lg md:w-4/5 xs:w-5/6 ">
                            {isOpenCalendar && (
                                <div className="absolute z-50 p-2 -translate-x-1/2 bg-white rounded-lg shadow-lg md920:top-14 md-818:top-24 top-12 left-1/2 ">
                                    <DatePicker
                                        locale={es}
                                        selectsRange={landing !== "embarcacion"}
                                        selected={
                                            landing === "embarcacion"
                                                ? startDate
                                                : undefined
                                        }
                                        startDate={
                                            landing !== "embarcacion"
                                                ? startDate
                                                : undefined
                                        }
                                        endDate={
                                            landing !== "embarcacion"
                                                ? endDate
                                                : undefined
                                        }
                                        onChange={handleDateChange}
                                        monthsShown={
                                            landing !== "embarcacion" ? 2 : 1
                                        }
                                        inline
                                    />
                                </div>
                            )}
                            <div
                                onClick={() =>
                                    setIsOpenCalendar(!isOpenCalendar)
                                }
                                className="flex items-center justify-center gap-1 px-5 cursor-pointer "
                            >
                                <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M6.81433 4.31169V2.64573H8.18874V4.31169H15.2828V2.64573H16.6572V4.31169H17.6428C19.483 4.31169 20.9747 5.80343 20.9747 7.64359V9.72602H20.9747V10.9755H20.9747V17.6393C20.9747 19.4795 19.483 20.9712 17.6428 20.9712H5.98113C4.14097 20.9712 2.64923 19.4795 2.64923 17.6393V7.64359C2.64923 5.80343 4.14098 4.31169 5.98114 4.31169H6.81433ZM6.81433 5.56115H5.98114C4.83104 5.56115 3.89869 6.49349 3.89869 7.64359V9.72602H19.7252V7.64359C19.7252 6.49349 18.7929 5.56115 17.6428 5.56115H16.6572V7.64358H15.2828V5.56115H8.18874V7.64358H6.81433V5.56115ZM3.89869 17.6393V10.9755H19.7252V17.6393C19.7252 18.7894 18.7929 19.7217 17.6428 19.7217H5.98113C4.83103 19.7217 3.89869 18.7894 3.89869 17.6393Z"
                                        fill="#053465"
                                    />
                                </svg>
                                <div className="flex flex-col items-start justify-start mx-auto ">
                                    {/* Placeholder */}

                                    {/* Input */}
                                    <div
                                        className={`w-full py-0 px-2 focus:outline-none  ${
                                            startDate || startDateEmbarcacion
                                                ? "text-gray-900 font-semibold"
                                                : "text-gray-400"
                                        }  text-sm`}
                                    >
                                        {landing !== "embarcacion"
                                            ? formatDisplayDate(startDate)
                                            : formatDisplayDate(
                                                  startDateEmbarcacion
                                              )}
                                    </div>

                                    <span
                                        className={` text-gray-500 text-xs px-2 `}
                                    >
                                        Fecha de llegada
                                    </span>
                                </div>
                            </div>
                            {landing !== "embarcacion" && (
                                <div
                                    onClick={() =>
                                        setIsOpenCalendar(!isOpenCalendar)
                                    }
                                    className="flex items-center justify-center gap-1 px-5 cursor-pointer "
                                >
                                    <svg
                                        width="23"
                                        height="23"
                                        viewBox="0 0 23 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M6.81433 4.31169V2.64573H8.18874V4.31169H15.2828V2.64573H16.6572V4.31169H17.6428C19.483 4.31169 20.9747 5.80343 20.9747 7.64359V9.72602H20.9747V10.9755H20.9747V17.6393C20.9747 19.4795 19.483 20.9712 17.6428 20.9712H5.98113C4.14097 20.9712 2.64923 19.4795 2.64923 17.6393V7.64359C2.64923 5.80343 4.14098 4.31169 5.98114 4.31169H6.81433ZM6.81433 5.56115H5.98114C4.83104 5.56115 3.89869 6.49349 3.89869 7.64359V9.72602H19.7252V7.64359C19.7252 6.49349 18.7929 5.56115 17.6428 5.56115H16.6572V7.64358H15.2828V5.56115H8.18874V7.64358H6.81433V5.56115ZM3.89869 17.6393V10.9755H19.7252V17.6393C19.7252 18.7894 18.7929 19.7217 17.6428 19.7217H5.98113C4.83103 19.7217 3.89869 18.7894 3.89869 17.6393Z"
                                            fill="#053465"
                                        />
                                    </svg>
                                    <div className="flex flex-col items-start justify-start mx-auto ">
                                        {/* Placeholder */}

                                        {/* Input */}
                                        <div
                                            className={`w-full py-0 px-2 focus:outline-none  ${
                                                endDate
                                                    ? "text-gray-900 font-semibold"
                                                    : "text-gray-400"
                                            }  text-sm`}
                                        >
                                            {formatDisplayDate(endDate)}
                                        </div>

                                        <span
                                            className={` text-gray-500 text-xs px-2 `}
                                        >
                                            Fecha de salida
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div
                            onClick={() => setIsOpenHuespedes(!isOpenHuespedes)}
                            className="relative flex items-center justify-start w-full gap-1 py-3 rounded-lg shadow-lg cursor-pointer md:w-4/5 xs:w-5/6"
                        >
                            {isOpenHuespedes && (
                                <div
                                    onClick={(e) => e.stopPropagation()}
                                    className="absolute z-50 w-full p-4 mx-auto bg-white rounded-lg shadow-lg md920:top-14 md818:top-24 top-14"
                                >
                                    <div className="flex flex-col items-center justify-center gap-5 mx-auto w-72 ">
                                        <div className="flex items-start justify-between w-full ">
                                            <div className="flex flex-col items-start justify-center">
                                                <div className="text-[#053465] text-base font-bold ">
                                                    Adultos
                                                </div>
                                                <div className="text-xs text-gray-500 ">
                                                    De: 13 años o más
                                                </div>
                                            </div>

                                            <div className="flex justify-center items-center p-1.5 rounded-full border  font-semibold border-[#0F80F2] gap-3">
                                                <div
                                                    onClick={() =>
                                                        setPersonas({
                                                            ...personas,
                                                            adultos:
                                                                personas.adultos >
                                                                0
                                                                    ? personas.adultos -
                                                                      1
                                                                    : 0,
                                                        })
                                                    }
                                                    className="flex justify-center items-center font-semibold cursor-pointer text-xl rounded-full w-6 h-6 bg-[#D9D9D9] text-[#424242] "
                                                >
                                                    <svg
                                                        width="12"
                                                        height="3"
                                                        viewBox="0 0 12 3"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M10.5585 1.41779H1.1637"
                                                            stroke="#424242"
                                                            strokeWidth="1.87897"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="text-[#0F80F2] font-semibold">
                                                    {personas.adultos}
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        setPersonas({
                                                            ...personas,
                                                            adultos:
                                                                personas.adultos +
                                                                1,
                                                        })
                                                    }
                                                    className="flex justify-center items-center font-semibold cursor-pointer rounded-full w-6 h-6 bg-[#D9D9D9] text-[#424242]"
                                                >
                                                    <svg
                                                        width="12"
                                                        height="12"
                                                        viewBox="0 0 13 13"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6.47245 11.1152V1.7204M11.1699 6.41782H1.77502"
                                                            stroke="#424242"
                                                            strokeWidth="1.87897"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-start justify-between w-full ">
                                            <div className="flex flex-col items-start justify-center">
                                                <div className="text-[#053465] text-base font-bold ">
                                                    Niños
                                                </div>
                                                <div className="text-xs text-gray-500 ">
                                                    De: 0 años a 12 años
                                                </div>
                                            </div>

                                            <div className="flex justify-center items-center p-1.5 rounded-full border  font-semibold border-[#0F80F2] gap-3">
                                                <div
                                                    onClick={() =>
                                                        setPersonas({
                                                            ...personas,
                                                            niños:
                                                                personas.niños >
                                                                0
                                                                    ? personas.niños -
                                                                      1
                                                                    : 0,
                                                        })
                                                    }
                                                    className="flex justify-center items-center font-semibold cursor-pointer text-xl rounded-full w-6 h-6 bg-[#D9D9D9] text-[#424242] "
                                                >
                                                    <svg
                                                        width="12"
                                                        height="3"
                                                        viewBox="0 0 12 3"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M10.5585 1.41779H1.1637"
                                                            stroke="#424242"
                                                            strokeWidth="1.87897"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="text-[#0F80F2] font-semibold">
                                                    {personas.niños}
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        setPersonas({
                                                            ...personas,
                                                            niños:
                                                                personas.niños +
                                                                1,
                                                        })
                                                    }
                                                    className="flex justify-center items-center font-semibold cursor-pointer rounded-full w-6 h-6 bg-[#D9D9D9] text-[#424242]"
                                                >
                                                    <svg
                                                        width="12"
                                                        height="12"
                                                        viewBox="0 0 13 13"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6.47245 11.1152V1.7204M11.1699 6.41782H1.77502"
                                                            stroke="#424242"
                                                            strokeWidth="1.87897"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="flex items-center justify-start gap-1 px-5 ">
                                <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_326_648)">
                                        <g clip-path="url(#clip1_326_648)">
                                            <path
                                                d="M11.192 13.6427C7.99188 13.6427 5.38684 11.0308 5.38684 7.82234C5.38684 4.61388 7.98842 2.00202 11.192 2.00202C14.3955 2.00202 16.9971 4.61388 16.9971 7.82234C16.9971 11.0308 14.392 13.6427 11.192 13.6427ZM11.192 3.22644C8.66303 3.22644 6.60806 5.28679 6.60806 7.82234C6.60806 10.3579 8.66303 12.4182 11.192 12.4182C13.7209 12.4182 15.7758 10.3579 15.7758 7.82234C15.7758 5.28679 13.7174 3.22644 11.192 3.22644Z"
                                                fill="#053465"
                                            />
                                            <path
                                                d="M17.8863 22.002H4.11383C3.34236 22.002 2.65737 21.5997 2.28028 20.9302C1.89973 20.2538 1.91011 19.4491 2.31141 18.7797C3.78864 16.3239 7.19629 14.7387 11.0018 14.7387C14.8073 14.7387 18.2149 16.3239 19.6922 18.7797C20.0935 19.4491 20.1038 20.2504 19.7233 20.9302C19.3462 21.6031 18.6578 22.002 17.8897 22.002H17.8863ZM10.9983 15.9632C7.66678 15.9632 4.59471 17.3471 3.35273 19.411C3.17976 19.6954 3.1763 20.0388 3.3389 20.3267C3.50149 20.6146 3.78172 20.7776 4.11037 20.7776H17.8863C18.2149 20.7776 18.4952 20.6146 18.6578 20.3267C18.8204 20.0388 18.8134 19.6954 18.6439 19.411C17.4054 17.3506 14.3333 15.9632 10.9983 15.9632Z"
                                                fill="#053465"
                                            />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_326_648">
                                            <rect
                                                width="21.9906"
                                                height="21.9906"
                                                fill="white"
                                                transform="translate(0.278931 0.813181)"
                                            />
                                        </clipPath>
                                        <clipPath id="clip1_326_648">
                                            <rect
                                                width="18"
                                                height="20"
                                                fill="white"
                                                transform="translate(2 2.00202)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <div className="flex flex-col items-start justify-start ">
                                    {/* Placeholder */}

                                    {/* Input */}
                                    <div
                                        className={`w-full py-0 px-2 focus:outline-none  ${
                                            personas.adultos + personas.niños >
                                            0
                                                ? "text-gray-900 font-semibold"
                                                : "text-gray-400"
                                        }  text-sm`}
                                    >
                                        {personas.adultos + personas.niños}{" "}
                                        personas
                                    </div>

                                    <span
                                        className={` text-gray-500 text-xs px-2 `}
                                    >
                                        ¿Cuantas personas?
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            onClick={handleBusqueda}
                            className="flex items-center justify-center w-full gap-1 mx-auto md:w-4/5 xs:w-5/6 "
                        >
                            <div className=" bg-[#0F80F2] rounded-lg text-white cursor-pointer flex justify-center items-center px-3 py-2 w-full">
                                <svg
                                    width="22"
                                    height="23"
                                    viewBox="0 0 22 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="11.0077"
                                        cy="11.3503"
                                        r="6.64298"
                                        stroke="white"
                                        stroke-width="1.37441"
                                    />
                                    <path
                                        d="M15.589 16.3898L18.7959 19.5968"
                                        stroke="white"
                                        stroke-width="1.37441"
                                        stroke-linecap="round"
                                    />
                                </svg>

                                <span className={` text-sm px-2 `}>Buscar</span>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex items-center justify-start w-full gap-10 mb-3 md818:hidden">
                    <Swiper
                        className="w-full"
                        slidesPerView="auto"
                        spaceBetween={20}
                        autoWidth={false}
                        autoplay={true}
                        loop={true}
                    >
                        <SwiperSlide className="!w-auto">
                            <div
                                onClick={() => {
                                    navigate(
                                        "/alquiler-de-alojamientos/query_tipo=&query_value=Campo&fecha_final=&fecha_inicio=&numero_banos=0&numero_camas=0&numero_habitaciones=0&numero_personas=0&id=5&pagina=1&tipo_alojamiento=0&atributos=&reglas=&ubicacion=&precio_rango=0&tipo_moneda=&order=precio&mayor_menor=&latitud=0&longitud=0&zoom=10&numero_noches=0"
                                    );
                                }}
                                className=" text-[#0F80F2] font-semibold px-5 "
                            >
                                Casas de Campo
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="!w-auto">
                            <div
                                onClick={() => {
                                    navigate(
                                        "/alquiler-de-alojamientos/query_tipo=&query_value=Playa&fecha_final=&fecha_inicio=&numero_banos=0&numero_camas=0&numero_habitaciones=0&numero_personas=0&id=5&pagina=1&tipo_alojamiento=0&atributos=&reglas=&ubicacion=&precio_rango=0&tipo_moneda=&order=precio&mayor_menor=&latitud=0&longitud=0&zoom=10&numero_noches=0"
                                    );
                                }}
                                className=" text-[#0F80F2] font-semibold px-5 "
                            >
                                Casas de Playa
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="!w-auto">
                            <div
                                onClick={() => {
                                    navigate("/alquiler-de-embarcaciones/");
                                }}
                                className=" text-[#0F80F2] font-semibold px-5 "
                            >
                                Embarcaciones
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="!w-auto">
                            <div
                                onClick={() => {
                                    navigate("/blog");
                                }}
                                className=" text-[#0F80F2] font-semibold hidden xs400:flex px-5 "
                            >
                                Blog
                            </div>
                        </SwiperSlide>

                        {/* <SwiperSlide className="!w-auto">
                            <div className="text-[#0F80F2] font-semibold  justify-center items-center gap-1 bg-[#0F80F2] bg-opacity-20 rounded-full py-1 px-5 flex ">
                                <img src={imagenOfertas} alt="imagen ofertas" />
                                Ofertas
                            </div>
                        </SwiperSlide> */}
                    </Swiper>
                </div>
            </div>
        </nav>
    );
};

export default NavBarHome;
