import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ModalVariablePrices from "./ModalVariablePrices";

import Spinner from "../../Spinner";
import ModalEditVariablePrices from "./ModalEditVariablePrices";
import { useSelector } from "react-redux";

const VariablePrices = ({
    onboarding,
    setOnboarding,
    setPage,
    actualPage,
    setModalAvanzado,
    setRegresar,
}) => {
    const [modalEditVisible, setModalEditVisible] = useState(false);
    const [fullVariable, setFullVariable] = useState({});
    const [select, setSelect] = useState("");
    const [createVisible, setCreateVisible] = useState(false);
    const [allVariablePrices, setAllVariablePrices] = useState({
        checking: true,
        data: [],
    });
    const [fakeRefresh, setFakeRefresh] = useState(false);
    const [siguiente, setSiguiente] = useState("Añadir");
    const [input, setInput] = useState({
        tipoVariante: "Selección por fechas",
        fechaHasta: "",
        fechaDesde: "",
        mesDesde: "Enero",
        mesHasta: "Enero",
        diaDesde: "Lunes",
        diaHasta: "Lunes",
        numero_dias: "",
        costoBase_accion: "+",
        costoBase_precio: "",
        costoBloque_accion: "+",
        costoBloque_precio: "",
        precioPorPersona: "",
    });
    const [feeArray, setFeeArray] = useState([]);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const authUser = useSelector((state) => state.authReducer);

    const func = async () => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/productoPreciosVariables/all/${onboarding.id}`,
            { headers }
        );
        console.log(data);
        setAllVariablePrices({
            checking: false,
            data: data,
        });
    };
    const fee = async () => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY}/casaFee/all/${onboarding.id}`,
            { headers }
        );
        let arrayFiltrado = data.data.filter(
            (fee) => fee.tipo_tiempo === "Fecha"
        );
        setFeeArray(arrayFiltrado);
    };
    useEffect(() => {
        func();
        fee();
    }, [createVisible, fakeRefresh]);

    const updateProduct = async (payload) => {
        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/productoPreciosVariables`,
            payload,
            { headers }
        );

        if (
            data?.error !==
            "Existe conflicto en rango de numero de dias con otro rango, verifique porfavor"
        ) {
            setSiguiente("Añadir");
            setCreateVisible(!createVisible);
        } else {
            setSiguiente("Añadir");
            Swal.fire({
                title: data.error,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Seguro que quieres eliminar la variación de precio?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(
                        `${process.env.REACT_APP_PROXY}/productoPreciosVariables/eliminar/${id}`,
                        {
                            data: { id_usuario: authUser.id_usuario },
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        if (
                            response.data.message ===
                            "Registro eliminado con exito"
                        ) {
                            Swal.fire({
                                title: "Variación eliminada correctamente!",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            });
                            setFakeRefresh(!fakeRefresh);
                        }
                    });
            }
        });
    };

    return (
        <div className="w-full p-5 ">
            <div
                className={
                    "flex items-center text-lg cursor-pointer pt-4 px-4 mt-6  w-full  "
                }
                onClick={() => (setModalAvanzado(false), setRegresar(false))}
            >
                <svg
                    className="w-6 h-6 -ml-2 text-black"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    {" "}
                    <polyline points="15 18 9 12 15 6" />
                </svg>
                <span className="mx-1">Regresar</span>
            </div>
            <div className="flex items-center justify-between w-full px-6 mx-auto ">
                <h1 className="text-4xl  py-10 text-center text-[#3C3C3C]">
                    Precios variables
                </h1>
                <div className="mt-4 md:ml-10">
                    <button
                        className="flex items-center px-3 py-2 mx-auto text-lg border rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:text-white"
                        onClick={() => setCreateVisible(!createVisible)}
                    >
                        <svg
                            className="w-5 h-5 text-blue-bluecorner group-hover:text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="12" y1="5" x2="12" y2="19" />{" "}
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        <span className="items-center hidden text-base sm:block ">
                            Nuevo precio
                        </span>
                    </button>
                </div>
            </div>

            {createVisible && (
                <ModalVariablePrices
                    modalVisible={createVisible}
                    setModalVisible={setCreateVisible}
                    fakeRefresh={fakeRefresh}
                    setFakeRefresh={setFakeRefresh}
                    updateProduct={updateProduct}
                    onboarding={onboarding}
                    setOnboarding={setOnboarding}
                    setSiguiente={setSiguiente}
                    siguiente={siguiente}
                    feeArray={feeArray}
                />
            )}
            {modalEditVisible && (
                <ModalEditVariablePrices
                    modalEditVisible={modalEditVisible}
                    setModalEditVisible={setModalEditVisible}
                    fakeRefresh={fakeRefresh}
                    setFakeRefresh={setFakeRefresh}
                    fullVariable={fullVariable}
                    select={select}
                    onboarding={onboarding}
                    setSiguiente={setSiguiente}
                    siguiente={siguiente}
                    feeArray={feeArray}
                />
            )}

            <div className="w-11/12 mx-auto mt-5 ">
                {allVariablePrices?.checking && <Spinner width={20} />}

                <div
                    className={
                        allVariablePrices.data?.arrayRangoExactoDiasPorDia
                            ?.length
                            ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5 overflow-auto"
                            : "hidden"
                    }
                >
                    <div className="w-full">
                        <p className="text-center text-xl sm:text-2xl text-[#3C3C3C] ">
                            Rango exacto de días (precio adicional)
                        </p>
                        <table className=" w-full  min-w-[580px] overflow-x-auto mt-14 ">
                            <thead className="text-left">
                                <tr>
                                    <th className="w-2/12 min-w-[5rem] ">
                                        Desde
                                    </th>
                                    <th className="w-2/12 min-w-[5rem] ">
                                        Hasta
                                    </th>
                                    <th className="w-2/12 min-w-[5rem]  table-cell">
                                        Precio adicional
                                    </th>

                                    <th className="w-2/12 min-w-[5rem]  table-cell">
                                        Fecha
                                    </th>

                                    <th className="w-1/12 text-right min-w-[5rem]  table-cell pr-10">
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {allVariablePrices.data.arrayRangoExactoDiasPorDia?.map(
                                    (dias) => (
                                        <tr
                                            key={dias.id}
                                            className="w-full border-t "
                                        >
                                            <td>{dias?.dia_inicio}</td>
                                            <td className="table-cell">
                                                {dias.dia_final}
                                            </td>

                                            <td className="table-cell">
                                                {dias.accion}
                                                {dias?.title} (
                                                {onboarding.moneda_precio_base})
                                            </td>
                                            <td className="table-cell">
                                                {dias.tipo_tiempo ===
                                                "Por fechas"
                                                    ? `Desde ${dias.fecha_inicio
                                                          .slice(0, 10)
                                                          .split("-")
                                                          .reverse()
                                                          .join(
                                                              "-"
                                                          )} a ${dias.fecha_final
                                                          .slice(0, 10)
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")}`
                                                    : "Siempre"}
                                            </td>

                                            <td
                                                className={
                                                    "flex justify-end py-2 px-2"
                                                }
                                            >
                                                <div className="flex">
                                                    <button
                                                        onClick={() => (
                                                            setSelect(
                                                                "Selección por rango exacto de días"
                                                            ),
                                                            setFullVariable(
                                                                dias
                                                            ),
                                                            setModalEditVisible(
                                                                !modalEditVisible
                                                            )
                                                        )}
                                                        title="Editar"
                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white"
                                                    >
                                                        <svg
                                                            className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />
                                                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                            <line
                                                                x1="16"
                                                                y1="5"
                                                                x2="19"
                                                                y2="8"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            handleDelete(
                                                                dias.id
                                                            )
                                                        }
                                                        title="Eliminar"
                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white"
                                                    >
                                                        <svg
                                                            className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <line
                                                                x1="4"
                                                                y1="7"
                                                                x2="20"
                                                                y2="7"
                                                            />{" "}
                                                            <line
                                                                x1="10"
                                                                y1="11"
                                                                x2="10"
                                                                y2="17"
                                                            />{" "}
                                                            <line
                                                                x1="14"
                                                                y1="11"
                                                                x2="14"
                                                                y2="17"
                                                            />{" "}
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className={
                        allVariablePrices.data?.arrayRangoExactoDiasTotal
                            ?.length
                            ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5 overflow-auto"
                            : "hidden"
                    }
                >
                    <div className="w-full">
                        <p className="text-center text-xl sm:text-2xl text-[#3C3C3C] ">
                            Rango exacto de días (precio total)
                        </p>
                        <table className=" w-full  min-w-[580px] overflow-x-auto mt-14 ">
                            <thead className="text-left">
                                <tr>
                                    <th className="w-2/12 min-w-[5rem] ">
                                        Desde
                                    </th>
                                    <th className="w-2/12 min-w-[5rem] ">
                                        Hasta
                                    </th>
                                    <th className="w-2/12 min-w-[5rem]  table-cell">
                                        Total a cobrar
                                    </th>
                                    <th className="w-2/12 min-w-[5rem]  table-cell">
                                        Fecha
                                    </th>

                                    <th className="w-1/12 text-right min-w-[5rem]  table-cell pr-10">
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {allVariablePrices.data.arrayRangoExactoDiasTotal?.map(
                                    (dias) => (
                                        <tr
                                            key={dias.id}
                                            className="w-full border-t "
                                        >
                                            <td>{dias?.dia_inicio}</td>
                                            <td className="table-cell">
                                                {dias.dia_final}
                                            </td>
                                            <td className="table-cell">
                                                {/* {dias.accion} */}
                                                {dias?.title} (
                                                {onboarding.moneda_precio_base})
                                            </td>
                                            <td className="table-cell">
                                                {dias.tipo_tiempo ===
                                                "Por fechas"
                                                    ? `Desde ${dias.fecha_inicio
                                                          .slice(0, 10)
                                                          .split("-")
                                                          .reverse()
                                                          .join(
                                                              "-"
                                                          )} a ${dias.fecha_final
                                                          .slice(0, 10)
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")}`
                                                    : "Siempre"}
                                            </td>

                                            <td
                                                className={
                                                    "flex justify-end py-2 px-2"
                                                }
                                            >
                                                <div className="flex">
                                                    <button
                                                        onClick={() => (
                                                            setSelect(
                                                                "Selección por rango exacto de días"
                                                            ),
                                                            setFullVariable(
                                                                dias
                                                            ),
                                                            setModalEditVisible(
                                                                !modalEditVisible
                                                            )
                                                        )}
                                                        title="Editar"
                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white"
                                                    >
                                                        <svg
                                                            className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />
                                                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                            <line
                                                                x1="16"
                                                                y1="5"
                                                                x2="19"
                                                                y2="8"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            handleDelete(
                                                                dias.id
                                                            )
                                                        }
                                                        title="Eliminar"
                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white"
                                                    >
                                                        <svg
                                                            className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <line
                                                                x1="4"
                                                                y1="7"
                                                                x2="20"
                                                                y2="7"
                                                            />{" "}
                                                            <line
                                                                x1="10"
                                                                y1="11"
                                                                x2="10"
                                                                y2="17"
                                                            />{" "}
                                                            <line
                                                                x1="14"
                                                                y1="11"
                                                                x2="14"
                                                                y2="17"
                                                            />{" "}
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className={
                        allVariablePrices.data?.arrayNumeroDiasPorDia?.length
                            ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5 overflow-auto"
                            : "hidden"
                    }
                >
                    <div className="w-full">
                        <p className="text-center text-xl sm:text-2xl text-[#3C3C3C]  ">
                            Cantidad de días (precio por día)
                        </p>

                        <table className="w-full min-w-[580px] overflow-x-auto mt-14 ">
                            <thead className="text-left">
                                <tr>
                                    <th className="w-2/12 min-w-[5rem">
                                        Rango de dias
                                    </th>

                                    <th className="w-2/12 min-w-[5rem] table-cell">
                                        Por día
                                    </th>
                                    <th className="w-2/12 min-w-[5rem]  table-cell">
                                        Fecha
                                    </th>

                                    <th className="w-1/12 text-right min-w-[5rem] md:table-cell pr-10">
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {allVariablePrices.data.arrayNumeroDiasPorDia?.map(
                                    (dias) => (
                                        <tr
                                            key={dias.id}
                                            className="w-full border-t "
                                        >
                                            <td>
                                                {dias?.numero_dias} -{" "}
                                                {dias?.numero_dias_final}
                                            </td>

                                            <td className="table-cell">
                                                {dias.accion}
                                                {dias?.title} (
                                                {onboarding.moneda_precio_base})
                                            </td>
                                            <td className="table-cell">
                                                {dias.tipo_tiempo ===
                                                "Por fechas"
                                                    ? `Desde ${dias.fecha_inicio
                                                          .slice(0, 10)
                                                          .split("-")
                                                          .reverse()
                                                          .join(
                                                              "-"
                                                          )} a ${dias.fecha_final
                                                          .slice(0, 10)
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")}`
                                                    : "Siempre"}
                                            </td>

                                            <td
                                                className={
                                                    "flex justify-end py-2 px-2"
                                                }
                                            >
                                                <div className="flex">
                                                    <button
                                                        onClick={() => (
                                                            setSelect(
                                                                "Selección por numero de días"
                                                            ),
                                                            setFullVariable(
                                                                dias
                                                            ),
                                                            setModalEditVisible(
                                                                !modalEditVisible
                                                            )
                                                        )}
                                                        title="Editar"
                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white"
                                                    >
                                                        <svg
                                                            className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />
                                                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                            <line
                                                                x1="16"
                                                                y1="5"
                                                                x2="19"
                                                                y2="8"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            handleDelete(
                                                                dias.id
                                                            )
                                                        }
                                                        title="Eliminar"
                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white"
                                                    >
                                                        <svg
                                                            className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <line
                                                                x1="4"
                                                                y1="7"
                                                                x2="20"
                                                                y2="7"
                                                            />{" "}
                                                            <line
                                                                x1="10"
                                                                y1="11"
                                                                x2="10"
                                                                y2="17"
                                                            />{" "}
                                                            <line
                                                                x1="14"
                                                                y1="11"
                                                                x2="14"
                                                                y2="17"
                                                            />{" "}
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className={
                        allVariablePrices.data?.arrayNumeroDiasTotal?.length
                            ? "w-10/12 mx-auto sm:flex xl:w-8/12 mt-5 overflow-auto"
                            : "hidden"
                    }
                >
                    <div className="w-full">
                        <p className="text-center text-xl sm:text-2xl text-[#3C3C3C]  ">
                            Cantidad de días (precio total)
                        </p>

                        <table className="w-full min-w-[580px] overflow-x-auto mt-14 ">
                            <thead className="text-left">
                                <tr>
                                    <th className="w-2/12 min-w-[5rem">
                                        Numero de dias
                                    </th>

                                    <th className="w-2/12 min-w-[5rem] table-cell">
                                        Total a cobrar
                                    </th>
                                    <th className="w-2/12 min-w-[5rem]  table-cell">
                                        Fecha
                                    </th>

                                    <th className="w-1/12 text-right min-w-[5rem] md:table-cell pr-10">
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {allVariablePrices.data.arrayNumeroDiasTotal?.map(
                                    (dias) => (
                                        <tr
                                            key={dias.id}
                                            className="w-full border-t "
                                        >
                                            <td>{dias?.numero_dias}</td>

                                            <td className="table-cell">
                                                {dias?.title} (
                                                {onboarding.moneda_precio_base})
                                            </td>
                                            <td className="table-cell">
                                                {dias.tipo_tiempo ===
                                                "Por fechas"
                                                    ? `Desde ${dias.fecha_inicio
                                                          .slice(0, 10)
                                                          .split("-")
                                                          .reverse()
                                                          .join(
                                                              "-"
                                                          )} a ${dias.fecha_final
                                                          .slice(0, 10)
                                                          .split("-")
                                                          .reverse()
                                                          .join("-")}`
                                                    : "Siempre"}
                                            </td>
                                            <td
                                                className={
                                                    "flex justify-end py-2 px-2"
                                                }
                                            >
                                                <div className="flex">
                                                    <button
                                                        onClick={() => (
                                                            setSelect(
                                                                "Selección por numero de días"
                                                            ),
                                                            setFullVariable(
                                                                dias
                                                            ),
                                                            setModalEditVisible(
                                                                !modalEditVisible
                                                            )
                                                        )}
                                                        title="Editar"
                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white"
                                                    >
                                                        <svg
                                                            className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />
                                                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                            <line
                                                                x1="16"
                                                                y1="5"
                                                                x2="19"
                                                                y2="8"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            handleDelete(
                                                                dias.id
                                                            )
                                                        }
                                                        title="Eliminar"
                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border-2 rounded-lg text-blue-bluecorner border-blue-bluecorner group hover:bg-blue-bluecorner hover:white"
                                                    >
                                                        <svg
                                                            className="h-4 w-4  text-blue-bluecorner group-hover:text-white"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <line
                                                                x1="4"
                                                                y1="7"
                                                                x2="20"
                                                                y2="7"
                                                            />{" "}
                                                            <line
                                                                x1="10"
                                                                y1="11"
                                                                x2="10"
                                                                y2="17"
                                                            />{" "}
                                                            <line
                                                                x1="14"
                                                                y1="11"
                                                                x2="14"
                                                                y2="17"
                                                            />{" "}
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* ------------------------------------------------------------ */}
            </div>
            <div className="flex items-center justify-center mt-20">
                <div className="pb-6 mt-4 text-center">
                    <button
                        onClick={() => (
                            setModalAvanzado(false), setRegresar(false)
                        )}
                        className="text-center border-blue-400 bg-blue-bluecorner text-white w-full xs:w-80 border hover:bg-white hover:text-blue-bluecorner p-3 rounded-md disabled:bg-blue-400"
                    >
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VariablePrices;
