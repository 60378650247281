import React from "react";
import { useDispatch } from "react-redux";
import {
    updateBoatFilters,
    updateHouseFilters,
} from "../../redux/actions/filtersActions";

const AutoComplete = ({
    fields,
    backGroundStyle = "",
    setInput,
    setInputType,
    setInputBoat,
    type,
}) => {
    const dispatch = useDispatch();

    const handleFieldClick = (field) => {
        if (type === "boat") {
            setInput(field.valor);
            setInputType(field.tipo);
            dispatch(
                updateBoatFilters({
                    query_value: field.valor,
                    query_tipo: field.tipo,
                })
            );
        } else {
            setInput(field.valor);
            setInputType(field.tipo);
            dispatch(
                updateHouseFilters({
                    query_value: field.valor,
                    query_tipo: field.tipo,
                })
            );
        }
    };

    return (
        <div className="absolute md920:top-12 md818:top-24 top-12 z-[51] bg-white mt-1 left-0 p-2 md-818:w-80 w-full  rounded-lg">
            {fields.map((field, index) => {
                return (
                    <div
                        key={index + field.valor}
                        className={` cursor-pointer p-1 flex items-center rounded-lg gap-2 ${
                            backGroundStyle && backGroundStyle
                        }`}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleFieldClick(field);
                        }}
                    >
                        {field.tipo === "ubicacion" && (
                            <svg
                                className="w-6 h-6 text-black"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                        )}
                        {field.tipo === "etiqueta" && (
                            <svg
                                className="w-6 h-6 text-black"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <line x1="5" y1="9" x2="19" y2="9" />{" "}
                                <line x1="5" y1="15" x2="19" y2="15" />{" "}
                                <line x1="11" y1="4" x2="7" y2="20" />{" "}
                                <line x1="17" y1="4" x2="13" y2="20" />
                            </svg>
                        )}
                        {field.tipo === "casa" && (
                            <svg
                                className="w-6 h-6 text-black"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />{" "}
                                <line x1="13" y1="7" x2="13" y2="7.01" />{" "}
                                <line x1="17" y1="7" x2="17" y2="7.01" />{" "}
                                <line x1="17" y1="11" x2="17" y2="11.01" />{" "}
                                <line x1="17" y1="15" x2="17" y2="15.01" />
                            </svg>
                        )}
                        {field.tipo === "embarcacion" && (
                            <svg
                                className="w-6 h-6 text-black"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M12 9v12m-8 -8a8 8 0 0 0 16 0m1 0h-2m-14 0h-2" />{" "}
                                <circle cx="12" cy="6" r="3" />
                            </svg>
                        )}
                        <h1 className="p-2">{field.valor}</h1>
                    </div>
                );
            })}
        </div>
    );
};

export default AutoComplete;
