import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import "swiper/css/lazy";
import "../../empresas/paquetesEmpresa/Swiper.css";
import useHeaders from "../../../hooks/useHeaders";
import { ReactSortable } from "react-sortablejs";
import { useSelector } from "react-redux";

import CardsDestacados from "./CardsDestacados";
import { useWindowDimension } from "../../../hooks/useWindowDimension";

const AllCards = ({
    vistaAdministrador,
    refreshHouses,
    setRefreshHouses,
    checking,
    allHouses,
    setAllHouses,
    setSelectedMarker,
    setMapShouldFly,
    selectedDays,
    personas,
    cantDays,
}) => {
    const { headers } = useHeaders();
    const authUser = useSelector((state) => state.authReducer);

    const handleDragEnd = async () => {
        const allHousesID = allHouses.map((house) => house.id);

        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/putOrdenDestacados/`,
                { array_orden: allHousesID },
                { headers }
            );

            console.log(data);
        } catch (error) {
            console.log(error.response);
        }
    };

    return (
        <div>
            {checking ? (
                <div className="justify-center w-full pt-6">
                    <Spinner />
                </div>
            ) : allHouses.data?.length > 0 ? (
                <div>
                    <div className="px-4 xl:w-[90%] mx-auto">
                        <ReactSortable
                            disabled={!vistaAdministrador}
                            onEnd={() => handleDragEnd()}
                            animation={200}
                            delayOnTouchStart={true}
                            delay={2}
                            list={allHouses.data}
                            setList={setAllHouses}
                            className="grid w-full grid-cols-1 gap-4 "
                        >
                            {allHouses.data.map((product, i) => (
                                <CardsDestacados
                                    name={product.id}
                                    key={i}
                                    product={product}
                                    vistaAdministrador={vistaAdministrador}
                                    setRefreshHouses={setRefreshHouses}
                                    setSelectedMarker={setSelectedMarker}
                                    setMapShouldFly={setMapShouldFly}
                                    selectedDays={selectedDays}
                                    personas={personas}
                                    cantDays={cantDays}
                                />
                            ))}
                        </ReactSortable>
                    </div>
                </div>
            ) : (
                <div className="flex justify-center items-center font-bold mt-10">
                    No se ha encontrado casas con ese tipo de búsqueda
                </div>
            )}
        </div>
    );
};

export default AllCards;
